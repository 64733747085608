import {DataPoint} from "../data/DataPoint";

export enum DataPointActionType {
    DataPointLoad = "DataPointsLoad",
    DataPointLoading = "DataPointsLoading",
    DataPointLoadingFailed = "DataPointsLoadingFailed",
    SetDataPoints = "SetDataPoints"
}

interface DataPointsLoadAction {
    type: typeof DataPointActionType.DataPointLoad
}

interface DataPointsLoadingAction {
    type: typeof DataPointActionType.DataPointLoading
}

interface DataPointsLoadingFailedAction {
    type: typeof DataPointActionType.DataPointLoadingFailed,
    errorMessage: string
}

export interface SetDataPointsAction {
    type: typeof DataPointActionType.SetDataPoints,
    dataPoints: DataPoint[]
}

export type DataPointsActions =
    DataPointsLoadAction |
    DataPointsLoadingAction |
    DataPointsLoadingFailedAction |
    SetDataPointsAction
