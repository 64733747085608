import {IResource, IResourceTag} from "../shared/soleTypes";

export enum ResourceActionType {
    SetResources = "SetResources",
    SetResourceTags = "SetResourceTags"
}

export interface SetResourcesAction {
    type: typeof ResourceActionType.SetResources,
    resources: IResource[]
}

export interface SetResourceTagsAction {
    type: typeof ResourceActionType.SetResourceTags,
    resourceTags: IResourceTag[]
}

export type ResourceActions =
    SetResourcesAction |
    SetResourceTagsAction
