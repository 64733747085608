import React from "react";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {useTranslationLabel} from "../../hooks/useTranslation";

const VirtualRegister: React.FC = () => {
    const getLabel = useTranslationLabel();

    return <Grid container justify={'center'}>
        <Grid item sm={12} md={8} lg={6}>
            <Typography variant="h4" align={'center'} gutterBottom>
                {getLabel('ui.virtual.title')}
            </Typography>
            <Typography variant="subtitle1" align={'center'} gutterBottom>
                {getLabel('ui.virtual.explanation')}
            </Typography>
            <Box display="flex">
                <Box m={'auto'}>
                    <Button variant="contained" color="primary" href={'https://docs.google.com/forms/d/e/1FAIpQLSfwaNWMXdOJ8RNJ4da-bFck3JfmDAbr3sA31XIXqsMtdy8UXg/viewform'} target={'_blank'}>
                        {getLabel('ui.virtual.button_text')}
                    </Button>
                </Box>
            </Box>
        </Grid>
    </Grid>
};

export default VirtualRegister;
