import {useEffect, useState} from "react";
import Parse from "parse";

type EmailSettingsMap = Map<string, boolean>;

const useEmailSettings = (): [EmailSettingsMap, (rdns: string[]) => void, boolean, string] => {
    const [settings, setSettings] = useState<EmailSettingsMap>(new Map<string, boolean>());
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const fetchSubscriptions = async () => {
        setIsLoading(true);
        try {
            const result = await Parse.Cloud.run('userpub.getSubscriptions', {},
                {sessionToken: Parse.User.current()?.getSessionToken()});
            const map = new Map<string, boolean>();
            Object.keys(result).forEach(key => {
                map.set(key, result[key]);
            });
            setSettings(map);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    const saveSubscriptions = async (rdns: string[]) => {
        setIsLoading(true);
        try {
            const newSettings = new Map<string, boolean>();
            let subscriptions = {};
            for (let [key, value] of settings) {
                // @ts-ignore
                subscriptions[key] = rdns.includes(key);
                newSettings.set(key, rdns.includes(key));
            }
            setSettings(newSettings);
            const result = await Parse.Cloud.run('userpub.setSubscriptions', {
                    subscriptions: subscriptions
                },
                {sessionToken: Parse.User.current()?.getSessionToken()});
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return [settings, saveSubscriptions, isLoading, errorMessage];
};

export default useEmailSettings;
