import {Constraint} from "../data/constraints/Constraints";
import {ConstraintActions, ConstraintActionType} from "./ConstraintTypes";

export interface ConstraintState {
    // Maps datapoint rdns to constraints
    constraints: Map<string, Constraint>
}

const initialState: ConstraintState = {
    constraints: new Map<string, Constraint>()
};

export const ConstraintReducer = (state = initialState, action: ConstraintActions): ConstraintState => {
    switch (action.type) {
        case ConstraintActionType.SetConstraints:
            return {
                constraints: action.constraints
            };
        default:
            return state;
    }
};
