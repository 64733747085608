import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import {searchQuestions} from "../../redux/QuestionCreators";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Loading from "../LoadingComponent";

import StandardPicker, {getTags, PickerState} from "../StandardPickerComponent";
import QuestionCards from "./QuestionCardsComponent";
import {Grid} from "@material-ui/core";

interface SearchParameters {
    keywords: string,
    tags: string[]
}

const QuestionsSearch: React.FC = () => {
    const isLoading = useSelector((state: AppState) => state.questionsSearch.isLoading);
    const errorMessage = useSelector((state: AppState) => state.questionsSearch.errorMessage);
    const questions = useSelector((state: AppState) => state.questionsSearch.questions);
    const dispatch = useDispatch();
    const [keywords, setKeywords] = useState('');
    const [pickerState, setPickerState] = useState<PickerState | undefined>(undefined);
    const [lastSearch, setLastSearch] = useState<SearchParameters | undefined>(undefined);

    function handleSubmit(event: any) {
        event.preventDefault();
        if (pickerState) {
            triggerQuestionSearch(pickerState, keywords);
        }
    }

    const onChangedStandardPicker = (state: PickerState) => {
        setPickerState(state);
        triggerQuestionSearch(state, keywords);
    };

    const triggerQuestionSearch = (pickerState: PickerState, keywords: string) => {
        const tags = getTags(pickerState);
        if (lastSearch?.keywords !== keywords || JSON.stringify(lastSearch?.tags) !== JSON.stringify(tags)) {
            setLastSearch({keywords: keywords, tags: tags});
            dispatch(searchQuestions(keywords, tags));
        }
    };

    const RenderQuestions: React.FC = () => {
        if (isLoading) {
            return <Loading text="Loading questions..." fullHeight={false}/>
        } else if (errorMessage) {
            return <h1>Error {errorMessage}</h1>
        } else {
            return <QuestionCards questions={questions} pickerState={pickerState}/>
        }
    };

    const onChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeywords(event.target.value)
    };

    return <div>
        <Container component="main" maxWidth="xs">
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="keyword"
                            label="Search by keyword"
                            name="keyword"
                            autoComplete="keyword"
                            autoFocus
                            value={keywords}
                            onChange={onChangeKeyword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            SEARCH QUESTIONS
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <StandardPicker
                callback={onChangedStandardPicker}
                onlyStandardsWithQuestions={true}
                hasSessionContext={true}
                pickerState={undefined}/>
        </Container>

        <RenderQuestions/>
    </div>
};

export default QuestionsSearch;
