import React, {useEffect, useState} from 'react';
import {ISole, IUserPub} from "../shared/soleTypes";
import Parse from "parse";
import {useSelector} from "react-redux";
import {SessionContextItem} from "../redux/SessionTypes";
import {Tag} from "../data/Tag";
import {getAllSubjects} from "../shared/subjects";
import {AppState} from "../redux/configureStore";
import SelectionComponent from "./input/SelectionComponent";
import {SelectionConstraints} from "../data/constraints/Constraints";
import {useSessionContext} from "../hooks/useSessionContext";
import {Box, Grid} from '@material-ui/core';

function getPluralType(type?: string): string {
    let plural = 'All ';
    const specialCases: {[key: string]: string} = {
        "Accountability Criteria" : "Accountability Criteria",
        "Content" : "Content",
        "Evidence of Learning" : "Evidence of Learning",
        "Measurement Criteria" : "Measurement Criteria",
        "Processes and Inquiry Standards" : "Processes and Inquiry Standards",
        "Skills and Knowledge" : "Skills and Knowledge",
        "Body of Knowledge" : "Bodies of Knowledge",
        "Check for Understanding" : "Checks for Understanding",
        "Skill and Concept" : "Skills and Concepts",
        "Statement of Enduring Knowledge" : "Statements of Enduring Knowledge",
    };
    if (type) {
        if (type in specialCases){
            plural += specialCases[type];
        }
        else if (type.slice(-1) === "y") {
            plural += type.slice(0, -1) + 'ies';
        }
        else if (type.slice(-1) === "s") {
            plural += type + 'es';
        }
        else {
            plural += type + 's';
        }
    }
    return plural;
}

const allRdn = 'all';

export interface PickerState {
    subjects: Tag[],
    selectedSubjectRdn?: string,

    grades: Tag[],
    selectedGradeRdn?: string,

    tags: Tag[][],
    selectedTagRdns: string[]
}

async function getTag(rdn: string): Promise<Tag> {
    return await Parse.Cloud.run('tag.getJson', {
        rdn: rdn,
        options: {}
    }, {
        sessionToken: Parse.User.current()?.getSessionToken()
    });
}

export async function getPickerData(sole: ISole): Promise<[string?, string?, string[]?]> {
    let subjectRdn: string | undefined = undefined;
    let gradeRdn: string | undefined = undefined;
    let tags: string[] = [];

    if (sole.standard && sole.standard[0]) {
        gradeRdn = sole.grade;
        const tag = await getTag(sole.standard[0]);
        const subjectIndex = tag.partof?.findIndex(element => element.includes("asn.d"));
        if (tag.partof && subjectIndex !== undefined && subjectIndex >= 0) {
            subjectRdn = tag.partof[subjectIndex];
            for (let i = subjectIndex - 1; i >= 0; i--) {
                tags.push(tag.partof[i]);
            }
        }
    }
    return [subjectRdn, gradeRdn, tags];
}

export const initialPickerState: PickerState = {
    subjects: [],
    selectedSubjectRdn: allRdn,

    grades: [],
    selectedGradeRdn: undefined,

    tags: [],
    selectedTagRdns: []
};

const usePickerState = (
    onlyStandardsWithQuestions: boolean,
    pickerState: PickerState = initialPickerState):
    [
        PickerState,
        (rdn: string) => void,
        (rdn: string) => void,
        (index: number, rdn: string) => void,
        (subject?: string, grade?: string, tags?: string[]) => void,
        boolean,
        string
    ] => {
    const [state, setState] = useState(pickerState);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    async function select(subject?: string, grade?: string, tags?: string[]) {
        setIsLoading(true);
        try {
            const newState = {...state};
            newState.selectedSubjectRdn = subject ? subject : allRdn;
            newState.grades = await Parse.Cloud.run('webapp.getGrades', {
                subject: newState.selectedSubjectRdn && newState.selectedSubjectRdn !== 'all' ? newState.selectedSubjectRdn : false
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });

            if (newState.grades && newState.grades.length > 0) {
                newState.grades.unshift({
                    rdn: allRdn,
                    short: 'All Grades'
                });
            }

            newState.selectedGradeRdn = grade ? grade : allRdn;
            if (newState.selectedGradeRdn !== allRdn) {
                const nextTags = await Parse.Cloud.run('webapp.getStandards', {
                    rdn: newState.selectedSubjectRdn,
                    grade: newState.selectedGradeRdn,
                    showAll: !onlyStandardsWithQuestions
                }, {
                    sessionToken: Parse.User.current()?.getSessionToken()
                });
                if (nextTags && nextTags.length > 0) {
                    nextTags.unshift({
                        rdn: allRdn,
                        short: getPluralType(nextTags[0].type)
                    });
                }
                newState.tags = [nextTags];
            }

            if (tags) {
                newState.selectedTagRdns = tags;
                let i = 0;
                for (const tag of tags) {
                    if (i === tags.length - 1) { break; }
                    i++;
                    if (tag && tag !== allRdn) {
                        const nextTags = await Parse.Cloud.run('webapp.getStandards', {
                            rdn: tag,
                            grade: newState.selectedGradeRdn,
                            showAll: !onlyStandardsWithQuestions
                        }, {
                            sessionToken: Parse.User.current()?.getSessionToken()
                        });
                        if (nextTags && nextTags.length > 0) {
                            nextTags.unshift({
                                rdn: allRdn,
                                short: getPluralType(nextTags[0].type)
                            });
                        }
                        newState.tags.push(nextTags);
                    }
                }
            } else {
                newState.selectedTagRdns = [];
            }
            setIsLoading(false);
            if (JSON.stringify(state) !== JSON.stringify(newState)) {
                setState(newState);
            }
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    }

    async function selectSubject(rdn: string) {
        setIsLoading(true);

        try {
            const grades = await Parse.Cloud.run('webapp.getGrades', {
                subject: rdn && rdn !== 'all' ? rdn : false
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
            if (grades && grades.length > 0) {
                grades.unshift({
                    rdn: allRdn,
                    short: 'All Grades'
                });
            }

            setIsLoading(false);
            const newState = {
                ...state,
                selectedSubjectRdn: rdn,
                grades: grades,
                selectedGradeRdn: allRdn,
                tags: [],
                selectedTagRdns: []
            };
            setState(newState);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    }

    async function selectGrade(rdn: string) {
        if (state.selectedSubjectRdn && state.selectedSubjectRdn !== allRdn) {
            await addTag({...state, selectedGradeRdn: rdn}, state.selectedSubjectRdn, rdn, 0);
        }
    }

    async function addTag(state: PickerState, previousRdn: string, gradeRdn: string, atPosition: number) {
        setIsLoading(true);
        try {
            let tags: Tag[] = [];
            if (previousRdn !== allRdn) {
                tags = await Parse.Cloud.run('webapp.getStandards', {
                    rdn: previousRdn,
                    grade: gradeRdn,
                    showAll: !onlyStandardsWithQuestions
                }, {
                    sessionToken: Parse.User.current()?.getSessionToken()
                });
            }
            if (tags && tags.length > 0) {
                tags.unshift({
                    rdn: allRdn,
                    short: getPluralType(tags[0].type)
                });

                state.tags[atPosition] = tags;
                state.selectedTagRdns[atPosition] = allRdn;

                state.tags.splice(atPosition + 1);
                state.selectedTagRdns.splice(atPosition + 1);
            } else {
                state.tags.splice(atPosition);
                state.selectedTagRdns.splice(atPosition);
            }

            setState(state);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    }

    async function selectTag(index: number, rdn: string) {
        setIsLoading(true);

        const newState = {
            ...state
        };
        newState.selectedTagRdns[index] = rdn;
        await addTag(newState, rdn, state.selectedGradeRdn || "", index + 1);
    }

    useEffect(() => {
        select(state.selectedSubjectRdn, state.selectedGradeRdn, state.selectedTagRdns);
    }, [state]);

    return [state, selectSubject, selectGrade, selectTag, select, isLoading, errorMessage];
};

const usePickerSessionContext = (
    selectSubject: (rdn: string) => void,
    selectGrade: (rdn: string) => void,
    selectTag: (index: number, rdn: string) => void
) :
    [
        (pickerState: PickerState) => void
    ] => {
    const [pickerState, setPickerState] = useState<PickerState | undefined>(undefined);
    const [mySessionContext, setMySessionContext] = useSessionContext([]);

    useEffect(() => {
        if (pickerState) {
            const sessionContext: SessionContextItem[] = [];
            if (pickerState.selectedSubjectRdn) {
                sessionContext.push({
                    text: pickerState.subjects.find(tag => tag.rdn === pickerState.selectedSubjectRdn)?.short || "",
                    callback: () => {
                        if (pickerState.selectedSubjectRdn) {
                            selectSubject(pickerState.selectedSubjectRdn);
                        }
                    }
                });
            }
            if (pickerState.grades?.length > 0 && pickerState.selectedGradeRdn) {
                sessionContext.push({
                    text: pickerState.grades.find(tag => tag.rdn === pickerState.selectedGradeRdn)?.short || "",
                    callback: () => {
                        if (pickerState.selectedGradeRdn) {
                            selectGrade(pickerState.selectedGradeRdn);
                        }
                    }
                });
            }
            if (pickerState.tags.length > 0) {
                pickerState.selectedTagRdns.forEach((rdn, index) => {
                    if (rdn !== 'all') {
                        sessionContext.push({
                            text: pickerState.tags[index].find(tag => tag.rdn === pickerState.selectedTagRdns[index])?.short || "",
                            callback: () => {
                                selectTag(index, rdn);
                            }
                        });
                    }
                });
            }
            //           dispatch(setSessionContext(sessionContext));
            setMySessionContext(sessionContext);
        }
    }, [pickerState]);

    return [setPickerState];
};

export function getTags(pickerState: PickerState): string[] {
    let tags: string[] = [];
    if (pickerState.selectedSubjectRdn) {
        tags.push(pickerState.selectedSubjectRdn);
    }
    if (pickerState.selectedGradeRdn) {
        tags.push(pickerState.selectedGradeRdn);
    }
    pickerState.selectedTagRdns.forEach((rdn, index) => {
        tags.push(rdn);
    });
    tags = tags.filter(tag => tag !== 'all');
    return tags;
}

const getInitialPickerState = (pub?: IUserPub, state?: PickerState): PickerState | undefined => {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    if (params) {
        let pickerState: PickerState = {...initialPickerState};
        if (pub) {
            pickerState.subjects = getAllSubjects(pub);
        }
        let standardsStr = params.get('standards');
        if (standardsStr) {
            if (standardsStr && standardsStr.length > 0 && standardsStr.charAt(0) === '/') {
                standardsStr = standardsStr.slice(1);
            }
            let standards: string[] = standardsStr !== undefined ? standardsStr.split('/') : [];
            standards = standards.filter(standard => standard !== "");

            pickerState.selectedSubjectRdn = standards.length > 0 ? standards[0] : undefined;
            pickerState.selectedGradeRdn = standards.length > 1 ? standards[1] : undefined;
            pickerState.selectedTagRdns = standards.length > 2 ? standards.slice(2) : [];
        }

        return pickerState;
    }

    return state;
};

interface StandardPickerProps {
    callback: (pickerState: PickerState) => void,
    onlyStandardsWithQuestions: boolean,
    hasSessionContext: boolean,
    pickerState?: PickerState
}

const StandardPicker: React.FC<StandardPickerProps> = props => {
    const {callback} = props;
    const pub = useSelector((state: AppState) => state.user.pub);
    const [pickerState, selectSubject, selectGrade, selectTag, select, isLoading, errorMessage] =
        usePickerState(props.onlyStandardsWithQuestions, getInitialPickerState(pub, props.pickerState));
    const [setSessionContextState] = usePickerSessionContext(selectSubject, selectGrade, selectTag);
    const [urlString, setUrlString] = useState(window.location.href);

    if (window.location.href !== urlString) {
        setUrlString(window.location.href);
    }

    useEffect(() => {
        if (props.hasSessionContext) {
            setSessionContextState(pickerState);
        }
        callback(pickerState);
    }, [pickerState]);

    useEffect(() => {
        select(props.pickerState?.selectedSubjectRdn, props.pickerState?.selectedGradeRdn, props.pickerState?.selectedTagRdns);
    }, [props.pickerState]);

    // Subjects
    const subjectConstraint = new SelectionConstraints();
    subjectConstraint.min = 1;
    subjectConstraint.max = 1;
    subjectConstraint.items = pickerState.subjects
        .map(subject => { return { rdn: subject.rdn, title: subject.short }});

    const onChangeSubject = (value: string | string[] | undefined) => {
        if (typeof value === 'string') {
            selectSubject(value as string);
        }
    };

    const renderedSubjects = pickerState.subjects ?
        <SelectionComponent
            rdn={'picker-subjects'}
            onChange={onChangeSubject}
            constraint={subjectConstraint}
            initialValue={pickerState.selectedSubjectRdn}/> :
        <div/>;

    // Grades
    const gradeConstraint = new SelectionConstraints();
    gradeConstraint.min = 1;
    gradeConstraint.max = 1;
    gradeConstraint.items = pickerState.grades
        .map(grade => { return { rdn: grade.rdn, title: grade.short }});

    const onChangeGrade = (value: string | string[] | undefined) => {
        if (typeof value === 'string') {
            selectGrade(value as string);
        }
    };

    const renderedGrades = pickerState.grades ?
        <SelectionComponent
            rdn={'picker-grades'}
            onChange={onChangeGrade}
            constraint={gradeConstraint}
            initialValue={pickerState.selectedGradeRdn}/> :
        <div/>;

    // Tags
    const onChangeTag = (index: number, value: string | string[] | undefined) => {
        if (typeof value === 'string') {
            selectTag(index, value);
        }
    };

    const renderedTags = pickerState.tags.length > 0 ?
        pickerState.tags.map((tags, index) => {
            const tagConstraint = new SelectionConstraints();
            tagConstraint.min = 0;
            tagConstraint.max = 1;
            tagConstraint.items = tags
                .map(tag => { return { rdn: tag.rdn, title: tag.short }});

            return <Grid item xs={12} md={6}>
                <SelectionComponent
                    key={'picker-tag-' + index}
                    rdn={'picker-tag-' + index}
                    onChange={value => {onChangeTag(index, value)}}
                    constraint={tagConstraint}
                    initialValue={pickerState.selectedTagRdns[index]}
                />
            </Grid>
        }) :
        <div/>;

    return <Box mb={5}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                {renderedSubjects}
            </Grid>
            <Grid item xs={12} md={6}>
                {renderedGrades}
            </Grid>
            {renderedTags}
        </Grid>
    </Box>
};

export default StandardPicker;
