import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {questionAdd, questionAddReset} from "../../redux/QuestionCreators";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import {Redirect} from "react-router-dom";
import Loading from "../LoadingComponent";
import StandardPicker, {PickerState} from "../StandardPickerComponent";
import {useSessionContext} from "../../hooks/useSessionContext";
import {SessionContextItem} from "../../redux/SessionTypes";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {makeStyles} from "@material-ui/core/styles";

interface AddProps {
    location: {
        state: {
            pickerState?: PickerState
        }
    }
}

const useStyles = makeStyles({
    headerImage: {
        maxWidth: '100%'
    },
});

const QuestionAdd: React.FC<AddProps> = (props: AddProps) => {
    const [question, setQuestion] = useState('');
    const dispatch = useDispatch();
    const classes = useStyles();

    const isSaving = useSelector((state: AppState) => state.questionAdd.isSaving);
    const errorMessage = useSelector((state: AppState) => state.questionAdd.errorMessage);
    const addedQuestionId = useSelector((state: AppState) => state.questionAdd.addedQuestionId);
    const [sessionContext, setSessionContext] = useSessionContext([]);
    const getLabel = useTranslationLabel();

    useEffect(() => {
        return function cleanup() {
            if (addedQuestionId) {
                dispatch(questionAddReset());
            }
        }
    });

    useEffect(() => {
        let context: SessionContextItem[] = [
            {
                callback(): void {
                },
                text: getLabel('ui.add_a_question')!
            }
        ];
        if (question.length > 0) {
            context.push({
                callback(): void { },
                text: question
            });
        }
        setSessionContext(context);
    }, [question]);

    function handleSubmit(event: any) {
        console.log('Question:', question);
        event.preventDefault();
        dispatch(questionAdd(question));
    }

    if (isSaving) {
        return <div>
            <Loading text={getLabel('ui.adding_question')}/>
        </div>
    } else if (errorMessage) {
        return <h1>Error {errorMessage}</h1>
    } else if (addedQuestionId) {
        return <Redirect to={'/questions/' + addedQuestionId}/>
    } else {
        return <Container component="main" maxWidth="xs">
            <img className={classes.headerImage} src="/images/questions/add-question.svg"/>
            <form onSubmit={handleSubmit} noValidate>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    rowsMax={4}
                    id="question"
                    label={getLabel('ui.enter_your_question')}
                    name="question"
                    autoComplete="question"
                    autoFocus
                    value={question}
                    onChange={(e: any) => setQuestion(e.target.value)}
                />

                <StandardPicker
                    callback={() => {}}
                    hasSessionContext={false}
                    onlyStandardsWithQuestions={false}
                    pickerState={props.location?.state?.pickerState}/>

                <Button
                    disabled={question.length === 0}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {getLabel('ui.submit_question')}
                </Button>
            </form>
        </Container>;
    }
};

export default QuestionAdd;
