import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Scrollspy from "react-scrollspy-ez";
import {Link, Typography} from "@material-ui/core";
import VideoModal from "../how-to/VideoModalComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {useSessionContext} from "../../hooks/useSessionContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            height: 140,
            width: 100,
        },
        control: {
            padding: theme.spacing(2),
        },
        marginTop: {
            scrollMarginTop: "80px",
        },
        active: {
            fontWeight: 'bold'
        },
        scrollspy: {
            top: 70,
            // Fix IE 11 position sticky issue.
            //marginTop: 70,
            width: 175,
            flexShrink: 0,
            order: 2,
            position: 'sticky',
            height: 'calc(100vh - 70px)',
            overflowY: 'auto',
            padding: theme.spacing(2, 2, 2, 0),
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        ul: {
            padding: 0,
            marginLeft: theme.spacing(3),
            listStyleType: 'none'
        },
        item: {
            fontSize: 13,
            cursor: 'pointer',
            padding: theme.spacing(0.5, 0, 0.5, 1),
            borderLeft: '4px solid transparent',
            boxSizing: 'content-box',
            '&:hover': {
                borderLeft: `4px solid ${
                    theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
                    }`,
            },
            '&$active,&:active': {
                borderLeft: `4px solid ${
                    theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[800]
                    }`,
            },
        },
        anchor: { display: 'block', position: 'relative', visibility: 'hidden', top: '-100px' }
    }),
);

const HistoryOfSole: React.FC = () => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    useSessionContext([
        {
            callback(): void { },
            text: getLabel('ui.history_of_startsole')!
        }
    ]);

    return <Grid container className={classes.root} spacing={2}>
        <Grid item xs={10}>
            <section>
                <Typography id={"history"} variant="h5" gutterBottom className={classes.marginTop}>
                    History
                </Typography>
                <Typography variant="body1" gutterBottom>
                    In 1999, educational researcher Dr. Sugata Mitra conducted his pioneering <Link href="https://www.ted.com/talks/sugata_mitra_kids_can_teach_themselves" target="_blank" rel="noopener" variant={"body1"}>Hole in the Wall</Link> experiments to study how children learn in self-organized settings. In New Delhi and remote areas of India, students who had little to no formal education were given access to a computer and high speed internet while a camera monitored their interactions. The results were incredible and almost impossible to believe. Children with little or no input from an adult instructor led themselves on a process of enthusiastic exploration, discovery, and peer coaching that resulted in the ability to learn almost anything- new languages, the solution to complex questions, science and math, and more. Fourteen years of research and iteration since then continue to support these startling results. Professor Mitra has honed this work into a concept of Self-Organizing Learning Environments or SOLEs, which draw on children’s natural curiosity to create a dynamic space for students to learn, interact and develop critical problem-solving skills. Professor Mitra's vision has earned him the first ever one million dollar TED Prize. In January of 2015 SOLE CLE was launched. SOLE CLE supports the development of a self-sustaining network of practitioners implementing SOLE in schools and educational entities within the Cleveland Region and beyond.
                </Typography>
                <br/>
                <Grid container justify={"center"}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/y3jYVe1RGaU"}
                            title={"Sugata Mitra: Build a School in the Cloud"}
                            image={"/images/history/sugata.jpg"}
                            description={"Sugata Mitra presents his inspiring vision for Self Organized Learning at TED2013."}
                        />
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography id={"startSOLE"} variant="h5" gutterBottom className={classes.marginTop}>
                    StartSOLE
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Link href={"http://www.tedxcle.com/jeffrey-mcclellan/"} target={"_blank"} rel={"noopener"} variant={"body1"}>Jeff McClellan</Link> was the founding Head of School for <Link href={"https://www.mc2stemhighschool.org/"} target={"_blank"} rel={"noopener"} variant={"body1"}>MC2STEM High School</Link> and is now the Founding Director of StartSOLE. StartSOLE has a vision for turning SOLE into a mainstream movement. We are building a system by connecting innovative educators, community institutions and partners with entrepreneurs (who would normally find themselves in the private sector) to impact the future of education. We are thankful to the DBJ Foundation for seeing the potential in this movement.
                </Typography>
                <Grid container justify={"center"} spacing={2}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/h6TmvUDimoU"}
                            title={"The student success triangle: Jeffrey McClellan at TEDxCLE"}
                            image={"/images/history/jeff-tedxcle.jpeg"}
                            description={"Jeff McClellan's TEDxCLE talk introducing the student success triangle."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/z1GVX_jegxw"}
                            title={"Real-World Internships Lead to College and Career Readiness"}
                            image={"/images/history/edutopia-mc2stem.jpeg"}
                            description={"MC2STEM students are paired with industry mentors in order to learn STEM skills and prepare for college and career."}
                        />
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography id={"in-the-news"} variant="h5" gutterBottom className={classes.marginTop}>
                    In the News
                </Typography>
            </section>
        </Grid>
        <Grid item xs={2} className={classes.scrollspy}>


            <img src="/images/logos/sole-graphic-logo-small.png" alt="logo"/>
            <Scrollspy ids={['history', 'startSOLE', 'in-the-news']}
                       activeItemClassName={classes.active}
                       itemContainerClassName={classes.ul} itemClassName={classes.item}/>
        </Grid>
    </Grid>
};

export default HistoryOfSole;
