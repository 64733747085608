export interface Training {
    id: string,
    image: string,
    title: string,
    description: string,
    time?: string
}

export const trainings:Training[] = [
    {id: "UDL010", image: "/images/trainings/intro-to-udl-cover.png", title: "UDL Introduction", description: "A brief introduction to Understanding by Design.", time: '25 minutes'},
    {id: "UDL020", image: "/images/trainings/applying-udl-cover.png", title: "Applying UDL", description: "Tips on applying UDL in a classroom environment", time: '30 minutes'},
    {id: "UDL030", image: "/images/trainings/udl-and-math-cover.png", title: "UDL and Mathematics", description: "A guide to implementing UDL with Math", time: '15 minutes'},
    {id: "UDL040", image: "/images/trainings/udl-and-ela-cover.png", title: "UDL and ELA", description: "A guide to implementing UDL with ELA", time: '22 minutes'},
    {id: "UDL050", image: "/images/trainings/learner-variability-networks-cover.png", title: "Learner Variability Networks", description: "An introduction to Learning Variability Networks.", time: '23 minutes'},
];