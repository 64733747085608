import React, {useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import {BooleanConstraints} from "../../data/constraints/Constraints";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup} from "@material-ui/core";

interface BooleanProps {
    initialValue?: boolean,
    label: string,
    rdn: string,
    onChange: (value: boolean) => void,
    radio?: boolean
}

const BooleanComponent: React.FC<BooleanProps> = (props: BooleanProps) => {
    const [value, setValue] = useState<boolean | undefined>(props.initialValue);
    const constraints = useSelector((state: AppState) => state.constraints.constraints);

    const constraint = props.rdn ? constraints.get(props.rdn) : undefined;
    if (constraint instanceof BooleanConstraints) {
        if (value === undefined && constraint.defaultValue !== undefined) {
            setValue(constraint.defaultValue);
        }
        const strValue = value === undefined ? undefined : (value ? "true" : "false");

        if(props.radio) {
            return <FormControl component="fieldset">
                <FormLabel component="legend">{props.label}</FormLabel>
                <RadioGroup aria-label=""
                            name={props.rdn}
                            value={strValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const bool = (event.target as HTMLInputElement).value == "true";
                                setValue(bool);
                                props.onChange(bool);
                            }}>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>;
        }
        else {
            return <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={value}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                setValue(checked);
                                props.onChange(checked);
                            }}
                            name={props.rdn}
                            color="primary"
                        />
                    }
                    label={props.label}
                />
            </FormGroup>;
        }
            // <Typography component="legend">{props.label}</Typography>
            // <Switch
            //     checked={value}
            //     onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            //         setValue(checked);
            //         props.onChange(checked);
            //     }}
            // />

    } else {
        return <div/>;
    }
};

export default BooleanComponent;
