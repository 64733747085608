import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {resetUser} from "../redux/UserCreators";
import Login from "./LoginComponent";

const Logout: React.FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetUser());
    });

    return <Login/>;
};

export default Logout;
