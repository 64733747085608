import React from "react";
import Joyride, {CallBackProps} from "react-joyride";

interface JoyrideTourProps {
    start: boolean,
    onClose: ()=>void
}

const JoyrideTour: React.FC<JoyrideTourProps> = (props: JoyrideTourProps) => {

    // const[run, setRun] = useState(props.start);

    const handleJoyrideCallback = (data:CallBackProps) => {
         if (data.action === "close") {
            props.onClose();
        }
    };

    return <Joyride
        callback={handleJoyrideCallback}
        // getHelpers={this.getHelpers}
        scrollToFirstStep={true}
        run={
            props.start
        }
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        styles={{
            options: {
                zIndex: 10000,
            },
        }}
        steps={
            [{
                content: <h2>Step 1</h2>,
                locale: { skip: <strong>SKIP</strong>},
                placement: 'center',
                target: 'body'
            },{
                content: <h2>Step 2</h2>,
                locale: { skip: <strong>SKIP</strong>},
                spotlightPadding: 20,
                target: '.home_power'
            },{
                content: <h2>Step 3</h2>,
                locale: { skip: <strong>NOPE</strong>},
                placement: 'center',
                spotlightPadding: 20,
                target: 'h1'
            },{
                content: <h2>Step 4</h2>,
                locale: { skip: <strong>NO</strong>},
                placement: 'center',
                spotlightPadding: 20,
                target: 'h1'
            }]
        }></Joyride>;
};

export default JoyrideTour;
