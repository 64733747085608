import {SessionContextItem} from "../redux/SessionTypes";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {setSessionContext} from "../redux/SessionCreators";

export const useSessionContext = (context: SessionContextItem[]): [SessionContextItem[], (context: SessionContextItem[]) => void] => {
    const [myContext, setMySessionContext] = useState(context);

    //TODO: https://stackoverflow.com/questions/58624200/react-hook-useeffect-has-a-missing-dependency-dispatch
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSessionContext(myContext));

        return () => { dispatch(setSessionContext([])) };
    }, [myContext]);

    return [myContext, setMySessionContext];
};
