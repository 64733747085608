import React, {useEffect, useState} from "react";
import {Avatar, IconButton, InputAdornment, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Parse from "parse";
import {Alert} from "@material-ui/lab";
import {useTranslationLabel} from "../../hooks/useTranslation";

const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        margin: "auto",
        backgroundColor: "#96D3E2"
    },
    passwordAlert: {
        marginTop: "1rem"
    }
}));

const useUpdatePassword = (): [(currentPassword: string, newPassword: string) => void, boolean, string, boolean] => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [wasUpdated, setWasUpdated] = useState(false);

    const update = (currentPassword: string, newPassword: string): void => {
        (async () => {
            setWasUpdated(false);
            setIsUpdating(true);
            let user = Parse.User.current();
            if (user) {
                try {
                    // @ts-ignore
                    await user.verifyPassword(currentPassword);
                    user.setPassword(newPassword);
                    await user.save(null, {sessionToken: user.getSessionToken()});
                    setErrorMessage('');
                    setIsUpdating(false);
                    setWasUpdated(true);
                } catch (error) {
                    let message = error.message;
                    if (error.code === Parse.Error.OBJECT_NOT_FOUND) {
                        message = "Invalid password";
                    }
                    setErrorMessage(message);
                    setIsUpdating(false);
                }
            }
        })();
    };

    return [update, isUpdating, errorMessage, wasUpdated];
};

const PasswordReset: React.FC = () => {
    const [update, isUpdating, errorMessage, wasUpdated] = useUpdatePassword();
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const classes = useStyles();
    const getLabel = useTranslationLabel();

    useEffect(() => {
        if (wasUpdated) {
            setCurrentPassword('');
            setNewPassword('');
        }
    }, [wasUpdated]);

    const onClickUpdatePassword = () => {
        console.log(currentPassword + ' -> ' + newPassword);
        update(currentPassword, newPassword);
    };

    return <div>
        <Avatar alt="Password Image" src="/images/profile/password-avatar.svg" className={classes.avatar} />
        <Typography variant="body1" gutterBottom>
            {getLabel('ui.profile.password_explanation')}
        </Typography>

        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={errorMessage.length > 0}
            helperText={errorMessage}
            name={getLabel('ui.profile.password')}
            label={getLabel('ui.profile.current_password')}
            type={showCurrentPassword ? "text" : "password"}
            id="currentpassword"
            value={currentPassword}
            onChange={(e: any) => setCurrentPassword(e.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={() => setShowCurrentPassword(true)}
                        onMouseUp={() => setShowCurrentPassword(false)}
                    >
                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            }}
        />

        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name={getLabel('ui.profile.password')}
            label={getLabel('ui.profile.new_password')}
            type={showNewPassword ? "text" : "password"}
            id="newpassword"
            value={newPassword}
            onChange={(e: any) => setNewPassword(e.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onMouseDown={() => setShowNewPassword(true)}
                            onMouseUp={() => setShowNewPassword(false)}
                        >
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
            }}
        />

        <Button
            disabled={isUpdating || currentPassword.length === 0 || newPassword.length === 0}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onClickUpdatePassword()}
        >
            {getLabel('ui.profile.update_password')}
        </Button>

        {wasUpdated ?
            <Alert
                className={classes.passwordAlert}
                severity="success">
                {getLabel('ui.profile.password_updated')}
            </Alert>
            : <div/>
        }

    </div>
};

export default PasswordReset;
