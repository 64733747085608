import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Container,
    createStyles,
    Grid,
    Step,
    StepButton,
    Stepper,
    Theme,
    Typography
} from "@material-ui/core";
import {match, Redirect, useHistory, useLocation} from "react-router-dom";
import queryString from 'query-string';
import useFetchQuestion from "../../hooks/useFetchQuestion";
import Loading from "../LoadingComponent";
import {getPickerData, initialPickerState, PickerState} from "../StandardPickerComponent";
import {IQuestion, ISole} from "../../shared/soleTypes";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useFetchSole from "../../hooks/useFetchSole";
import SoleSelectionComponent from "./SoleSelectionComponent";
import SoleNumberComponent from "./SoleNumberComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {useSessionContext} from "../../hooks/useSessionContext";
import {SessionContextItem} from "../../redux/SessionTypes";
import useSaveSole from "../../hooks/useSaveSole";
import SoleBooleanComponent from "./SoleBooleanComponent";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import Parse from "parse";
import ObservationCard from "./ObservationCardComponent";
import {DropzoneArea} from "material-ui-dropzone";
import SoleStringComponent from "./SoleStringComponent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        paper: {
            height: theme.spacing(36)
        },
        constrainWidth: {
            maxWidth: '223px'
        },
        sideMargin: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        },
        topMargin: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        marginBottom: {
            marginBottom: theme.spacing(5),
            marginTop: '-25px',
        },
        marginBottomOnly: {
            marginBottom: theme.spacing(5),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        completed: {
            display: 'inline-block',
        },
        uploadCard: {
            maxWidth: "100%",
        },
    }),
);

interface Identifiable {
    id: string
}

interface ReflectProps {
    match?: match<Identifiable>
    location: {
        state: {
            question: IQuestion,
            pickerState: PickerState
        }
    }
}

const Reflect: React.FC<ReflectProps> = (props: ReflectProps) => {
    const getLabel = useTranslationLabel();
    const classes = useStyles();
    const location = useLocation();
    const texts = useSelector((state: AppState) => state.text.texts);
    const parsed = queryString.parse(location.search);
    const [question, isLoadingQuestion, questionErrorMessage, fetchQuestion] = useFetchQuestion(parsed.question as string || '');
    const [sole, isLoadingSole, soleErrorMessage, fetchSole] = useFetchSole(undefined);
    const [activeStep, setActiveStep] = useState(0);
    const [mySole, setMySole] = useState<ISole | undefined>(undefined);
    const [myPickerState, setMyPickerState] = useState<PickerState | undefined>(undefined);
    const [pickerState, setPickerState] = useState<PickerState | undefined>(undefined);
    const [saveSole, savedSole, isSavingSole, saveSoleErrorMessage] = useSaveSole();
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
    const [pictureFiles, setPictureFiles] = React.useState<File[]>([]);

    const steps = getSteps();

    const [sessionContext, setSessionContext] = useSessionContext([]);
    const history = useHistory();

    useEffect(() => {
        let context: SessionContextItem[] = [
            {
                callback(): void {
                    history.push('/soles');
                },
                text: getLabel('ui.my_soles')!
            }
        ];
        if (mySole?.question?.text) {
            context.push({
                callback(): void { },
                text: mySole?.question?.text
            });
        }
        setSessionContext(context);
        //TODO: make a more elegant fix for this and validate content in relationship to other DPVs (i.e. you can't have more groups than students)
        const newCompleted = completed;
        newCompleted[0] = (mySole?.question?.text ?? '').length>0;
        newCompleted[1] = completedReflectObjectives();
        newCompleted[2] = completedReflectRating();
        newCompleted[3] = completedReflectFinal();
        setCompleted(newCompleted);
    }, [mySole]);

    useEffect(() => {
        const soleId = props.match?.params.id || '';
        if (soleId) {
            fetchSole(soleId);
        } else {
            if (props.location?.state?.pickerState) {
                setMyPickerState(props.location?.state?.pickerState);
            }

            const question = props.location?.state?.question;
            const urlQuestionId = parsed.question as string;
            if (question && (urlQuestionId && question?.id === urlQuestionId || urlQuestionId === undefined)) {
                setMySole({
                    question: question
                });
            } else if (urlQuestionId && urlQuestionId !== question?.id) {
                setMySole({});
                fetchQuestion(urlQuestionId);
            } else {
                setMySole(
                    {}
                );
            }
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (sole) {
                setMySole(sole);
                const pickerState = myPickerState ? myPickerState : initialPickerState;
                const [subjectRdn, gradeRdn, tags] = await getPickerData(sole);
                pickerState.selectedSubjectRdn = subjectRdn;
                pickerState.selectedGradeRdn = gradeRdn;
                pickerState.selectedTagRdns = tags || [];
                setMyPickerState(pickerState);
            }
        })();
    }, [sole]);

    useEffect(() => {
        if (question) {
            setMySole({
                ...mySole,
                question: question
            });
        }
    }, [question]);

    const handleSave = async () => {
        if (mySole) {
            //TODO: this does not properly save standards attached to a SOLE.  Neither does the old client though so not fixing now
            console.log(JSON.stringify(mySole));
            await saveSole(mySole);
        }
    };

    const handleDateChange = (date: Date | null) => {
        if (date) {
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const plannedDate = date;
            plannedDate.setHours(hours, minutes, seconds);
            setMySole({
                ...mySole,
                plannedDate: plannedDate
            });
        }
    };

    const handleTimeChange = (date: Date | null) => {
        if (date) {
            let plannedDate = mySole?.plannedDate || date;
            plannedDate.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
            setMySole({
                ...mySole,
                plannedDate: plannedDate
            });
        }
    };

    const onChangedStandardPicker = (state: PickerState) => {
        setPickerState(state);
    };

    const onChangePictures = (files: File[]): void => {
        setPictureFiles(files);
    };

    useEffect(() => {
        handleSavePictures();
    }, [pictureFiles]);

    const handleSavePictures = async () => {
        const uploadedFiles:File[] = [];
        for (let file of pictureFiles) {
            try {
                let parseFile = new Parse.File("picture", file);
                const obj = await parseFile.save();
                const response = await Parse.Cloud.run('webapp.saveImage', {
                    id: mySole?.id,
                    imageFile: obj
                });
                uploadedFiles.push(file);
            } catch (error) {
                console.log(error);
            }
        }
        if(uploadedFiles.length > 0) {
            setPictureFiles(pictureFiles.filter(file => !uploadedFiles.includes(file)));
            if(mySole?.id) {
                fetchSole(mySole.id);
            }
        }
    };

    const renderReflectObservations = () => {
        return <div>
        <Grid container spacing={3}>
            {mySole?.observations?.filter(obs => (obs.img !== "")).map(obs =>
                <Grid item key={obs.id} xs={12} sm={6} md={4}>
                    <ObservationCard observation={obs}/>
                </Grid>)}
        </Grid>
            <Box mt={5}>
                        <DropzoneArea
                            acceptedFiles={['image/*']}
                            dropzoneText={getLabel("ui.reflect.drop_photos")}
                            filesLimit={10}
                            onChange={onChangePictures}
                            showAlerts={['error']}
                        />
            </Box>
        </div>
    };

    const renderQuestion = () => {
        if (isLoadingQuestion) {
            return <Loading text="Loading..."/>
        } else if (questionErrorMessage) {
            return <div>
                <h1>{questionErrorMessage}</h1>
            </div>
        } else {
            return <Grid container justify="space-around" spacing={3}>
                <Grid item xs={10} sm={8}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        id="question"
                        label={ getLabel('ui.plan.what_is_your_big_question') }
                        name="question"
                        value={mySole?.question?.text}
                        onChange={(e: any) => {
                            if (mySole) {
                                const question = mySole.question ?? {};
                                question.text = e.target.value;
                                setMySole({
                                    ...mySole,
                                    question: question
                                });
//                            if (!mySole?.question) {
//                                mySole.question = {};
//                            }
//                            mySole.question.text = e.target.value;
//                            setMySole(mySole);
                            }
                        }}
                    />
                </Grid>
            </Grid>
        }
    };

    /*
    const renderPicker = () => {
        return <Grid container justify="space-around" spacing={3}>
            <Grid item xs={10} sm={8}>
                <StandardPicker
                    hasSessionContext={false}
                    callback={onChangedStandardPicker}
                    onlyStandardsWithQuestions={false}
                    pickerState={myPickerState}/>
            </Grid></Grid>;
    };

    const renderClassDetails = () => {
        return <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around" spacing={3}>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label={getLabel('ui.plan.what_is_the_planned_date?')}
                            format="MM/dd/yyyy"
                            fullWidth
                            value={mySole?.plannedDate}
                            // className={classes.sideMargin}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label={getLabel('ui.plan.what_is_the_planned_time?')}
                            fullWidth
                            value={mySole?.plannedDate}
                            // className={classes.sideMargin}
                            onChange={handleTimeChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleNumberComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="plannedDuration"
                            rdn="session.planned_duration"
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleNumberComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="numDevices"
                            rdn="session.num_devices"
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleNumberComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="numStudents"
                            rdn="session.num_students"
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleNumberComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="numGroups"
                            rdn="session.num_groups"
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={10} sm={8} md={6} lg={4}>
                        <SoleSelectionComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="grade"
                            rdn="session.grade"
                        />
                    </Grid>
                    <Grid item xs={10} sm={8} md={6} lg={4}>
                        <SoleSelectionComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="subject"
                            rdn="session.subject"
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </>
    };

    const renderObservationDetails = () => {
        return <>
            <Grid container justify="space-around" spacing={3}>
                <Grid item xs={10} sm={8}>
                    <SoleSelectionComponent
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey="contentObjectiveRdn"
                        rdn="session.content_objective"
                    />
                </Grid>
            </Grid>
            <Grid container justify="space-around" spacing={3}>
                <Grid item xs={10} sm={8} md={5} lg={4}>
                    <SoleSelectionComponent
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey="materials"
                        rdn="session.materials"
                        isExpanded={true}
                    />
                </Grid>
                <Grid item xs={10} sm={8} md={5} lg={4}>
                    <FormLabel>How will you manage this SOLE?</FormLabel>
                    <SoleBooleanComponent
                        label={texts.find(t => t.rdn === "session.grouporganization")?.short ?? ''}
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey='grouporganization'
                        rdn='session.grouporganization'
                    />
                    <SoleBooleanComponent
                        label={texts.find(t => t.rdn === "session.groupsharing")?.short ?? ''}
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey='groupsharing'
                        rdn='session.groupsharing'
                    />
                    <SoleBooleanComponent
                        label={texts.find(t => t.rdn === "session.self_assessment")?.short ?? ''}
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey='self_assessment'
                        rdn='session.self_assessment'
                    />
                </Grid>
                <Grid item xs={10} sm={8} md={12} lg={10}>
                    <SoleSelectionComponent
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey="targetObservations"
                        rdn="session.target_observations"
                        isExpanded={true}
                        isRow={true}
                    />
                </Grid>
            </Grid>
        </>;
    };
     */

    const renderReflectObjectives = () => {
        return <div>
                    <Grid container spacing={3}>
                            <Grid item xs={12}>
                                    <SoleSelectionComponent
                                        sole={mySole}
                                        setSole={setMySole}
                                        jsonKey="contentObjectiveRdn"
                                        rdn="session.content_objective"
                                    />
                            </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant={'body1'}>
                                {getLabel('ui.reflect.objective_question_achieved')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <SoleSelectionComponent
                                label={''}
                                sole={mySole}
                                setSole={setMySole}
                                jsonKey="reflectionContentObjectiveAchievedRdn"
                                rdn="session.reflection.content_objective.achieved"
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.marginBottom}>
                            <SoleStringComponent
                                label={'Why do you think this?'}
                                sole={mySole}
                                setSole={setMySole}
                                jsonKey='reflectionContentObjectiveNotes'
                                rdn='session.reflection.content_objective.notes'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SoleSelectionComponent
                                sole={mySole}
                                setSole={setMySole}
                                jsonKey="reflectionTypeOfThinkingRdn"
                                rdn="session.reflection.type_of_thinking"
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.marginBottom}>
                            <SoleStringComponent
                                label={'Why do you think this?'}
                                sole={mySole}
                                setSole={setMySole}
                                jsonKey='reflectionContentObjectiveNotes'
                                rdn='session.reflection.content_objective.notes'
                            />
                        </Grid>
                    </Grid>
        </div>
    };

    function completedReflectObjectives() {
        if(mySole) {
            return mySole.contentObjectiveRdn !== undefined &&
                mySole.reflectionContentObjectiveAchievedRdn !== undefined &&
                mySole.reflectionTypeOfThinkingRdn !== undefined;
        }
        return false;
    }

    const renderReflectRatings = () => {
        return <div>

                <Grid item sm={12}>
                    <Box mb={3}>
                        {/*TODO: make the text for this DP past tense or have another DP for it*/}
                        <SoleNumberComponent
                            label={texts.find(t => t.rdn === "session.engagement.ratio")?.short ?? ''}
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey='engagementRatio'
                            rdn='session.engagement.ratio'
                        />
                    </Box>

                    <Box mb={3}>
                        <SoleNumberComponent
                            label={texts.find(t => t.rdn === "reflection.collaboration_group")?.short ?? ''}
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey='reflectionCollaboration'
                            rdn='reflection.collaboration'
                        />
                    </Box>

                    <Box mb={3}>
                        <SoleNumberComponent
                            label={texts.find(t => t.rdn === "reflection.technology_group")?.short ?? ''}
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey='reflectionTechnology'
                            rdn='reflection.technology'
                        />
                    </Box>

                    <Box mb={3}>
                        <SoleNumberComponent
                            label={texts.find(t => t.rdn === "reflection.communication_group")?.short ?? ''}
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey='reflectionCommunication'
                            rdn='reflection.communication'
                        />
                    </Box>
                </Grid>
        </div>
    };

    function completedReflectRating() {
        if(mySole) {
            return mySole.engagementRatio !== undefined  || mySole.reflectionCollaboration !== undefined ||
                mySole.reflectionTechnology !== undefined || mySole.reflectionCommunication !== undefined
        }
        return false;
    }

    const renderReflectFinalStep = () => {
        return <div>

                <Grid item xs={12}>
                    {/*TODO: change the rule for this DP to default to true*/}
                    <SoleBooleanComponent
                        label={texts.find(t => t.rdn === "reflection.ground_rules")?.short ?? ''}
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey='reflectionGroundRules'
                        rdn='reflection.ground_rules'
                        radio={true}
                    />
                </Grid>

                <Grid item xs={12} className={classes.marginBottomOnly}>
                    <SoleBooleanComponent
                        label={texts.find(t => t.rdn === "reflection.need_help")?.short ?? ''}
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey='reflectionNeedHelp'
                        rdn='reflection.need_help'
                        radio={true}
                    />
                    {mySole?.reflectionNeedHelp ?
                        <SoleStringComponent
                            label={texts.find(t => t.rdn === "reflection.help_text")?.short ?? ''}
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey='reflectionHelpText'
                            rdn='reflection.help_text'
                        />: <></>}
                </Grid>
        </div>
    };

    function completedReflectFinal() {
        if(mySole) {
            return mySole.reflectionGroundRules !== undefined &&
            mySole.reflectionNeedHelp !== undefined;
        }
        return false;
    }

    const isValidDuration = mySole?.plannedDuration === undefined ||
        mySole?.plannedDuration && mySole?.plannedDuration >= 30;

    function getSteps() {
        return [
            mySole?.observations && mySole?.observations.length>0 ? getLabel('ui.reflect.review_photos') : getLabel('ui.reflect.add_photos'),
            getLabel('ui.reflect_objectives'),
            getLabel('ui.reflect_ratings'),
            getLabel('ui.reflect_final')
        //    getLabel('ui.plan.big_question_and_standard'), getLabel('ui.plan.class_details'), getLabel('ui.plan.objective_logistics')

        ];
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return renderReflectObservations();
            case 1:
                return renderReflectObjectives();
            case 2:
                return renderReflectRatings();
            case 3:
                return renderReflectFinalStep();
            default:
                return 'Unknown step';
        }
    }

    // NOTE: below code is identical for PlanComponent and ReflectComponent => TODO: abstract this into shared code

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.values(completed).filter(o => o).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    if (isSavingSole) {
        return <Loading text="Saving SOLE..."/>
    } else if (savedSole) {
        return <Redirect to='/soles/'/>
    } else if (isLoadingQuestion || isLoadingSole) {
        return <Loading text={ getLabel('ui.loading') }/>
    } else if (questionErrorMessage || soleErrorMessage) {
        return <div>
            <h1>{questionErrorMessage}{soleErrorMessage}</h1>
        </div>;
    } else {
        return <Container maxWidth="md">
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) =>
                    <Step key={label}>
                        <StepButton onClick={handleStep(index)} completed={completed[index]}>
                            {label}
                        </StepButton>
                    </Step>
                )}
            </Stepper>
            <div>

                {getStepContent(activeStep)}
                <Box mt={10}>
                    <Grid container justify={'center'}>
                        {activeStep > 0 ?
                            <Button onClick={handleBack} className={classes.button}>
                                Back
                            </Button> : <></>
                        }
                        {!isLastStep() ?
                            <Button
                                variant={allStepsCompleted() ? undefined : "contained"}
                                color={allStepsCompleted() ? undefined : "primary"}
                                onClick={handleNext}
                                className={classes.button}>
                                Next
                            </Button> :
                            <Button
                                disabled={!allStepsCompleted()}
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                className={classes.button}
                            >
                                {getLabel('ui.save')}
                            </Button>}
                    </Grid>
                </Box>

            </div>
        </Container>;
    }
};

export default Reflect;
