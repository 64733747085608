import {ISole} from "../shared/soleTypes";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import Parse from "parse";
import {addSole, setSoles} from "../redux/SolesCreators";
import {AppState} from "../redux/configureStore";

const useSaveSole = (): [(sole: ISole) => void, ISole | undefined, boolean, string | undefined] => {
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [savedSole, setSavedSole] = useState<ISole | undefined>(undefined);
    const soles = useSelector((state: AppState) => state.soles.soles);

    const save = async (sole: ISole) => {
        setIsSaving(true);
        try {
            const newSole = await Parse.Cloud.run('webapp.saveSole', {
                sole: sole
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });

            const jsonSole = await Parse.Cloud.run('webapp.getSoleJsonById', {
                id: newSole.id
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });

            dispatch(addSole(newSole.id));

            const newSoles = [...soles];
            const index = newSoles.findIndex(s => s.id === sole.id);
            if (index >= 0) {
                newSoles[index] = jsonSole;
            } else {
                newSoles.unshift(jsonSole);
            }
            dispatch(setSoles(newSoles));

            setIsSaving(false);
            setSavedSole(newSole);
        } catch (error) {
            setIsSaving(false);
            setErrorMessage(error.message);
        }

    };
    return [save, savedSole, isSaving, errorMessage];
};

export default useSaveSole;
