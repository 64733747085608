import React, {useState} from 'react';
import {Box, Tab, Tabs, Theme, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LockIcon from '@material-ui/icons/Lock';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import FolderIcon from '@material-ui/icons/Folder';
import LocationSelector from "./ProfileLocationComponent";
import EmailSettings from "./ProfileEmailComponent";
import PasswordReset from "./ProfilePasswordComponent";
import Certificates from "./ProfileCertificateComponent";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {AboutMeForm} from "./ProfileAboutMeFormComponent";
import TrainerMaterials from "./ProfileTrainerComponent";
import {useSessionContext} from '../../hooks/useSessionContext';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{maxWidth: "440px"}}
        {...other}
    >
        {value === index && <Box p={3}>
            {children}
        </Box>}
    </div>;
};

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Profile: React.FC = () => {
    const theme = useTheme();
    const iconsOnly = useMediaQuery('(min-width:800px)');
    const getLabel = useTranslationLabel();
    useSessionContext([
        {
            callback(): void { },
            text: getLabel('ui.my_profile')!
        }
    ]);

    const useStyles = makeStyles((theme: Theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            margin: 'auto',
            maxWidth: '600px'
        },
        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
            minWidth: "48px"
        },
        tab: {
            minWidth: "48px"
        },
        tabPanel: {
            width: "100%"
        },
        hidden: {
            display: "none"
        }
    }));

    const classes = useStyles();
    const [value, setValue] = useState(0);

    const pub = useSelector((state: AppState) => state.user.pub);
    const showPassword = pub?.accountType === "parse";

    const showTrainer = pub?.roles?.some((el) => el === 'trainer');

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return <div className={classes.root}>
        {/*<Paper className={classes.root}*/}
        {/*square={isSmall}*/}
        {/*elevation={isSmall ? 0 : 3}*/}
        {/*>*/}
        <Tabs
            value={value}
            variant="scrollable"
            orientation="vertical"
            onChange={handleChange}
            className={classes.tabs}
        >
            <Tab classes={{root: classes.tab}}
                 label={iconsOnly ? getLabel('ui.profile.about_me') : ""}
                 icon={iconsOnly ? "" : <AccountCircleIcon />}
                 {...a11yProps(0)}/>
            <Tab classes={{root: classes.tab}}
                 label={iconsOnly ? getLabel('ui.profile.location') : ""}
                 icon={iconsOnly ? "" : <LocationOnIcon />}
                 {...a11yProps(1)}/>
            <Tab classes={{root: classes.tab}}
                 label={iconsOnly ? getLabel('ui.profile.email') : ""}
                 icon={iconsOnly ? "" : <EmailIcon />}
                 {...a11yProps(2)}/>
            <Tab classes={{root: classes.tab}}
                 label={iconsOnly ? getLabel('ui.profile.certificates') : ""}
                 icon={iconsOnly ? "" : <FolderIcon />}
                 {...a11yProps(3)}/>
            <Tab classes={showTrainer ? {root: classes.tab} : {root: classes.hidden}}
                 label={iconsOnly ? getLabel('ui.profile.trainer_materials') : ""}
                 icon={iconsOnly ? "" : <EmojiPeopleIcon />}
                 {...a11yProps(4)}/>
            <Tab classes={showPassword ? {root: classes.tab} : {root: classes.hidden}}
                 label={iconsOnly ? getLabel('ui.profile.password') : ""}
                 icon={iconsOnly ? "" : <LockIcon />}
                 {...a11yProps(5)}/>
        </Tabs>
        <TabPanel index={0} value={value}>
            <AboutMeForm/>
        </TabPanel>
        <TabPanel index={1} value={value}>
            <LocationSelector/>
        </TabPanel>
        <TabPanel index={2} value={value}>
            <EmailSettings/>
        </TabPanel>
        <TabPanel index={3} value={value}>
            <Certificates/>
        </TabPanel>
        { showTrainer ? <TabPanel index={4} value={value}>
            <TrainerMaterials/>
        </TabPanel> : null}
        { showPassword ? <TabPanel index={5} value={value}>
            <PasswordReset/>
        </TabPanel> : null}

        {/*</Paper>*/}
    </div>
};

export default Profile;
