import {applyMiddleware, combineReducers, createStore} from 'redux';
import {UserReducer} from "./UserReducer";
import logger from "redux-logger";
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga/index'
import {MyQuestionsReducer, QuestionAddReducer, QuestionsSearchReducer} from "./QuestionReducers";
import {SolesReducer} from "./SolesReducer";
import {DataPointsReducer} from "./DataPointReducer";
import {ConstraintReducer} from "./ConstraintReducer";
import {TextReducer} from "./TextReducer";
import {UserActionType} from "./UserTypes";
import {SessionReducer} from "./SessionReducer";
import {ResourceReducer} from "./ResourceReducer";
import {ReportReducer} from "./ReportReducer";

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
    session: SessionReducer,
    user: UserReducer,
    soles: SolesReducer,
    dataPoints: DataPointsReducer,
    constraints: ConstraintReducer,
    myQuestions: MyQuestionsReducer,
    questionAdd: QuestionAddReducer,
    questionsSearch: QuestionsSearchReducer,
    resources: ResourceReducer,
    text: TextReducer,
    report: ReportReducer
});

export type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppState | undefined, action: any): any => {
    if (action.type === UserActionType.ResetUser) {
        state = undefined;
    }

    return appReducer(state, action);
};

export const configureStore = () => {
    const store = createStore(
        rootReducer,
        applyMiddleware(
            thunk,
            sagaMiddleware,
//            localStorageMiddleware,
            logger
        )
    );

    sagaMiddleware.run(rootSaga);
    return store;
};

