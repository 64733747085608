// Action creators

import {
    MyQuestionsActions,
    MyQuestionsActionType,
    QuestionActionType,
    QuestionAddActions,
    QuestionsSearchActions,
    QuestionsSearchType
} from "./QuestionTypes";
import {IQuestion} from "../shared/soleTypes";

// My questions

export const loadMyQuestions = (): MyQuestionsActions => ({
    type: MyQuestionsActionType.LoadMyQuestions
});

export const loadMyQuestionsFinished = (): MyQuestionsActions => ({
    type: MyQuestionsActionType.LoadMyQuestionsFinished
});

export const setMyQuestions = (questions: IQuestion[]): MyQuestionsActions => ({
    type: MyQuestionsActionType.SetMyQuestions,
    questions: questions
});

export const setMyFavoriteQuestions = (questions: IQuestion[]): MyQuestionsActions => ({
    type: MyQuestionsActionType.SetMyFavoriteQuestions,
    questions: questions
});

export const loadMyQuestionsFailed = (errorMessage: string): MyQuestionsActions => ({
    type: MyQuestionsActionType.LoadMyQuestionsFailed,
    errorMessage: errorMessage
});

export const toggleFavoriteQuestion = (id: string): MyQuestionsActions => ({
    type: MyQuestionsActionType.ToggleFavoriteQuestion,
    id: id
});

// Add question

export const questionAdd = (questionText: string): QuestionAddActions => ({
    type: QuestionActionType.QuestionAdd,
    questionText: questionText
});

export const questionAddFailed = (errorMessage: string): QuestionAddActions => ({
    type: QuestionActionType.QuestionAddFailed,
    errorMessage: errorMessage
});

export const questionAdded = (questionId: string): QuestionAddActions => ({
    type: QuestionActionType.QuestionAdded,
    questionId: questionId
});

export const questionAddReset = (): QuestionAddActions => ({
    type: QuestionActionType.QuestionAddReset
});

// Search questions
export const searchQuestions = (keywords: string, tags: string[]): QuestionsSearchActions => ({
    type: QuestionsSearchType.QuestionsSearch,
    keywords: keywords,
    tags: tags
});

export const questionsLoading = (): QuestionsSearchActions => ({
    type: QuestionsSearchType.QuestionsLoading
});

export const questionsLoadingFailed = (errorMessage: string): QuestionsSearchActions => ({
    type: QuestionsSearchType.QuestionsLoadingFailed,
    errorMessage: errorMessage
});

export const setQuestions = (questions: IQuestion[]): QuestionsSearchActions => ({
    type: QuestionsSearchType.SetQuestions,
    questions: questions
});
