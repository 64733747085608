import React from 'react';
import {useSelector} from "react-redux";
import {AppState} from '../../redux/configureStore';
import RingTable from "./RingTable";

const Admin: React.FC = () => {
    const pub = useSelector((state: AppState) => state.user.pub);
    return pub?.leaderRings ? <RingTable rings={pub?.leaderRings}/> : <div></div>;
};

export default Admin;
