import React from "react";
import {Avatar, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useEmailSettings from "../../hooks/useEmailSettings";
import SelectionComponent from "../input/SelectionComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";
import Loading from "../LoadingComponent";
import Error from "../ErrorComponent";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        margin: "auto",
        backgroundColor: "#96D3E2"
    }
}));

const EmailSettings: React.FC = () => {
    const getLabel = useTranslationLabel();
    const [settings, saveSubscriptions, isLoading, errorMessage] = useEmailSettings();
    const classes = useStyles();

    if (isLoading) {
        return <Loading text={getLabel('ui.admin.loading_email settings')}/>
    } else if (errorMessage) {
        return <Error text={errorMessage.replace('Object',getLabel('ui.question')??'Question')}/>
    } else if (settings.size > 0) {
        let values: string[] = [];
        for (let [key, value] of settings) {
            if (value) {
                values.push(key);
            }
        }

        return <div className={classes.root}>
            <Avatar alt="email image" src="/images/profile/email-avatar.svg" className={classes.avatar} />
            <Typography variant="body1" gutterBottom align={"center"}>
                {getLabel('ui.profile.email_explanation')}
            </Typography>
            <SelectionComponent
                initialValue={values}
                rdn={'user.mailerflags'}
                isExpanded={true}
                onChange={
                    (value: string | string[] | undefined) => {
                        saveSubscriptions(value as string[]);
                    }
                }/>
        </div>;
    } else {
        return <Error/>;
    }
};

export default EmailSettings;
