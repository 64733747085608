import React, {useEffect, useState} from "react";
import {Link as RouterLink, match} from "react-router-dom";
import Loading from "../LoadingComponent";
import Error from "../ErrorComponent";
import useFetchQuestion from "../../hooks/useFetchQuestion";
import {PickerState} from "../StandardPickerComponent";
import {IQuestion} from "../../shared/soleTypes";
import {Button, createStyles, Grid, IconButton, makeStyles, Snackbar, Theme, Typography, Container} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {toggleFavoriteQuestion} from "../../redux/QuestionCreators";
import {AppState} from "../../redux/configureStore";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import CloseIcon from '@material-ui/icons/Close';
import {useTranslationLabel} from "../../hooks/useTranslation";
import QuestionResources from "./QuestionResourcesComponent";

interface Identifiable {
    id: string
}

interface QuestionProps {
    match?: match<Identifiable>
    location: {
        state: {
            question: IQuestion,
            pickerState: PickerState
        }
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingBottom: theme.spacing(2),
            minWidth: 180,
            textAlign: 'center',
            color: theme.palette.text.secondary,
            whiteSpace: 'nowrap',
        },
        card: {
            maxWidth: '700px',
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingBottom: theme.spacing(2)
        },
        alignText: {
            textAlign: 'center',
            marginTop: theme.spacing(3)
        },
        resourceContainer: {
            paddingTop: theme.spacing(4)
        }
    }),
);

const SingleQuestion: React.FC<QuestionProps> = (props) => {
    const [question, isLoading, errorMessage, fetchQuestion] = useFetchQuestion();
    const {match, location} = props;
    const [myQuestion, setMyQuestion] = useState<IQuestion | undefined>(undefined);
    const myFavoriteQuestions = useSelector((state: AppState) => state.myQuestions.myFavoriteQuestions);
    const dispatch = useDispatch();
    const [isFavorite, setIsFavorite] = useState(false);
    const classes = useStyles();
    const getLabel = useTranslationLabel();

    const [open, setOpen] = React.useState(false);
    const [snackBarMessage, setSnackbarMessage] = useState<string>('');
    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (question) {
            setMyQuestion(question);
        }
    }, [question]);

    useEffect(() => {
        if (myQuestion?.id) {
            setIsFavorite(
                myFavoriteQuestions.find(question => question.id === myQuestion?.id) !== undefined
            );
        }
    }, [myFavoriteQuestions, myQuestion]);

    useEffect(() => {
        const questionId = match?.params.id || '';
        if (questionId === location?.state?.question?.id) {
            setMyQuestion(location?.state?.question);
        } else {
            fetchQuestion(questionId);
        }
    }, [match,location]);

    const onToggleFavorite = async () => {
        if (myQuestion?.id) {
            const message = !isFavorite ? getLabel('ui.question.added_to_favorites') : getLabel('ui.question.removed_from_favorites');
            dispatch(toggleFavoriteQuestion(myQuestion.id));
            setSnackbarMessage(message as string);
            setOpen(true);
        }
    };

    if (isLoading) {
        return <Loading text={getLabel('ui.admin.loading_question')}/>
    } else if (errorMessage) {
        return <Error text={errorMessage.replace('Object',getLabel('ui.question')??'Question')}/>
    } else {
        return <Grid container justify={'center'}>
            {/*<Card className={classes.card}>*/}
            {/*<CardHeader title={myQuestion?.text} titleTypographyProps={{variant:"h4",  align:'center', gutterBottom:true}}>*/}
            <Typography variant="h4" align={'center'} gutterBottom={true}>
                {myQuestion?.text}
            </Typography>
            {/*</CardHeader>*/}
            {/*<CardContent>*/}
            <Grid container spacing={6} justify={'center'}>
                <Grid item className={classes.alignText}>
                    <Button
                        variant={'contained'}
                        size={'large'}
                        startIcon={<AssignmentIcon/>}
                        component={RouterLink}
                        color={'primary'}
                        to={{
                            pathname: "/soles/plan",
                            state: {
                                question: myQuestion,
                                pickerState: location?.state?.pickerState
                            }
                        }}>
                        {getLabel('ui.question.plan_a_lesson')}
                    </Button>
                </Grid>
                <Grid item className={classes.alignText}>
                    <Button
                        variant={'contained'}
                        size={'large'}
                        startIcon={!isFavorite ? <BookmarkBorderIcon/> : <BookmarkIcon/>}
                        color={'primary'}
                        onClick={onToggleFavorite}
                    >
                        {!isFavorite ? getLabel('ui.favorite') : getLabel('ui.unfavorite')}
                    </Button>
                </Grid>
                <Grid item className={classes.alignText}>
                    <Button
                        variant={'contained'}
                        size={'large'}
                        color={'secondary'}
                        startIcon={<VideoCallIcon/>}
                        component={RouterLink}
                        to={{
                            pathname: '/soles/virtual',
                            search: '?question=' + myQuestion?.id
                        }}
                    >
                        {getLabel('ui.question.virtual_sole')}
                    </Button>
                </Grid>

            </Grid>
            {/*</CardContent>*/}
            {/*</Card>*/}

            <Container maxWidth={'md'} className={classes.resourceContainer}>
                {<QuestionResources question={myQuestion}/>}
            </Container>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        {/*<Button color="secondary" size="small" onClick={handleClose}>*/}
                        {/*    {getLabel('ui.undo')}*/}
                        {/*</Button>*/}
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </Grid>;
    }
};

export default SingleQuestion;
