import Parse from "parse";
import {ISchool, IUserPub} from "../shared/soleTypes";

export enum UserActionType {
    Login = "Login",
    LoginWithToken = "LoginWithToken",
    LoginPending = "LoginPending",
    LoginFailed = "LoginFailed",
    SetCurrentUser = "SetCurrentUser",
    ResetUser = "ResetUser",
    SaveUser = "SaveUser",
    SignUp = "SignUp",
    SetSchool = "SetSchool",
    SaveSchool = "SaveSchool",
    CompleteOnboarding = "CompleteOnboarding"
}

export interface LoginAction {
    type: typeof UserActionType.Login,
    email: string,
    password: string
}

export interface LoginWithTokenAction {
    type: typeof UserActionType.LoginWithToken,
    sessionToken: string,
    email: string
}

interface LoginPendingAction {
    type: typeof UserActionType.LoginPending
}

interface LoginFailedAction {
    type: typeof UserActionType.LoginFailed,
    errorMessage: string
}

export interface SetCurrentUserAction {
    type: typeof UserActionType.SetCurrentUser,
    user: Parse.User,
    pub: IUserPub
}

export interface SetSchoolAction {
    type: typeof UserActionType.SetSchool,
    school: ISchool
}

export interface SaveSchoolAction {
    type: typeof UserActionType.SaveSchool,
    placeId: string
}

interface ResetUserAction {
    type: typeof UserActionType.ResetUser
}

export interface SaveUserAction {
    type: typeof UserActionType.SaveUser,
    pub: IUserPub
}

export interface SignUpAction {
    type: typeof UserActionType.SignUp,
    email: string,
    password: string
}

export interface CompleteOnboardingAction {
    type: typeof UserActionType.CompleteOnboarding,
    pub: IUserPub
}

export type UserActions =
    LoginAction |
    LoginWithTokenAction |
    LoginPendingAction |
    LoginFailedAction |
    SetCurrentUserAction |
    ResetUserAction |
    SaveUserAction |
    SignUpAction |
    SetSchoolAction |
    SaveSchoolAction |
    CompleteOnboardingAction;
