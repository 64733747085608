import React, {useEffect, useState} from "react";
import useFetchQuestion from "../../hooks/useFetchQuestion";
import Parse from "parse";
import {Redirect} from "react-router-dom";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import Loading from "../LoadingComponent";
import {useDispatch, useSelector} from "react-redux";
import {setSoles} from "../../redux/SolesCreators";
import {AppState} from "../../redux/configureStore";
import {useTranslationLabel} from "../../hooks/useTranslation";
import Error from "../ErrorComponent";

interface VirtualQuestionProps {
    questionId: string
}

const VirtualQuestion: React.FC<VirtualQuestionProps> = props => {
    const dispatch = useDispatch();
    const getLabel = useTranslationLabel();
    const [question, isLoading, errorMessage, fetchQuestion] = useFetchQuestion();
    const [quickstartSoleId, setQuickstartSoleId] = useState<string | undefined>(undefined);
    const soles = useSelector((state: AppState) => state.soles.soles);

    useEffect(() => {
        fetchQuestion(props.questionId);
    }, [props.questionId]);

    const createQuickstartSole = async () => {
        try {
            const soleId = await Parse.Cloud.run('webapp.quickstart', {
                question: question?.id
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });

            const sole = await Parse.Cloud.run('webapp.getSoleJsonById', {
                id: soleId
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });

            const newSoles = [...soles];
            newSoles.unshift(sole);
            dispatch(setSoles(newSoles));
            setQuickstartSoleId(soleId);

//            dispatch(loadSoles());
        } catch (error) {
        }
    };

    if (isLoading) {
        return <Loading text={getLabel('ui.admin.loading_question')}/>
    } else if (errorMessage) {
        return <Error text={errorMessage.replace('Object',getLabel('ui.question')??'Question')}/>
    } else if (question) {
        if (quickstartSoleId) {
            return <Redirect to={{
                pathname: '/soles/virtual',
                search: '?sole=' + quickstartSoleId
            }}/>;
        } else {
            return <Grid container justify={'center'}>
                <Grid item sm={12} md={8} lg={6}>
                    <Typography variant="h4" align={'center'} gutterBottom>
                        {question.text}
                    </Typography>
                    <Typography variant="body1">
                        {getLabel('ui.virtual.button_instructions')}
                    </Typography>
                    <Box display="flex" mt={5} mb={5}>
                        <Box m={'auto'}>
                            <Button variant="contained" color="secondary" onClick={() => createQuickstartSole()}>
                                {getLabel('ui.virtual.button_setup')}
                            </Button>
                        </Box>
                    </Box>
                    <Typography variant="body1">
                        {getLabel('ui.virtual.explanation_details')}
                    </Typography>
                </Grid>
            </Grid>
        }
    } else {
        return <Error/>
    }
};

export default VirtualQuestion;
