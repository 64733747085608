import {put, select, delay} from "redux-saga/effects";
import {loadSoles} from "../redux/SolesCreators";
import Parse from "parse";
import {
    CompleteOnboardingAction,
    LoginAction,
    LoginWithTokenAction,
    SaveSchoolAction,
    SaveUserAction,
    SetCurrentUserAction,
    SetSchoolAction,
    SignUpAction
} from "../redux/UserTypes";
import {loginFailed, loginPending, resetUser, saveUser, setCurrentUser, setSchool} from "../redux/UserCreators";
import {ISchool, IUserPub} from "../shared/soleTypes";
import {AppState} from "../redux/configureStore";
import {SolesState} from "../redux/SolesReducer";
import {DataPointsState} from "../redux/DataPointReducer";
import {loadDataPoints} from "../redux/DataPointCreators";

const loadUserPub = async (): Promise<IUserPub | undefined> => {
    try {
        return await Parse.Cloud.run('userpub.getJson', {}, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
    } catch (error) {
        return undefined;
    }
};

const loadSchool = async (id: string): Promise<ISchool | undefined> => {
    try {
        return await Parse.Cloud.run('school.getByIdJson', {id: id}, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
    } catch (error) {
        return undefined;
    }
};

export function* loadSchoolFromLocalStorage() {
    const schoolJson = localStorage.getItem('school') as string;
    if (schoolJson) {
        const school = JSON.parse(schoolJson) as ISchool;
        yield put(setSchool(school));
    }
}

export function* loadCurrentUserFromLocalStorage() {
    const user = Parse.User.current();
    if (user) {
        const pubJson = localStorage.getItem('pub') as string;
        if (pubJson) {
            try {
                const pub = JSON.parse(pubJson);
                yield put(setCurrentUser(user, pub));
            } catch (error) {
            }
        }

        const pub = yield loadUserPub();
        if (pub) {
            yield put(setCurrentUser(user, pub))
        } else {
            //TODO: maybe include some contextual message here so users can see what's up
            yield put(resetUser());
        }
    }
}

export function* setSchoolSaga(action: SetSchoolAction) {
    localStorage.setItem('school', JSON.stringify(action.school));
}

export function* setCurrentUserSaga(action: SetCurrentUserAction) {
    localStorage.setItem('pub', JSON.stringify(action.pub));

    const dpState: DataPointsState = yield select((state: AppState) => state.dataPoints);
    if (dpState.dataPoints.length === 0 && !dpState.isLoading) {
        yield put(loadDataPoints());
    }

    const solesState: SolesState = yield select((state: AppState) => state.soles);
    if (solesState.soles.length === 0 && !solesState.isLoading) {
        yield put(loadSoles());
    }
}

export function* signUpSaga(action: SignUpAction) {
    try {
        const user = new Parse.User();
        user.setUsername(action.email);
        user.setEmail(action.email);
        user.setPassword(action.password);
        user.set('accountType', 'parse');

        yield user.signUp();
        const pub = yield loadUserPub();
        //forcing email into pub
        pub.email = action.email;
        yield Parse.Cloud.run('userpub.setProfileValues', {
            name: '',
            firstName: '',
            lastName: '',
            email: action.email,
            imageUrl: ''
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(setCurrentUser(user, pub));
    } catch (error) {
        yield put(loginFailed(error.message));
    }
}

export function* saveUserSaga(action: SaveUserAction) {
    let current = Parse.User.current();
    if (current) {
        try {
            const sessionToken = current.getSessionToken();
            const pub = yield Parse.Cloud.run('userpub.save', {
                pubJson: action.pub
            }, {
                sessionToken: sessionToken
            });

            current = yield current.fetch({
                sessionToken: sessionToken
            });

            if (current) {
                yield put(setCurrentUser(current, pub));
            }
        } catch (error) {
            console.log('error: ' + JSON.stringify(error));
        }
    }
}

export function* saveSchoolSaga(action: SaveSchoolAction) {
    let pub = yield select((state: AppState) => state.user.pub);
    let current = Parse.User.current();
    if (current && pub) {
        try {
            const schoolParse = yield Parse.Cloud.run("school.getByPlaceId", {
                placeId: action.placeId
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
            const school = yield loadSchool(schoolParse.id);
            if (school) {
                pub = yield select((state: AppState) => state.user.pub);
                pub.schoolId = school.id;
                yield put(saveUser(pub));
                yield put(setSchool(school));
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function* resetUserSaga() {
    yield Parse.User.logOut();

    // Clear local storage
    localStorage.removeItem('pub');
    localStorage.removeItem('soles');
    localStorage.removeItem('school');
}

export function* loginWithTokenSaga(action: LoginWithTokenAction) {
    yield put(loginPending());
    try {
        const user = yield Parse.User.become(action.sessionToken);
        const pub = yield loadUserPub();
        //if Google user doesn't have the email DPV written set, save email.
        if (!pub.email) {
            yield Parse.Cloud.run('userpub.setProfileValues', {
                name: '',
                firstName: '',
                lastName: '',
                email: action.email,
                imageUrl: ''
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
        }
        yield put(setCurrentUser(user, pub));

        if (pub) {
            const school = yield loadSchool(pub.schoolId);
            yield put(setSchool(school));
        }

    } catch (error) {
        yield put(loginFailed(error.message));
    }
}

export function* loginSaga(action: LoginAction) {
    yield put(loginPending());
    try {
        const user = yield Parse.User.logIn(action.email, action.password);
        console.log('user logged in: ' + user.getUsername());
        const pub = yield loadUserPub();
        yield put(setCurrentUser(user, pub));

        if (pub) {
            const school = yield loadSchool(pub.schoolId);
            yield put(setSchool(school));
        }

    } catch (error) {
        yield put(loginFailed(error.message));
    }
}

export function* completeOnboardingSaga(action: CompleteOnboardingAction) {
    //this is where we should fire the onboarding.done event for Parse I think
    try {
        yield Parse.Cloud.run('userpub.setProfileValues', {
            name: action.pub.firstName,
            firstName: action.pub.firstName,
            lastName: action.pub.lastName,
            email: action.pub.email,
            imageUrl: ''
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        Parse.Cloud.run('event.trigger', {
            rdn: 'onboarding.done',
            isActivity: false,
            objectId: action.pub.id,
            className: 'UserPub'
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
    } catch (error) {
        return undefined;
    }
}
