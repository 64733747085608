import React from "react";
import Grid from "@material-ui/core/Grid";
import {Zoom} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import {IQuestion} from "../../shared/soleTypes";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {PickerState} from "../StandardPickerComponent";
import QuestionCard from "./QuestionCardComponent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
            breakInside: 'avoid',
            pageBreakInside: 'avoid',
            webkitColumnBreakInside: 'avoid',
            marginBottom: '12px'
        },
        media: {
            height: '100px'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        cols: {
            webkitColumns: '250px',
            columns: '250px',
            webkitColumnGap: '2em',
            columnGap: '2em'
        },
        questionCard: {
            marginBottom: '12px',
            breakInside: 'avoid',
            pageBreakInside: 'avoid',
            webkitColumnBreakInside: 'avoid'
        }
    })
);

interface QuestionsProps {
    questions: IQuestion[],
    pickerState?: PickerState
}

const QuestionCards: React.FC<QuestionsProps> =
    ({
         questions,
         pickerState
     }) => {
        const classes = useStyles();
        const items = questions.map((question, index) => {
            return <QuestionCard key={'question-' + index} question={question} pickerState={pickerState}/>;
        });

        if (items.length === 0) {
            const link = "/questions/new";

            return <Grid key={'newquestion'} item xs={12} sm={6} md={3}>
                <Zoom in={true} style={{transitionDelay: '0ms'}}>
                    <Link component={RouterLink} to={{
                        pathname: link,
                        state: {
                            pickerState: pickerState
                        }
                    }}>
                        <Paper elevation={2} className={classes.paper}>{"Nothing here...yet\n" +
                        "\n" +
                        "Share your own questions with the SOLE community."}</Paper>
                    </Link>
                </Zoom>
            </Grid>;
        }

        return <div>
            <div id="container" className={classes.cols}>
                {items}
            </div>
        </div>;
    };

export default QuestionCards;
