import React from "react";
import SelectionComponent from "../input/SelectionComponent";
import {SoleProps} from "./SoleProps";
import {useTranslationShort} from "../../hooks/useTranslation";

interface SoleSelectionProps extends SoleProps {
    isExpanded?: boolean
    isRow?: boolean
}

const SoleSelectionComponent: React.FC<SoleSelectionProps> = (props: SoleSelectionProps) => {
    const getShort = useTranslationShort();
    const rdn = props.rdn;
    const jsonKey = props.jsonKey;
    const sole = props.sole;
    const label = props.label ?? getShort(rdn) ?? '';
    // @ts-ignore
    const initialValue = sole && sole[jsonKey] ? sole[jsonKey] : undefined;

    return <SelectionComponent
        initialValue={initialValue}
        label={label}
        isExpanded={props.isExpanded}
        isRow={props.isRow}
        rdn={rdn}
        onChange={
            (value: string | string[] | undefined) => {
                props.setSole({
                    ...props.sole,
                    [jsonKey]: value
                });
            }
        }
    />
};

export default SoleSelectionComponent;
