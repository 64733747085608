import React from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import {useTranslationLabel} from "../../hooks/useTranslation";

const VirtualWelcome: React.FC = () => {
    const getLabel = useTranslationLabel();

    return <Grid container justify={'center'}>
        <Grid item sm={12} md={8} lg={6}>
            <Typography variant="h4" align={'center'} gutterBottom>
                {getLabel('ui.virtual.title')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {getLabel('ui.virtual.explanation_details')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {getLabel('ui.virtual.need_a_question')}
            </Typography>
            <Grid container spacing={2} justify={'center'}>
                <Grid item>
                    <Button variant="contained" component={RouterLink} to="/questions">
                        {getLabel('ui.explore_questions')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" component={RouterLink} to="/questions/mine">
                        {getLabel('ui.my_questions')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" component={RouterLink} to="/questions/new">
                        {getLabel('ui.add_a_question')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
};

export default VirtualWelcome;
