import React from "react";
import {SoleProps} from "./SoleProps";
import NumberComponent from "../input/NumberComponent";
import {useTranslationShort} from "../../hooks/useTranslation";

interface SoleNumberProps extends SoleProps {
    isRating?: boolean
}

const SoleNumberComponent: React.FC<SoleNumberProps> = (props: SoleNumberProps) => {
    const getShort = useTranslationShort();
    const rdn = props.rdn;
    const jsonKey = props.jsonKey;
    const sole = props.sole;
    const label = props.label ?? getShort(rdn) ?? '';
    // @ts-ignore
    const initialValue = sole && sole[jsonKey] ? Number(sole[jsonKey]) : undefined;

    return <NumberComponent
        isRating={props.isRating}
        initialValue={initialValue}
        label={label}
        rdn={rdn}
        onChange={
            (value: number | undefined) => {
                props.setSole({
                    ...props.sole,
                    [jsonKey]: value
                });
            }
        }
    />
};

export default SoleNumberComponent;
