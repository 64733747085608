import {all, fork, takeEvery} from 'redux-saga/effects';
import {MyQuestionsActionType, QuestionActionType, QuestionsSearchType} from "../redux/QuestionTypes";
import {UserActionType} from "../redux/UserTypes";
import {SolesActionType} from "../redux/SolesTypes";
import {DataPointActionType} from "../redux/DataPointTypes";
import {TextActionType} from "../redux/TextTypes";
import {ReportActionType} from "../redux/ReportTypes";

import {addQuestionSaga, loadMyQuestionsSaga, searchQuestionsSaga, toggleFavoriteQuestionSaga} from "./sagaQuestions";
import {
    completeOnboardingSaga,
    loadCurrentUserFromLocalStorage,
    loadSchoolFromLocalStorage,
    loginSaga,
    loginWithTokenSaga,
    resetUserSaga,
    saveSchoolSaga,
    saveUserSaga,
    setCurrentUserSaga,
    setSchoolSaga,
    signUpSaga
} from "./sagaUser";
import {addSoleSaga, deleteSoleSaga, loadSolesSaga, setSolesSaga} from "./sagaSoles";
import {loadDataPointsSaga, setDataPointsSaga} from "./sagaDataPoints";
import {loadTextSaga, textLoadingFinishedSaga} from "./sagaText";
import {ConstraintActionType} from "../redux/ConstraintTypes";
import {setConstraintsSaga} from "./sagaConstraints";
import {loadReportSaga} from "./sagaReports";

function* actionWatcher() {
    yield takeEvery(UserActionType.SetCurrentUser, setCurrentUserSaga);
    yield takeEvery(UserActionType.ResetUser, resetUserSaga);
    yield takeEvery(UserActionType.SaveUser, saveUserSaga);
    yield takeEvery(UserActionType.Login, loginSaga);
    yield takeEvery(UserActionType.LoginWithToken, loginWithTokenSaga);
    yield takeEvery(UserActionType.SignUp, signUpSaga);
    yield takeEvery(UserActionType.SetSchool, setSchoolSaga);
    yield takeEvery(UserActionType.SaveSchool, saveSchoolSaga);
    yield takeEvery(UserActionType.CompleteOnboarding, completeOnboardingSaga);

    yield takeEvery(MyQuestionsActionType.LoadMyQuestions, loadMyQuestionsSaga);
    yield takeEvery(MyQuestionsActionType.ToggleFavoriteQuestion, toggleFavoriteQuestionSaga);
    yield takeEvery(QuestionActionType.QuestionAdd, addQuestionSaga);
    yield takeEvery(QuestionsSearchType.QuestionsSearch, searchQuestionsSaga);

    yield takeEvery(SolesActionType.SolesLoad, loadSolesSaga);
    yield takeEvery(SolesActionType.SetSoles, setSolesSaga);
    yield takeEvery(SolesActionType.DeleteSole, deleteSoleSaga);
    yield takeEvery(SolesActionType.AddSole, addSoleSaga);

    yield takeEvery(DataPointActionType.DataPointLoad, loadDataPointsSaga);
    yield takeEvery(DataPointActionType.SetDataPoints, setDataPointsSaga);

    yield takeEvery(TextActionType.TextLoad, loadTextSaga);
    yield takeEvery(TextActionType.TextLoadingFinished, textLoadingFinishedSaga);

    yield takeEvery(ConstraintActionType.SetConstraints, setConstraintsSaga);

    yield takeEvery(ReportActionType.LoadReport, loadReportSaga);
}

function* initialSaga() {

    yield loadCurrentUserFromLocalStorage();
    yield loadSchoolFromLocalStorage();

    yield loadTextSaga();
}

export default function* rootSaga() {

    yield fork(initialSaga);
    yield all([
        actionWatcher()
    ]);
}
