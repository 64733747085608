import {
    MyQuestionsActions,
    QuestionAddAction,
    QuestionsSearchAction,
    ToggleFavoriteQuestionAction
} from "../redux/QuestionTypes";
import Parse from "parse";
import {put} from "redux-saga/effects";
import {
    loadMyQuestions,
    loadMyQuestionsFailed,
    loadMyQuestionsFinished,
    questionAdded,
    questionAddFailed,
    questionsLoading,
    questionsLoadingFailed,
    setMyFavoriteQuestions,
    setMyQuestions,
    setQuestions
} from "../redux/QuestionCreators";
import {IQuestion} from "../shared/soleTypes";

export function* loadMyQuestionsSaga(action: MyQuestionsActions) {
    try {
        let questions = yield Parse.Cloud.run('webapp.getMyQuestions2', {
            offset: 0,
            limit: 500
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(setMyQuestions(questions));

        questions = yield Parse.Cloud.run('webapp.getMyFavoriteQuestions2', {
            offset: 0,
            limit: 500
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(setMyFavoriteQuestions(questions));
        yield put(loadMyQuestionsFinished());
    } catch (error) {
        yield put(loadMyQuestionsFailed(error.message));
    }
}

export function* toggleFavoriteQuestionSaga(action: ToggleFavoriteQuestionAction) {
    // Todo: manually toggle the value in the redux state before executing the cloud call and undo
    // the action if an error occurs
    try {
        yield Parse.Cloud.run('webapp.toggleFavoriteQuestion', {
            id: action.id
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(loadMyQuestions());
    } catch (error) {

    }
}

export function* addQuestionSaga(action: QuestionAddAction) {
    try {
        const questionId = yield Parse.Cloud.run('webapp.addQuestion', {
            text: action.questionText,
            tags: [],
            source: 'https://startsole.org'
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });

        console.log(questionId);
        yield put(questionAdded(questionId));
    } catch (error) {
        console.log(error);
        yield put(questionAddFailed(error.message));
    }
}

export function* searchQuestionsSaga(action: QuestionsSearchAction) {
    yield put(questionsLoading());

    try {
        const result = yield Parse.Cloud.run('webapp.findQuestionByTagsAndTextForLanguages', {
            tags: action.tags,
            text: action.keywords,
            languages: ['en']
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });

        const questions: IQuestion[] = result.questions;
        yield put(setQuestions(questions))
    } catch (error) {
        console.log(error);
        yield put(questionsLoadingFailed(error.message));
    }
}
