import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {IObservation} from "../../shared/soleTypes";
import {Dialog} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 345,
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        noPadding: {
            padding: 0,
        },
        expand: {
            backgroundColor: 'white',
            opacity: 0.5,
            "&:hover": {
                backgroundColor: 'white',
                opacity: 1
            },
            margin: '15px 0px 0px -48px',
            position: 'absolute',
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        }
    }),
);

interface ObservationProps {
    observation?: IObservation
//    img?: string,
}

interface ImageDialogProps {
    open: boolean;
    selectedImage: string;
    onClose: (value: string) => void;
}

const ObservationCard: React.FC<ObservationProps> = (props:ObservationProps) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const observation = props.observation;
    const [open, setOpen] = React.useState(false);
    // const [selectedImage, setSelectedImage] = observation?.img;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return <div>
    <Card className={classes.root}>
        <CardHeader
            className={classes.noPadding}
            action={
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    size={"small"}
                >
                    <ExpandMoreIcon />
                </IconButton>
            }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
                <Typography paragraph>Notes to students:</Typography>

                <Typography paragraph>Private Notes:</Typography>
            </CardContent>
            <CardActions disableSpacing>
                {/*<IconButton aria-label="add to favorites">*/}
                {/*    <FavoriteIcon />*/}
                {/*</IconButton>*/}
                {/*<IconButton aria-label="share">*/}
                {/*    <ShareIcon />*/}
                {/*</IconButton>*/}
            </CardActions>
        </Collapse>
        <Collapse in={!expanded} timeout="auto" unmountOnExit>
            <CardMedia
                className={classes.media}
                image={observation?.thumb}
                onClick={handleOpen}
            />
        </Collapse>

    </Card>
        <Dialog onClose={handleClose} open={open}>
            <img src={observation?.img} alt={observation?.notes ?? ''}></img>
        </Dialog>
    </div>;
};

export default ObservationCard;
