import {ISole} from "../shared/soleTypes";
import {useEffect, useState} from "react";
import Parse from "parse";

const useFetchSole = (soleId?: string): [ISole | undefined, boolean, string, (soleId: string) => void] => {
    const [sole, setSole] = useState<ISole | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (soleId) {
            fetchSole(soleId);
        }
    }, [soleId]);

    const fetchSole = async (soleId: string) => {
        setIsLoading(true);
        try {
            const sole = await Parse.Cloud.run('webapp.getSoleById', {
                id: soleId
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
            setIsLoading(false);
            // @ts-ignore
            setSole(sole);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(JSON.stringify(error.message));
        }
    };

    return [sole, isLoading, errorMessage, fetchSole];
};

export default useFetchSole;
