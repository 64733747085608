import {IQuestion} from "../../shared/soleTypes";
import {PickerState} from "../StandardPickerComponent";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Card, CardActionArea, CardContent, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.secondary.main,
            breakInside: 'avoid',
            pageBreakInside: 'avoid',
            webkitColumnBreakInside: 'avoid',
            marginBottom: '12px'
        },
        white: {
            color: 'white'
        }
    })
);

interface QuestionCardProps {
    question: IQuestion,
    pickerState?: PickerState
}

const QuestionCard: React.FC<QuestionCardProps> = (props: QuestionCardProps) => {
    const classes = useStyles();

    const question = props.question;
    const link = "/questions/" + question.id;
    return <Card variant={"outlined"} className={classes.root}>
        <CardActionArea  component={RouterLink} to={{
            pathname: link,
            state: {
                question: question,
                pickerState: props.pickerState
            }
        }}>
        <CardContent>
            <Typography variant={"h6"} align={'center'} className={classes.white}>
            {question.text}
            </Typography>
        </CardContent>
        </CardActionArea>
    </Card>;
};

export default QuestionCard;
