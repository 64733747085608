import {SoleText} from "../data/SoleText";
import {TextActions, TextActionType} from "./TextTypes";

export interface TextState {
    isLoading: boolean,
    errorMessage?: string,
    texts: SoleText[]
}

const initialState: TextState = {
    isLoading: false,
    errorMessage: undefined,
    texts: []
};

export const TextReducer = (state = initialState, action: TextActions): TextState => {
    switch (action.type) {
        case TextActionType.TextLoading:
            return {
                isLoading: true,
                errorMessage: undefined,
                texts: []
            };
        case TextActionType.TextLoadingFailed:
            return {
                isLoading: false,
                errorMessage: action.errorMessage,
                texts: []
            };
        case TextActionType.SetTexts:
            return {
                isLoading: false,
                errorMessage: undefined,
                texts: action.texts
            };
        default:
            return state;
    }
};
