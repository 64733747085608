import {Tag} from "../data/Tag";
import {IUserPub} from "./soleTypes";

export const getAllSubjects = (pub: IUserPub): Tag[] => {
    let subjects: Tag[] = [];
    if (pub.rings?.find(ring => ring.rdn === 'us.pa')) {
        subjects = allSubjectsPa;
    } else {
        subjects = allSubjects;
    }

    const result = [...subjects];
    result.unshift({ short: 'All Subjects', rdn: 'all' });
    // getLabel('ui.questions.all_subjects');
    // allRdn

    return result;
};

const allSubjects: Tag[] = [
// { short: "AmeriCorps", rdn: "asn.d0000003" },
    { short: "College Now", rdn: "asn.d0000001" },
// { short: "Community Health Worker Certification", rdn:"asn.d0000002" },
    { short: "Dance", rdn: "asn.d2466234" },
    { short: "Drama/Theatre", rdn: "asn.d2466454" },
    { short: "English Language Arts (Common Core)", rdn: "asn.d10003fc" },
    { short: "Engineering Content Standards", rdn: "asn.d0000006" },
    { short: "Education and Training Content Standards", rdn: "asn.d0000007" },
    { short: "HRSA (ACT test)", rdn: "asn.d0000004" },
    { short: "Mathematics (Common Core)", rdn: "asn.d10003fb" },
    { short: "Music", rdn: "asn.d2482974" },
    { short: "Next Generation Science Standards", rdn: "asn.d2454348" },
    { short: "Physical Education", rdn: "asn.d2716034" },
    { short: "Science (High School)", rdn: "asn.d2692656" },
    { short: "Science (K-8)", rdn: "asn.d2462762" },
    { short: "Social Studies (High School)", rdn: "asn.d2463176" },
    { short: "Social Studies (K-8)", rdn: "asn.d2462866" },
    { short: "Technology", rdn: "asn.d2465259" },
    { short: "Visual Arts", rdn: "asn.d2483255" },
    { short: "World Languages", rdn: "asn.d2483559" }
];

const allSubjectsPa: Tag[] = [
    {short: "English Language Arts (Common Core)", rdn: "asn.d10003fc"},
// { short: "English Language Arts (PreK-5)", rdn: "asn.d2562530" },
// { short: "English Language Arts (6-12)", rdn: "asn.d2562969" },
    {short: "Mathematics (PreK-12)", rdn: "asn.d2563472"},
// { short: "Environment and Ecology (PreK-3)", rdn: "asn.d2581951" },
    {short: "Environment and Ecology", rdn: "asn.d2382283"},
    {short: "Science and Technology and Engineering Education (PreK-3)", rdn: "asn.d2381762"},
    {short: "Science and Technology and Engineering Education (Elementary)", rdn: "asn.d2382902"},
    {short: "Science and Technology and Engineering Education (Secondary)", rdn: "asn.d2381498"},
    {short: "Next Generation Science Standards", rdn: "asn.d2454348" },
    {short: "Arts and Humanities", rdn: "asn.d10002dc"},
    {short: "Civics and Government (PreK-3)", rdn: "asn.d2384125"},
    {short: "Civics and Government (Elementary)", rdn: "asn.d2384126"},
    {short: "Civics and Government (Secondary)", rdn: "asn.d2384127"},
    {short: "CSTA CS Standards", rdn: "asn.d0000005"},
    {short: "Economics (PreK-3)", rdn: "asn.d2384140"},
    {short: "Economics (Elementary)", rdn: "asn.d2384141"},
    {short: "Economics (Secondary)", rdn: "asn.d2384142"},
    {short: "Geography (PreK-3)", rdn: "asn.d2385459"},
    {short: "Geography (Elementary)", rdn: "asn.d2385476"},
// { short: "Geography (Secondary)", rdn: "asn.d2385492" },
    {short: "History (PreK-3)", rdn: "asn.d2385504"},
    {short: "History (Elementary)", rdn: "asn.d2385514"},
    {short: "History (Secondary)", rdn: "asn.d2385533"},
// { short: "Health, Safety & Physical Education", rdn: "asn.d2386177" },
// { short: "Career Education and Work", rdn: "asn.d2400024" },
// { short: "Family and Consumer Sciences", rdn: "asn.d2400029" },
// { short: "Reading for Science and Technical Subjects (6-12)", rdn: "asn.d2529702" },
// { short: "Reading for History and Social Studies (6-12)", rdn: "asn.d2529738" },
    {short: "Writing for History and Social Studies (6-12)", rdn: "asn.d2562464"}
// { short: "Writing for Science and Technical Subjects (6-12)", rdn: "asn.d2562497" },
// { short: "Business, Computer and Information Technology", rdn: "asn.d2659715" },
];
