import React, {useState} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import Login from "./LoginComponent";
import Home from "./HomeComponent";
import {AppState} from "../redux/configureStore";
import {useSelector} from "react-redux";
import HowToSole from "./how-to/HowToSoleComponent";
import Soles from "./sole/SolesComponent";
import Logout from "./LogoutComponent";
import QuestionAdd from "./question/QuestionAddComponent";
import SingleQuestion from "./question/SingleQuestionComponent";
import QuestionsSearch from "./question/QuestionsSearch";
import Plan from "./sole/PlanComponent";
import Reflect from "./sole/ReflectComponent";
import Register from "./RegisterComponent";
import CssBaseline from "@material-ui/core/CssBaseline";
import {UserPub} from "../shared/soleTypes";
import ProfileComplete from "./profile/ProfileCompleteComponent";
import Profile from "./profile/ProfileComponent";
import HistoryOfSole from './history/HistoryComponent';
import Virtual from "./virtual/VirtualComponent";
import Summary from "./sole/SummaryComponent";
import {makeStyles} from "@material-ui/core/styles";
import ResponsiveDrawer from "./ResponsiveDrawerComponent";
import Resources from "./ResourcesComponent";
import QuestionsMine from "./question/QuestionsMine";
import AdminRoute from "./AdminRoute";
import Admin from "./admin/Admin";
import Dashboard from "./admin/Dashboard";
import Copy from './sole/CopyComponent';
import SoleApproval from "./admin/SoleApproval";
import MaintenancePopup from "./Maintenance";
import CommunityHome from "./community/CommunityHome";
import LakeErieTrainings from './community/LakeErieTrainings';
import Training from "./community/trainings/Training";

const useStyles = makeStyles((theme: any) => ({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(6),
    }
}));

//TODO: LabXChange has a really easy and simple model for sharing a URL to Google Classroom.  We should do this with StartSOLE (at least with virtual join links to start).
const Main: React.FC = () => {
    const classes = useStyles();
    const pub = useSelector((state: AppState) => state.user.pub);
    const location = useLocation();

    if(location.pathname === '/logout') {
        return <Logout/>
    }
    else if (pub) {
        const pubObject = new UserPub(pub);
        if (!pubObject.isComplete) {
            return <ProfileComplete/>;
        } else {
            return <div className={classes.root}>
                <CssBaseline/>
                {/*<MiniDrawer/>*/}
                <ResponsiveDrawer/>
                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                    <MaintenancePopup/>
                    <Switch>
                        <Route path="/home" component={Home}/>
                        <Route path="/how" component={HowToSole}/>
                        <Route path="/history" component={HistoryOfSole}/>
                        <Route path="/profile" component={Profile}/>
                        {/*<Route path="/profilecomplete" component={ProfileComplete}/>*/}
                        <Route exact path="/soles" component={Soles}/>
                        <Route exact path="/soles/plan" component={Plan}/>
                        <Route exact path="/soles/virtual" component={Virtual}/>
                        <Route exact path="/soles/:id/plan" component={Plan}/>
                        <Route exact path="/soles/:id/copy" component={Copy}/>
                        <Route exact path="/soles/:id/reflect" component={Reflect}/>
                        <Route exact path="/soles/:id/virtual" component={Virtual}/>
                        <Route exact path="/soles/:id" component={Summary}/>
                        <Route path="/questions/new" component={QuestionAdd}/>
                        <Route exact path="/questions"><Redirect to="/questions/search" /></Route>
                        <Route path="/questions/mine" component={QuestionsMine}/>
                        <Route path="/questions/search" component={QuestionsSearch}/>
                        <Route path="/questions/:id" component={SingleQuestion}/>
                        <Route path="/resources" component={Resources}/>
                        <Route exact path="/community/:ringRdn" component={CommunityHome}/>
                        <Route path="/community/:ringRdn/training" component={LakeErieTrainings}/>
                        <Route path="/training/:id" component={Training}/>
                        <AdminRoute exact path="/admin" component={Admin}/>
                        <AdminRoute exact path="/admin/approval" component={SoleApproval}/>
                        <AdminRoute path="/rings/:id" component={Dashboard}/>
                        <Route path="/logout" component={Logout}/>
                        <Redirect to="/home"/>
                    </Switch>
                </main>
            </div>
        }
    } else {
        return <Switch>
            <Route path="/login" component={Login}/>
            <Route path="/register" component={Register}/>
            <Redirect to="/login"/>
        </Switch>
    }
};

export default Main;
