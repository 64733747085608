// Action creators

import {ReportActions, ReportActionType,} from "./ReportTypes";
import {IReport} from "../shared/soleTypes";

export const loadReport = (name:string, ring:string, parms:{}): ReportActions => ({
    type: ReportActionType.LoadReport,
    name: name,
    ring: ring,
    parms: parms
});

export const setReport = (report: IReport): ReportActions => ({
    type: ReportActionType.SetReport,
    report: report
});

export const loadReportFinished = (): ReportActions => ({
    type: ReportActionType.LoadReportFinished,
});

export const loadReportFailed = (errorMessage: string): ReportActions => ({
    type: ReportActionType.LoadReportFailed,
    errorMessage: errorMessage
});
