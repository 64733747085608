import {useSelector} from "react-redux";
import {AppState} from "../redux/configureStore";
import {SoleText} from "../data/SoleText";

function findText (state: AppState, rdn: string) : SoleText | undefined {
    const texts = state.text.texts.length>0 ? state.text.texts : JSON.parse(localStorage.getItem('texts') as string) as SoleText[];
    if (!texts) {
        return undefined;
    }

    const lngArray = window.navigator.language.split('-');
//    console.log('detected user language: ',lngArray);
    let userLanguage = lngArray[0]+ (lngArray[1] ? ("_"+lngArray[1].toUpperCase()) : '');
    //for now all Spanish versions use Colombian Spanish
    if(lngArray[0] === 'es'){userLanguage = 'es_CO'};

    let text = texts.find(text => text.rdn === rdn && text.language === userLanguage);

    if(!text && userLanguage !== 'en_US'){
        text = texts.find(text => text.rdn === rdn && text.language === "en_US");
    }

    return text;
};

export const useTranslationLabel = (): (rdn: string) => string | undefined => {
    const state = useSelector((state: AppState) => state);

    return (rdn: string): string | undefined => {
        return findText(state, rdn)?.label;
    }
};

export const useTranslationShort = (): (rdn: string) => string | undefined => {
    const state = useSelector((state: AppState) => state);

    return (rdn: string): string | undefined => {
        return findText(state, rdn)?.short;
    }
};

export const useTranslationLong = (): (rdn: string) => string | undefined => {
    const state = useSelector((state: AppState) => state);

    return (rdn: string): string | undefined => {
        return findText(state, rdn)?.long;
    }
};
