import {SolesActions, SolesActionType} from './SolesTypes';
import {ISole} from "../shared/soleTypes";

export const loadSoles = (): SolesActions => ({
    type: SolesActionType.SolesLoad
});

export const solesLoading = (): SolesActions => ({
    type: SolesActionType.SolesLoading
});

export const solesLoadingFailed = (errorMessage: string): SolesActions => ({
    type: SolesActionType.SolesLoadingFailed,
    errorMessage: errorMessage
});

export const setSoles = (soles: ISole[]): SolesActions => ({
    type: SolesActionType.SetSoles,
    soles: soles
});

export const deleteSole = (soleId: string): SolesActions => ({
    type: SolesActionType.DeleteSole,
    soleId: soleId
});

export const addSole = (soleId: string): SolesActions => ({
    type: SolesActionType.AddSole,
    soleId: soleId
});
