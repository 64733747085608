import React from "react";
import {Redirect, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../redux/configureStore";

interface AdminRouteProps {
    component: React.ReactNode;
}

//TODO: get rid of this ts-ignore and understand exactly why this works.
// @ts-ignore
const AdminRoute = ({component: Component, ...rest}) => {
    const pub = useSelector((state: AppState) => state.user.pub);
    const ringLeader = (pub?.leaderRings && pub.leaderRings.length>0);

    return (
        // Show the component only when the user is a ringleader or super_ringleader
        // Otherwise, redirect the user to /home page
        <Route {...rest} render={props => (
            ringLeader ?
                <Component {...props} />
                : <Redirect to="/home" />
        )} />
    );
};

export default AdminRoute;