import {SessionActions, SessionActionType, SessionContextItem} from "./SessionTypes";

export interface SessionState {
    context: SessionContextItem[]
}

const initialState: SessionState = {
    context: []
};

export const SessionReducer = (state: SessionState = initialState, action: SessionActions): SessionState => {
    switch (action.type) {
        case SessionActionType.SetContext:
            return {
                ...state,
                context: action.context
            };
        default:
            return state;
    }
};
