import {put, select} from "redux-saga/effects";
import {setTexts, textLoading, textLoadingFailed, textLoadingFinished} from "../redux/TextCreators";
import Parse from "parse";
import {SoleText} from "../data/SoleText";
import clientTexts from "../shared/clientTexts";
import {AppState} from "../redux/configureStore";
import {TextState} from "../redux/TextReducer";

export function* textLoadingFinishedSaga() {
    // const dpState: DataPointsState = yield select((state: AppState) => state.dataPoints);
    // if (dpState.dataPoints.length === 0 && !dpState.isLoading) {
    //     yield put(loadDataPoints());
    // }
}

export function* loadTextSaga() {
    const textState: TextState = yield select((state: AppState) => state.text);
    if (textState.texts.length === 0 && !textState.isLoading) {
        yield put(textLoading());

        let texts: SoleText[] = [];

        // Check if texts are stored in the local storage
        const textsJson = localStorage.getItem('texts') as string;
        if (textsJson) {
            try {
                texts = JSON.parse(textsJson);
                yield put(setTexts(texts));
            } catch (error) {
            }
        }

        // Get texts from backend if necessary
        try {
            const remoteUpdatedAt: Date | undefined = yield Parse.Cloud.run('cache.getLatestUpdatedAt',
                {
                    className: 'Text'
                }, { });

            let localUpdatedAt: Date | undefined = undefined;
            const localUpdatedAtStr = localStorage.getItem('updatedAtText') as string;
            if (localUpdatedAtStr) {
                localUpdatedAt = new Date(localUpdatedAtStr);
            }

            const needsDownload = remoteUpdatedAt === undefined
                || localUpdatedAt === undefined
                || localUpdatedAt < remoteUpdatedAt;

            if (needsDownload) {
                const parseTexts: Parse.Object[] = yield Parse.Cloud.run('text.load',
                    {
                        lng: 'en_US',
                        date: new Date(0)
                    });
                texts = parseTexts.map(text => SoleText.createFromParse(text));

                if (remoteUpdatedAt !== undefined) {
                    localStorage.setItem('updatedAtText', remoteUpdatedAt.toISOString());
                }
            }

            // Append client texts
            clientTexts.forEach(text => {
                const index = texts.findIndex(t => t.rdn === text.rdn && t.language === text.language);
                if (index >= 0) {
                    texts[index] = text;
                } else {
                    texts.push(text);
                }
            });

            localStorage.setItem('texts', JSON.stringify(texts));

            yield put(setTexts(texts));
            yield put(textLoadingFinished());
        } catch (error) {
            yield put(textLoadingFailed(error.message));
        }
    }
}
