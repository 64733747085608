import {SetConstraintsAction} from "../redux/ConstraintTypes";
import {getConstraintClassName} from "../data/constraints/Constraints";

export function* setConstraintsSaga(action: SetConstraintsAction) {
    const arr = Array.from(action.constraints.entries());
    for (let e of arr) {
        // @ts-ignore
        e[1]['tsclass'] = getConstraintClassName(e[1]);
    }
    const json = JSON.stringify(arr);
    localStorage.setItem('constraints', json);
}
