import {IUserPub} from "../shared/soleTypes";
import React from "react";
import {teal} from "@material-ui/core/colors";
import {Slide} from "material-auto-rotating-carousel";

export interface IRingProps {
    logoUrl: string,
    isVisibleHistoryOfStartSole: boolean,
    language: string,
    resourceTags?: IResourceTag[],
    customRingPage?: string,
    customSlides?: JSX.Element[]
}

export interface IResourceTag {
    key: string,
    text: string
}

const defaultRingProps: IRingProps = {
    logoUrl: "url('/images/logos/logo-fallback.png')",
    isVisibleHistoryOfStartSole: false,
    language: 'en_US'
};

const usPaRingProps: IRingProps = {
    ...defaultRingProps,
    resourceTags: [
        {key: 'pa-udl-removing-engagement-barriers',text:'Removing Engagement Barriers'},
        {key: 'pa-udl-removing-representation-barriers',text:'Removing Representation Barriers'},
        {key: 'pa-udl-removing-action-and-expression-barriers',text:'Removing Action and Expression Barriers'},
        {key: 'pa-udl-provide-options-for-recruiting-interest',text:'Provide options for recruiting interest'},
        {key: 'pa-udl-provide-options-for-sustaining-effort-and-persistence',text:'Provide options for sustaining effort and persistence'},
        {key: 'pa-udl-provide-options-for-self-regulation',text:'Provide options for self-regulation'},
        {key: 'pa-udl-provide-options-for-perception',text:'Provide options for perception'},
        {key: 'pa-udl-provide-options-for-language,-mathematic,-expressions,-and-symbols',text:'Provide options for language, mathematic, expressions, and symbols'},
        {key: 'pa-udl-provide-options-for-comprehension',text:'Provide options for comprehension'},
        {key: 'pa-udl-provide-options-for-physical-action',text:'Provide options for physical action'},
        {key: 'pa-udl-provide-options-for-expression-and-communication',text:'Provide options for expression and communication'},
        {key: 'pa-udl-provide-options-for-executive-functioning',text:'Provide options for executive functioning'}
    ]
};

const usOhRingProps: IRingProps = {
    ...defaultRingProps,
    resourceTags: [
        {key: 'pa-udl-removing-engagement-barriers',text:'Removing Engagement Barriers'},
        {key: 'pa-udl-removing-representation-barriers',text:'Removing Representation Barriers'},
        {key: 'pa-udl-removing-action-and-expression-barriers',text:'Removing Action and Expression Barriers'},
        {key: 'pa-udl-provide-options-for-recruiting-interest',text:'Provide options for recruiting interest'},
        {key: 'pa-udl-provide-options-for-sustaining-effort-and-persistence',text:'Provide options for sustaining effort and persistence'},
        {key: 'pa-udl-provide-options-for-self-regulation',text:'Provide options for self-regulation'},
        {key: 'pa-udl-provide-options-for-perception',text:'Provide options for perception'},
        {key: 'pa-udl-provide-options-for-language,-mathematic,-expressions,-and-symbols',text:'Provide options for language, mathematic, expressions, and symbols'},
        {key: 'pa-udl-provide-options-for-comprehension',text:'Provide options for comprehension'},
        {key: 'pa-udl-provide-options-for-physical-action',text:'Provide options for physical action'},
        {key: 'pa-udl-provide-options-for-expression-and-communication',text:'Provide options for expression and communication'},
        {key: 'pa-udl-provide-options-for-executive-functioning',text:'Provide options for executive functioning'}
    ]
};

const coRingProps: IRingProps = {
    ...defaultRingProps,
    logoUrl: "url('/images/logos/sole-colombia-logo.png')",
    language: 'es_CO'
};

const lakeErieWestRingProps: IRingProps = {
    ...usOhRingProps,
    // logoUrl: "url('/images/logos/lake-erie-west-logo.png')",
    customRingPage: 'us.oh.lakeeriewest',
    customSlides: [<Slide
        media={
            <img src="/images/carousel/custom-lake-erie-1.png" alt={''} />
        }
        mediaBackgroundStyle={{ backgroundColor: teal[50] }}
        style={{ backgroundColor: teal[600] }}
        title="Community Partners"
        subtitle="We've partnered with Lake Erie West, your local Educational Service Center to make earning CEUs even easier.  Check out your community page to learn more!"
        landscape
    />]
};

//TODO: make an Italy ring and use ringProps for language
const itRingProps: IRingProps = {
    ...defaultRingProps,
    language: 'it_IT'
};

export const getRingProps = (pub: IUserPub): IRingProps => {
    const rings = pub.rings;
    if (rings?.find(ring => ring.rdn === 'us.oh.lakeeriewest')) {
        return lakeErieWestRingProps;
    } else if (rings?.find(ring => ring.rdn === 'us.oh')) {
        return usOhRingProps;
    } else if (rings?.find(ring => ring.rdn === 'us.pa')) {
        return usPaRingProps;
    } else if (rings?.find(ring => ring.rdn === 'co')) {
        return coRingProps;
    } else  {
        return defaultRingProps;
    }
};
