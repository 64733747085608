// Action types

import {IQuestion} from "../shared/soleTypes";

export enum MyQuestionsActionType {
    LoadMyQuestions = "LoadMyQuestions",
    LoadMyQuestionsFinished = "LoadMyQuestionsFinished",
    SetMyQuestions = "SetMyQuestions",
    SetMyFavoriteQuestions = "SetMyFavoriteQuestions",
    LoadMyQuestionsFailed = "LoadMyQuestionsFailed",
    ToggleFavoriteQuestion = "ToggleFavoriteQuestion"
}

export interface LoadMyQuestionsAction {
    type: typeof MyQuestionsActionType.LoadMyQuestions
}

export interface LoadMyQuestionsFinishedAction {
    type: typeof MyQuestionsActionType.LoadMyQuestionsFinished
}

export interface SetMyQuestionsAction {
    type: typeof MyQuestionsActionType.SetMyQuestions,
    questions: IQuestion[]
}

export interface SetMyFavoriteQuestionsAction {
    type: typeof MyQuestionsActionType.SetMyFavoriteQuestions,
    questions: IQuestion[]
}

export interface LoadMyQuestionsFailedAction {
    type: typeof MyQuestionsActionType.LoadMyQuestionsFailed,
    errorMessage: string
}

export interface ToggleFavoriteQuestionAction {
    type: typeof MyQuestionsActionType.ToggleFavoriteQuestion,
    id: string
}

export type MyQuestionsActions =
    LoadMyQuestionsAction |
    LoadMyQuestionsFinishedAction |
    SetMyQuestionsAction |
    SetMyFavoriteQuestionsAction |
    LoadMyQuestionsFailedAction |
    ToggleFavoriteQuestionAction;


export enum QuestionActionType {
    QuestionAdd = "QuestionAdd",
    QuestionAddFailed = "QuestionAddFailed",
    QuestionAdded = "QuestionAdded",
    QuestionAddReset = "QuestionAddReset"
}

export interface QuestionAddAction {
    type: typeof QuestionActionType.QuestionAdd,
    questionText: string
}

interface QuestionAddFailedAction {
    type: typeof QuestionActionType.QuestionAddFailed,
    errorMessage: string
}

interface QuestionAddedAction {
    type: typeof QuestionActionType.QuestionAdded,
    questionId: string
}

interface QuestionAddReset {
    type: typeof QuestionActionType.QuestionAddReset
}

export type QuestionAddActions =
    QuestionAddAction |
    QuestionAddFailedAction |
    QuestionAddedAction |
    QuestionAddReset

export enum QuestionsSearchType {
    QuestionsSearch = "QuestionsSearch",
    QuestionsLoading = "QuestionsLoading",
    QuestionsLoadingFailed = "QuestionsLoadingFailed",
    SetQuestions = "SetQuestions"
}

export interface QuestionsSearchAction {
    type: typeof QuestionsSearchType.QuestionsSearch,
    keywords: string,
    tags: string[]
}

interface QuestionsLoadingAction {
    type: typeof QuestionsSearchType.QuestionsLoading
}

interface QuestionsLoadingFailedAction {
    type: typeof QuestionsSearchType.QuestionsLoadingFailed,
    errorMessage: string
}

export interface SetQuestionsAction {
    type: typeof QuestionsSearchType.SetQuestions,
    questions: IQuestion[]
}

export type QuestionsSearchActions =
    QuestionsSearchAction |
    QuestionsLoadingAction |
    QuestionsLoadingFailedAction |
    SetQuestionsAction
