import {Constraint} from "../data/constraints/Constraints";

export enum ConstraintActionType {
    SetConstraints = "SetConstraints"
}

export interface SetConstraintsAction {
    type: typeof ConstraintActionType.SetConstraints,
    constraints: Map<string, Constraint>
}

export type ConstraintActions =
    SetConstraintsAction
