export interface SessionContextItem {
    text: string,
    callback?: () => void
}

export enum SessionActionType {
    SetContext = "SetContext",
}

interface SetContextAction {
    type: typeof SessionActionType.SetContext,
    context: SessionContextItem[]
}


export type SessionActions =
    SetContextAction;
