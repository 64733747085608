import React from "react";
import {Grid, makeStyles} from "@material-ui/core";

interface LoadingProps {
    text?: string,
    fullHeight?: boolean
}

const useStyles = makeStyles((theme:any) => ({
    marginAutoContainer: {
        height: `calc(100vh - ${theme.spacing(6)}px - ${theme.mixins.toolbar.minHeight}px - 8px)`,
    },
    marginAutoItem: {
        margin: 'auto',
        textAlign: 'center'
    }
}));


const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
    const classes = useStyles();
    const fullHeight = props.fullHeight ?? true;
    return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={fullHeight ? classes.marginAutoContainer : ""}
    >
        <Grid item xs={8} className={classes.marginAutoItem}>
            <img src="/images/logos/animated-logo-small.gif" alt="logo"/>
            <h1>{props.text}</h1>
        </Grid>
    </Grid>;
};

export default Loading;
