import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Container,
    createStyles,
    FormLabel,
    Grid,
    Step,
    StepButton,
    Stepper,
    Theme
} from "@material-ui/core";
import {match, Redirect, useHistory, useLocation} from "react-router-dom";
import queryString from 'query-string';
import useFetchQuestion from "../../hooks/useFetchQuestion";
import Loading from "../LoadingComponent";
import StandardPicker, {getPickerData, initialPickerState, PickerState} from "../StandardPickerComponent";
import {IQuestion, ISole} from "../../shared/soleTypes";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import useFetchSole from "../../hooks/useFetchSole";
import SoleSelectionComponent from "./SoleSelectionComponent";
import SoleNumberComponent from "./SoleNumberComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {useSessionContext} from "../../hooks/useSessionContext";
import {SessionContextItem} from "../../redux/SessionTypes";
import useSaveSole from "../../hooks/useSaveSole";
import SoleBooleanComponent from "./SoleBooleanComponent";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import {Alert} from '@material-ui/lab';
import SoleStringComponent from "./SoleStringComponent";
import {NumberConstraints} from '../../data/constraints/Constraints';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        paper: {
            height: theme.spacing(36)
        },
        constrainWidth: {
            maxWidth: '223px'
        },
        sideMargin: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        },
        topMargin: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        completed: {
            display: 'inline-block',
        },
    }),
);

interface Identifiable {
    id: string
}

interface PlanProps {
    match?: match<Identifiable>
    location: {
        state: {
            question: IQuestion,
            pickerState: PickerState
        }
    }
}

const Plan: React.FC<PlanProps> = (props: PlanProps) => {
    const getLabel = useTranslationLabel();
    const classes = useStyles();
    const location = useLocation();
    const texts = useSelector((state: AppState) => state.text.texts);
    const constraints = useSelector((state: AppState) => state.constraints.constraints);
    const parsed = queryString.parse(location.search);
    const [question, isLoadingQuestion, questionErrorMessage, fetchQuestion] = useFetchQuestion(parsed.question as string || '');
    const [sole, isLoadingSole, soleErrorMessage, fetchSole] = useFetchSole(undefined);
    const [activeStep, setActiveStep] = useState(0);
    const [mySole, setMySole] = useState<ISole | undefined>(undefined);
    const [myPickerState, setMyPickerState] = useState<PickerState | undefined>(undefined);
    const [pickerState, setPickerState] = useState<PickerState | undefined>(undefined);
    const [saveSole, savedSole, isSavingSole, saveSoleErrorMessage] = useSaveSole();
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
    const [helpText, setHelpText] = useState<string | undefined>(undefined);
    const steps = getSteps();

    const [sessionContext, setSessionContext] = useSessionContext([]);
    const history = useHistory();

    useEffect(() => {
        let context: SessionContextItem[] = [
            {
                callback(): void {
                    history.push('/soles');
                },
                text: getLabel('ui.my_soles')!
            }
        ];
        if (mySole?.question?.text) {
            context.push({
                callback(): void { },
                text: mySole?.question?.text
            });
        }
        setSessionContext(context);
        const newCompleted = completed;
        newCompleted[0] = (mySole?.question?.text ?? '').length>0;

        // newCompleted[1] = mySole ? ('grade' in mySole && 'subject' in mySole) : false;

        //valid the class details step
        const validate1 = (mySole:ISole) =>{
            const durationConstraint = constraints.get('session.planned_duration') as NumberConstraints;
            const deviceConstraint = constraints.get('session.num_devices') as NumberConstraints;
            const studentConstraint = constraints.get('session.num_students') as NumberConstraints;
            const groupConstraint = constraints.get('session.num_groups') as NumberConstraints;
            if(mySole){
                if(!('grade' in mySole)) { return false }
                else if (!('subject' in mySole)) { return false }
                // else if(!('plannedDate' in mySole)){ return false }
                else if(durationConstraint.min && mySole.plannedDuration && mySole.plannedDuration < durationConstraint.min){ return false }
                else if(deviceConstraint.min && mySole.numDevices && mySole.numDevices < deviceConstraint.min){ return false }
                else if(studentConstraint.min && mySole.numStudents && mySole.numStudents < studentConstraint.min){ return false }
                else if(groupConstraint.min && mySole.numGroups && mySole.numGroups < groupConstraint.min){ return false }
                // else if(!(mySole.plannedDate instanceof Date)){ return false }
                else { return true }
            } else {
                return false;
            }
        };
        newCompleted[1] = mySole ? validate1(mySole) : false;

        const obsArray = mySole && 'targetObservations' in mySole ? mySole['targetObservations'] : [];
        newCompleted[2] = mySole ? ('contentObjectiveRdn' in mySole && obsArray.length>0) : false;
        newCompleted[0] ? newCompleted[1] ? setHelpText(undefined) : setHelpText(getLabel('ui.plan.missing_details')) : setHelpText(getLabel('ui.plan.missing_question'));
        setCompleted(newCompleted);
    }, [mySole]);

    useEffect(() => {
        const soleId = props.match?.params.id || '';
        if (soleId) {
            fetchSole(soleId);
        } else {
            if (props.location?.state?.pickerState) {
                setMyPickerState(props.location?.state?.pickerState);
            }

            const question = props.location?.state?.question;
            const urlQuestionId = parsed.question as string;
            if (question && (urlQuestionId && question?.id === urlQuestionId || urlQuestionId === undefined)) {
                setMySole({
                    question: question
                });
            } else if (urlQuestionId && urlQuestionId !== question?.id) {
                setMySole({});
                fetchQuestion(urlQuestionId);
            } else {
                setMySole(
                    {}
                );
            }
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (sole) {
                setMySole(sole);
                const pickerState = myPickerState ? myPickerState : initialPickerState;
                const [subjectRdn, gradeRdn, tags] = await getPickerData(sole);
                pickerState.selectedSubjectRdn = subjectRdn;
                pickerState.selectedGradeRdn = gradeRdn;
                pickerState.selectedTagRdns = tags || [];
                setMyPickerState(pickerState);
            }
        })();
    }, [sole]);

    useEffect(() => {
        if (question) {
            setMySole({
                ...mySole,
                question: question
            });
        }
    }, [question]);

    const handleSave = async () => {
        if (mySole) {
            //TODO: this does not properly save standards attached to a SOLE.  Neither does the old client though so not fixing now
            await saveSole(mySole);
        }
    };

    const handleDateChange = (date: Date | null) => {
        if (date) {
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const plannedDate = date;
            plannedDate.setHours(hours, minutes, seconds);
            setMySole({
                ...mySole,
                plannedDate: plannedDate
            });
        }
    };

    const handleTimeChange = (date: Date | null) => {
        if (date) {
            let plannedDate = mySole?.plannedDate || date;
            plannedDate.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
            setMySole({
                ...mySole,
                plannedDate: plannedDate
            });
        }
    };

    const onChangedStandardPicker = (state: PickerState) => {
        setPickerState(state);
    };

    const renderQuestion = () => {
        if (isLoadingQuestion) {
            return <Loading text="Loading..."/>
        } else if (questionErrorMessage) {
            return <div>
                <h1>{questionErrorMessage}</h1>
            </div>
        } else {
            return <Grid container justify="space-around" spacing={3}>
                <Grid item xs={10} sm={8}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        id="question"
                        label={ getLabel('ui.plan.what_is_your_big_question') }
                        name="question"
                        value={mySole?.question?.text}
                        onChange={(e: any) => {
                            if (mySole) {
                                const question = mySole.question ?? {};
                                question.text = e.target.value;
                                setMySole({
                                    ...mySole,
                                    question: question
                                });
//                            if (!mySole?.question) {
//                                mySole.question = {};
//                            }
//                            mySole.question.text = e.target.value;
//                            setMySole(mySole);
                            }
                        }}
                    />
                </Grid>
            </Grid>
        }
    };

    const renderPicker = () => {
        return <Grid container justify="space-around" spacing={3}>
            <Grid item xs={10} sm={8}>
                <StandardPicker
                    hasSessionContext={false}
                    callback={onChangedStandardPicker}
                    onlyStandardsWithQuestions={false}
                    pickerState={myPickerState}/>
            </Grid></Grid>;
    };

    const renderClassDetails = () => {
        return <form>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around" spacing={3}>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label={getLabel('ui.plan.what_is_the_planned_date?')}
                            format="MM/dd/yyyy"
                            fullWidth
                            value={mySole?.plannedDate}
                            // className={classes.sideMargin}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label={getLabel('ui.plan.what_is_the_planned_time?')}
                            fullWidth
                            value={mySole?.plannedDate}
                            // className={classes.sideMargin}
                            onChange={handleTimeChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleNumberComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="plannedDuration"
                            rdn="session.planned_duration"
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleNumberComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="numDevices"
                            rdn="session.num_devices"
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleNumberComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="numStudents"
                            rdn="session.num_students"
                        />
                    </Grid>

                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleNumberComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="numGroups"
                            rdn="session.num_groups"
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleSelectionComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="grade"
                            rdn="session.grade"
                        />
                    </Grid>
                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleSelectionComponent
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey="subject"
                            rdn="session.subject"
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item xs={10} sm={8} md={5} lg={4}>
                        <SoleStringComponent
                            label={getLabel('ui.plan.class_label')}
                            sole={mySole}
                            setSole={setMySole}
                            jsonKey='tag'
                            rdn='session.tag'
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </form>
    };

    const renderObservationDetails = () => {
        return <>
            <Grid container justify="space-around" spacing={3}>
                <Grid item xs={10} sm={8}>
                    <SoleSelectionComponent
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey="contentObjectiveRdn"
                        rdn="session.content_objective"
                    />
                </Grid>
            </Grid>
            <Grid container justify="space-around" spacing={3}>
                <Grid item xs={10} sm={8} md={5} lg={4}>
                    <SoleSelectionComponent
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey="materials"
                        rdn="session.materials"
                        isExpanded={true}
                    />
                </Grid>
                <Grid item xs={10} sm={8} md={5} lg={4}>
                    <FormLabel>How will you manage this SOLE?</FormLabel>
                    <SoleBooleanComponent
                        label={texts.find(t => t.rdn === "session.grouporganization")?.short ?? ''}
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey='groupOrganization'
                        rdn='session.grouporganization'
                    />
                    <SoleBooleanComponent
                        label={texts.find(t => t.rdn === "session.groupsharing")?.short ?? ''}
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey='groupsharing'
                        rdn='session.groupsharing'
                    />
                    <SoleBooleanComponent
                        label={texts.find(t => t.rdn === "session.self_assessment")?.short ?? ''}
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey='self_assessment'
                        rdn='session.self_assessment'
                    />
                </Grid>
                <Grid item xs={10} sm={8} md={12} lg={10}>
                    <SoleSelectionComponent
                        sole={mySole}
                        setSole={setMySole}
                        jsonKey="targetObservations"
                        rdn="session.target_observations"
                        isExpanded={true}
                        isRow={true}
                    />
                </Grid>
            </Grid>
        </>;
    };

    function getSteps() {
        return [getLabel('ui.plan.big_question_and_standard'), getLabel('ui.plan.class_details'), getLabel('ui.plan.objective_logistics')];
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <>
                    {renderQuestion()}
                    {renderPicker()}
                </>;
            case 1:
                return renderClassDetails();
            case 2:
                return renderObservationDetails();
            default:
                return 'Unknown step';
        }
    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.values(completed).filter(o => o).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    if (isSavingSole) {
        return <Loading text={ getLabel('ui.soles.saving_sole') }/>
    } else if (savedSole) {
        return <Redirect to='/soles/'/>
    } else if (isLoadingQuestion || isLoadingSole) {
        return <Loading text={ getLabel('ui.loading') }/>
    } else if (questionErrorMessage || soleErrorMessage) {
        return <div>
            <h1>{questionErrorMessage}{soleErrorMessage}</h1>
        </div>;
    } else {
        return <Container maxWidth="md">
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) =>
                    <Step key={label}>
                        <StepButton onClick={handleStep(index)} completed={completed[index]}>
                            {label}
                        </StepButton>
                    </Step>
                )}
            </Stepper>
            <div>

                {getStepContent(activeStep)}
                <Box mt={10}>
                    <Grid container alignItems={'center'} direction={'column'}>
                        {helpText && isLastStep() ? <Grid item xs={6} sm={4}>
                            <Alert severity="info">
                                {/*<AlertTitle>Info</AlertTitle>*/}
                                {helpText}
                            </Alert>
                        </Grid> : <></>}
                        <Grid item>
                        {activeStep > 0 ?
                            <Button onClick={handleBack} className={classes.button}>
                                Back
                            </Button> : <></>
                        }
                        {!isLastStep() ?
                            <Button
                                variant={allStepsCompleted() ? undefined : "contained"}
                                color={allStepsCompleted() ? undefined : "primary"}
                                onClick={handleNext}
                                className={classes.button}>
                                Next
                            </Button> :
                            <Button
                                disabled={!allStepsCompleted()}
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                className={classes.button}
                            >
                                {getLabel('ui.save')}
                            </Button>}
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Container>;
    }

};

export default Plan;
