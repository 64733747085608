import {DataPointActionType, DataPointsActions} from "./DataPointTypes";
import {DataPoint} from "../data/DataPoint";

export interface DataPointsState {
    isLoading: boolean,
    errorMessage?: string,
    dataPoints: DataPoint[]
}

const initialState: DataPointsState = {
    isLoading: false,
    errorMessage: undefined,
    dataPoints: []
};

export const DataPointsReducer = (state = initialState, action: DataPointsActions): DataPointsState => {
    switch (action.type) {
        case DataPointActionType.DataPointLoading:
            return {
                isLoading: true,
                errorMessage: undefined,
                dataPoints: []
            };
        case DataPointActionType.DataPointLoadingFailed:
            return {
                isLoading: false,
                errorMessage: action.errorMessage,
                dataPoints: []
            };
        case DataPointActionType.SetDataPoints:
            return {
                isLoading: false,
                errorMessage: undefined,
                dataPoints: action.dataPoints
            };
        default:
            return state;
    }
};
