import {put, select} from "redux-saga/effects";
import {dataPointsLoading, dataPointsLoadingFailed, setDataPoints} from "../redux/DataPointCreators";
import Parse from "parse";
import {DataPoint} from "../data/DataPoint";
import {SetDataPointsAction} from "../redux/DataPointTypes";
import {
    BooleanConstraints,
    Constraint,
    createConstraint,
    NumberConstraints,
    SelectionConstraints,
    StringConstraints
} from "../data/constraints/Constraints";
import {TextState} from "../redux/TextReducer";
import {setConstraints} from "../redux/ConstraintCreators";
import {AppState} from "../redux/configureStore";
import {ConstraintState} from "../redux/ConstraintReducer";

const getTextState = (state: AppState) => state.text;

function deserializeConstraints(json: string): Map<string, Constraint> {
    const map = new Map<string, any>(JSON.parse(json));
    const typedMap = new Map<string, Constraint>();
    map.forEach((constraint: any, rdn: string) => {
        let c: Constraint = createConstraint(constraint.tsclass);
        // @ts-ignore
        Object.assign(c, constraint);
        // @ts-ignore
        typedMap.set(rdn, c);
    });
    return typedMap;
}

export function* loadDataPointsSaga() {
    // Are the constraint objects already stored in redux
    const constraintState: ConstraintState = yield select((state: AppState) => state.constraints);
    const constraintsJson = localStorage.getItem('constraints') as string;
    if (constraintState.constraints.size <= 0) {
        // Load constraints from local storage and save them in redux
        if (constraintsJson) {
            try {
                const map = deserializeConstraints(constraintsJson);
                yield put(setConstraints(map));
            } catch (error) {
            }
        }
    }

    const remoteUpdatedAt: Date | undefined = yield Parse.Cloud.run('cache.getLatestUpdatedAt',
        {
            className: 'DataPoint'
        }, { });

    let localUpdatedAt: Date | undefined = undefined;
    const localUpdatedAtStr = localStorage.getItem('updatedAtDataPoint') as string;
    if (localUpdatedAtStr) {
        localUpdatedAt = new Date(localUpdatedAtStr);
    }

    const needsDownload = remoteUpdatedAt === undefined
        || localUpdatedAt === undefined
        || localUpdatedAt < remoteUpdatedAt
        || (constraintsJson === null);

    // Load datapoints from backend and create constraints
    if (needsDownload) {
        yield put(dataPointsLoading());
        try {
            const parseDataPoints: Parse.Object[] = yield Parse.Cloud.run("cache.get", {
                cls: 'DataPoint',
                os: 'web',
                ver: '2.0',
                lng: 'en_US',
                latest: 0
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
            const dataPoints =
                parseDataPoints
                    .filter(dp => dp.get('weight') >= 0)
                    .map(dp => new DataPoint(dp));

            yield put(setDataPoints(dataPoints));

            if (remoteUpdatedAt !== undefined) {
                localStorage.setItem('updatedAtDataPoint', remoteUpdatedAt.toISOString());
            }
        } catch (error) {
            yield put(dataPointsLoadingFailed(error.message));
        }
    }
}

function getBooleanFromString(t: string) {
    return t === 'true' ||
        t === 'yes' ||
        t === 'on' ||
        t === '1';
}

export function* setDataPointsSaga(action: SetDataPointsAction) {
    let constraints = new Map<string, Constraint>();
    for (let dp of action.dataPoints) {
        if (dp.type === 'number') {
            let constraint = new NumberConstraints();

            if (dp?.rules['min']) {
                constraint.min = Number(dp.rules['min']);
            }
            if (dp?.rules['max']) {
                constraint.max = Number(dp.rules['max']);
            }
            if (dp?.rules['default']) {
                constraint.defaultValue = Number(dp.rules['default']);
            }
            if (dp?.rules['step']) {
                constraint.step = Number(dp.rules['step']);
            }
            if (dp?.rules['unit']) {
                constraint.unit = dp?.rules['unit'];
            }
            constraints.set(dp.rdn, constraint);
        } else if (dp.type === 'bool') {
            let constraint = new BooleanConstraints();
            if (dp?.rules['default']) {
                constraint.defaultValue = getBooleanFromString(dp.rules['default'] ?? "false");
            }
            constraints.set(dp.rdn, constraint);
        } else if (dp.type === 'string') {
            let constraint = new StringConstraints();
            let emptyValue = dp?.rules['empty'] ?? '';
            constraint.isRequired = getBooleanFromString(emptyValue);
            constraints.set(dp.rdn, constraint);
        } else if (dp.type === 'mailerflags') {
            let constraint = new SelectionConstraints();
            const result = yield Parse.Cloud.run('userpub.getSubscriptions', {},
                {sessionToken: Parse.User.current()?.getSessionToken()});
            const keys = Object.keys(result);
            constraint.min = 0;
            constraint.max = keys.length;
            const prefix = 'subscription.';
            for (let rdn of keys) {
                const textState: TextState = yield select(getTextState);
                if (textState.texts) {
                    const textRdn = prefix + rdn;
                    const text = textState.texts.find(t => t.rdn === textRdn);
                    constraint.items.push({rdn: rdn, title: text?.label ?? ''});
                }
            }

            constraints.set(dp.rdn, constraint);
        } else if (dp.type === 'selection') {
            let constraint = new SelectionConstraints();
            const isMultiSelect = dp?.rules['mode'] === 'multiselect';

            constraint.min = dp?.rules['min'] ? Number(dp.rules['min']) : 1;
            constraint.max = dp?.rules['max'] ? Number(dp.rules['max']) : (isMultiSelect ? Number.MAX_VALUE : 1);

            const source = dp?.rules['source'];
            const path = dp?.rules['path'] || dp.rdn;
            if (source) {
                const parameters = dp?.rules['parm'];

                try {
                    const result = yield Parse.Cloud.run(source, parameters, {
                        sessionToken: Parse.User.current()?.getSessionToken()
                    });
                    if (Array.isArray(result)) {
                        for (let item of result) {
                            if (item instanceof Parse.Object) {
                                const rdn = item.get('rdn');
                                const title = item.get('short');
                                if (rdn && title) {
                                    constraint.items.push({rdn: rdn, title: title});
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            } else if (path) {
                const prefix = path + '.';
                const textState: TextState = yield select(getTextState);
                if (textState.texts) {
                    constraint.items = textState.texts
                        .filter(text => text.rdn.startsWith(prefix))
                        .sort((t1, t2) => (t1.weight ?? 0) - (t2.weight ?? 0))
                        .map(soleText => {
                            return {rdn: soleText.rdn, title: soleText.label ?? ''}
                        });
                }
            }

            if (dp?.rules['exclude']) {
                const excludedRdns: string[] = dp?.rules['exclude'] as string[];
                constraint.items = constraint.items.filter(item => !excludedRdns.includes(item.rdn));
            }

            if (dp?.rules['insert']) {
                console.log('wtf?');
            }
            if (dp?.rules['hide']) {
                console.log('wtf?');
            }
            constraints.set(dp.rdn, constraint);
        }
    }

    yield put(setConstraints(constraints));
}

