import {
    MyQuestionsActions,
    MyQuestionsActionType,
    QuestionActionType,
    QuestionAddActions,
    QuestionsSearchActions,
    QuestionsSearchType
} from "./QuestionTypes";
import {IQuestion} from "../shared/soleTypes";

export interface MyQuestionsState {
    isLoading: boolean,
    errorMessage?: string,
    myQuestions: IQuestion[],
    myFavoriteQuestions: IQuestion[]
}

const initialMyQuestionsState: MyQuestionsState = {
    isLoading: false,
    errorMessage: undefined,
    myQuestions: [],
    myFavoriteQuestions: []
};

export const MyQuestionsReducer = (state = initialMyQuestionsState, action: MyQuestionsActions): MyQuestionsState => {
    switch (action.type) {
        case MyQuestionsActionType.LoadMyQuestions:
            return {
                ...state,
                isLoading: true,
                errorMessage: undefined
            };
        case MyQuestionsActionType.LoadMyQuestionsFailed:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.errorMessage
            };
        case MyQuestionsActionType.SetMyQuestions:
            return {
                ...state,
                myQuestions: action.questions
            };
        case MyQuestionsActionType.SetMyFavoriteQuestions:
            return {
                ...state,
                myFavoriteQuestions: action.questions
            };
        case MyQuestionsActionType.LoadMyQuestionsFinished:
            return {
                ...state,
                isLoading: false,
                errorMessage: undefined
            };
        default:
            return state;
    }
};


export interface QuestionAddState {
    isSaving: boolean,
    addedQuestionId?: string,
    errorMessage?: string
}

const initialQuestionAddState: QuestionAddState = {
    isSaving: false,
    addedQuestionId: undefined,
    errorMessage: undefined
};

export const QuestionAddReducer = (state = initialQuestionAddState, action: QuestionAddActions): QuestionAddState => {
    switch (action.type) {
        case QuestionActionType.QuestionAdd:
            return {
                isSaving: true,
                addedQuestionId: undefined,
                errorMessage: undefined
            };
        case QuestionActionType.QuestionAddFailed:
            return {
                isSaving: false,
                addedQuestionId: undefined,
                errorMessage: action.errorMessage
            };
        case QuestionActionType.QuestionAdded:
            return {
                isSaving: false,
                addedQuestionId: action.questionId,
                errorMessage: undefined
            };
        case QuestionActionType.QuestionAddReset:
            return initialQuestionAddState;
        default:
            return state;
    }
};


export interface QuestionsSearchState {
    isLoading: boolean,
    questions: IQuestion[],
    errorMessage?: string
}

const initialQuestionsSearchState: QuestionsSearchState = {
    isLoading: false,
    questions: [],
    errorMessage: undefined
};

export const QuestionsSearchReducer = (state = initialQuestionsSearchState, action: QuestionsSearchActions): QuestionsSearchState => {
    switch (action.type) {
        case QuestionsSearchType.QuestionsLoading:
            return {
                isLoading: true,
                errorMessage: undefined,
                questions: []
            };
        case QuestionsSearchType.QuestionsLoadingFailed:
            return {
                isLoading: false,
                errorMessage: action.errorMessage,
                questions: []
            };
        case QuestionsSearchType.SetQuestions:
            return {
                isLoading: false,
                errorMessage: undefined,
                questions: action.questions
            };
        default:
            return state;
    }
};
