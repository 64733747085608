// @ts-nocheck
//TODO: type this?
import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";

const PieGraph = ({
                      title,
                      values
                  }) => {

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#ff8042','#EF5350','#AB47BC','#5C6BC0','#29B6F6','#26A69A','#FFA726'];

    return (
        <div>
            <h3>{title}</h3>
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie dataKey="value" startAngle={360} endAngle={0} data={values} cx={200} cy={200} outerRadius={180} fill="#8884d8" label>
                        {
                            values.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                    <Tooltip/>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PieGraph;
