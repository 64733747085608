import {SoleText} from "../data/SoleText";

export default [
    new SoleText('ui.save', 'en_US', 'Save'),
    new SoleText('ui.delete', 'en_US', 'Delete'),
    new SoleText('ui.cancel', 'en_US', 'Cancel'),
    new SoleText('ui.ok', 'en_US', 'OK'),
    new SoleText('ui.download', 'en_US', 'Download'),
    new SoleText('ui.undo', 'en_US', 'Undo'),
    new SoleText('ui.favorite', 'en_US', 'Favorite'),
    new SoleText('ui.unfavorite', 'en_US', 'Unfavorite'),
    new SoleText('ui.contact_us', 'en_US', 'Contact Us'),
    new SoleText('ui.support', 'en_US', 'support@startsole.org'),
    new SoleText('ui.add_a_question', 'en_US', 'Add a Question'),
    new SoleText('ui.adding_question', 'en_US', 'Adding question...'),
    new SoleText('ui.enter_your_question', 'en_US', 'Enter your question here'),
    new SoleText('ui.submit_question', 'en_US', 'Submit Question'),
    new SoleText('ui.back', 'en_US', 'Back'),
    new SoleText('ui.close', 'en_US', 'Close'),
    new SoleText('ui.continue', 'en_US', 'Continue'),
    new SoleText('ui.copy', 'en_US', 'copy'),
    new SoleText('ui.how_to_sole', 'en_US', 'How to SOLE?'),
    new SoleText('ui.history_of_startsole', 'en_US', 'History of StartSOLE'),
    new SoleText('ui.loading', 'en_US', 'Loading...'),
    new SoleText('ui.my_profile', 'en_US', 'My Profile'),
    new SoleText('ui.question', 'en_US', 'Question'),
    new SoleText('ui.admin', 'en_US', 'Admin'),
    new SoleText('ui.my_questions', 'en_US', 'My Questions'),
    new SoleText('ui.my_soles', 'en_US', 'My SOLEs'),
    new SoleText('ui.my_community', 'en_US', 'My Community'),
    new SoleText('ui.plan_a_lesson', 'en_US', 'Plan a Lesson'),
    new SoleText('ui.teacher_resources', 'en_US', 'Teacher Resources'),
    new SoleText('ui.virtual', 'en_US', 'Virtual'),
    new SoleText('ui.explore_questions', 'en_US', 'Explore Questions'),
    new SoleText('ui.home.lets_get_started', 'en_US', 'Let\'s Get Started'),
    new SoleText('ui.home.welcome_card_text', 'en_US', 'We can customize a brief message here for different rings or whatever'),
    new SoleText('ui.home.start_tour', 'en_US', 'Start Tour'),
    new SoleText('ui.admin.educators', 'en_US', 'Educators'),
    new SoleText('ui.admin.map', 'en_US', 'Map'),
    new SoleText('ui.admin.soles', 'en_US', 'SOLEs'),
    new SoleText('ui.admin.schools', 'en_US', 'Schools'),
    new SoleText('ui.admin.loading_schools', 'en_US', 'Loading Schools...'),
    new SoleText('ui.admin.loading_educators', 'en_US', 'Loading Educators...'),
    new SoleText('ui.admin.loading_email settings', 'en_US', 'Loading Email Settings...'),
    new SoleText('ui.admin.loading_question', 'en_US', 'Loading Question...'),
    new SoleText('ui.admin.soles_by_grade', 'en_US', 'SOLEs By Grade'),
    new SoleText('ui.admin.soles_by_subject', 'en_US', 'SOLEs By Subject'),
    new SoleText('ui.admin.users_by_subject', 'en_US', 'Educators By Subject'),
    new SoleText('ui.admin.users_by_grade', 'en_US', 'Educators By Grade'),
    new SoleText('ui.admin.users_per_month_title', 'en_US', 'New User Signups'),
    new SoleText('ui.admin.users_per_month_x_axis', 'en_US', 'month'),
    new SoleText('ui.admin.users_per_month_y_axis', 'en_US', 'new users'),
    new SoleText('ui.admin.loading_soles', 'en_US', 'Loading SOLEs...'),
    new SoleText('ui.learn_more', 'en_US', 'Learn More'),
    new SoleText('ui.plan.big_question_and_standard', 'en_US', 'Big Question'),
    new SoleText('ui.plan.what_is_your_big_question', 'en_US', 'What is your big question?'),
    new SoleText('ui.profile.about_me', 'en_US', 'About Me'),
    new SoleText('ui.profile.location', 'en_US', 'Location'),
    new SoleText('ui.profile.email', 'en_US', 'Email'),
    new SoleText('ui.profile.certificates', 'en_US', 'Certificates'),
    new SoleText('ui.profile.password', 'en_US', 'Password'),
    new SoleText('ui.profile.trainer_materials', 'en_US', 'Trainer'),
    new SoleText('ui.profile.certificate_explanation', 'en_US', 'Educators earn points for their work with StartSOLE.  You can download your certificates here.'),
    new SoleText('ui.profile.no_certificates', 'en_US', 'You haven\'t earned any certificates... yet.'),
    new SoleText('ui.profile.progress_caption', 'en_US', '/2000 points to your next certificate'),
    new SoleText('ui.profile.trainer_explanation', 'en_US', 'Please remember to share any copied content with support@startsole.org so we can learn from your experience.'),
    new SoleText('ui.profile.about_first_name', 'en_US', 'First Name'),
    new SoleText('ui.profile.about_last_name', 'en_US', 'Last Name'),
    new SoleText('ui.profile.about_role', 'en_US', 'Role'),
    new SoleText('ui.profile.about_grades', 'en_US', 'Grades'),
    new SoleText('ui.profile.about_subjects', 'en_US', 'Subjects'),
    new SoleText('ui.profile.location_add', 'en_US', 'Add your location'),
    new SoleText('ui.profile.location_update', 'en_US', 'Change your location'),
    new SoleText('ui.profile.location_explanation', 'en_US', 'We use your location to customize your experience and deliver content in your language if available.'),
    new SoleText('ui.profile.complete_header', 'en_US', 'Complete your Profile'),
    new SoleText('ui.profile.need_more_info', 'en_US', 'We need a little more information to customize your experience, please complete the fields below.'),
    new SoleText('ui.profile.new_password', 'en_US', 'New password'),
    new SoleText('ui.profile.update_password', 'en_US', 'Update password'),
    new SoleText('ui.profile.current_password', 'en_US', 'Current password'),
    new SoleText('ui.profile.password_explanation', 'en_US', 'Use the form below to reset your password.  If you are having difficulty and need support feel free to contact: '),
    new SoleText('ui.profile.password_updated', 'en_US', 'Password successfully updated'),
    new SoleText('ui.profile.privacy_header', 'en_US', 'StartSOLE Privacy Policy'),
    new SoleText('ui.profile.privacy_body', 'en_US', 'Your privacy is important to us.  We use the information on this page to customize content for your SOLE experience and deliver big questions aligned to your standards if they are available.  We use cookies to cache your data personalise content.  We don\'t share your information with advertisers.  If you\'d like to read our complete privacy policy you can click the link below.'),
    new SoleText('ui.profile.privacy_button', 'en_US', 'Privacy Policy'),
    new SoleText('ui.questions.all_subjects', 'en_US', 'All Subjects'),
    new SoleText('ui.questions.mine', 'en_US', 'MINE'),
    new SoleText('ui.questions.saved', 'en_US', 'SAVED'),
    new SoleText('ui.profile.email_explanation', 'en_US', 'Use the checkboxes below to manage when you will receive emails from us based on your actions with StartSOLE.'),
    new SoleText('ui.plan.class_details', 'en_US', 'Class Details'),
    new SoleText('ui.plan.what_is_the_planned_date?', 'en_US', 'What is the planned date?'),
    new SoleText('ui.plan.what_is_the_planned_time?', 'en_US', 'What is the planned time?'),
    new SoleText('ui.plan.objective_logistics', 'en_US', 'Objectives & Logistics'),
    new SoleText('ui.plan.missing_question', 'en_US', 'Please add a question before saving'),
    new SoleText('ui.plan.missing_details', 'en_US', 'Please complete your class details before saving'),
    new SoleText('ui.question.plan_a_lesson', 'en_US', 'Plan a lesson'),
    new SoleText('ui.question.virtual_sole', 'en_US', 'Virtual SOLE'),
    new SoleText('ui.question.added_to_favorites', 'en_US', 'Added to Favorites'),
    new SoleText('ui.question.removed_from_favorites', 'en_US', 'Removed from Favorites'),
    new SoleText('ui.question.resources', 'en_US', 'Related Resources'),
    new SoleText('ui.reflect.add_photos', 'en_US', 'Add Photos'),
    new SoleText('ui.reflect.drop_photos', 'en_US', 'Drag and drop photos here or click to browse'),
    new SoleText('ui.reflect.review_photos', 'en_US', 'Review Photos'),
    new SoleText('ui.reflect.objective_question', 'en_US', ' What was your objective for this SOLE?'),
    new SoleText('ui.reflect.objective_question_suffix', 'en_US', ' was your objective for this SOLE.'),
    new SoleText('ui.reflect.objective_question_achieved', 'en_US', 'Do you think you achieved this objective?'),
    new SoleText('ui.reflect_objectives', 'en_US', 'Objectives'),
    new SoleText('ui.reflect_ratings', 'en_US', 'Ratings'),
    new SoleText('ui.reflect_final', 'en_US', 'Finally'),
    new SoleText('ui.resources.shared_by', 'en_US', 'Shared by'),
    new SoleText('ui.soles.loading_soles', 'en_US', 'Loading SOLEs...'),
    new SoleText('ui.soles.saving_sole', 'en_US', 'Saving SOLE...'),
    new SoleText('ui.soles.copying_sole', 'en_US', 'Copying SOLE...'),
    new SoleText('ui.soles.planned', 'en_US', 'Planned'),
    new SoleText('ui.soles.reflect', 'en_US', 'Reflect'),
    new SoleText('ui.soles.completed', 'en_US', 'Completed'),
    new SoleText('ui.sole-card.virtual', 'en_US', 'Virtual'),
    new SoleText('ui.sole-card.delete_title', 'en_US', 'Are you sure you want to delete this SOLE?'),
    new SoleText('ui.sole-card.delete_body', 'en_US', 'Deleting this SOLE will remove all associated data and clear this SOLE from your list.  Are you sure you want to proceed?'),
    new SoleText('ui.sole-card.download_plan', 'en_US', 'Download Plan'),
    new SoleText('ui.sole-card.download_summary', 'en_US', 'Download Summary'),
    new SoleText('ui.sole-card.reflect', 'en_US', 'Reflect'),
    new SoleText('ui.virtual.title', 'en_US', 'Welcome to StartSOLE Virtual'),
    new SoleText('ui.virtual.ready_to_go', 'en_US', 'You\'re ready to go virtual!'),
    new SoleText('ui.virtual.button_text', 'en_US', 'I\'m Interested'),
    new SoleText('ui.virtual.button_setup', 'en_US', 'Setup Virtual SOLE'),
    new SoleText('ui.virtual.button_instructions', 'en_US', 'Click the button below to create your virtual SOLE session.'),
    new SoleText('ui.virtual.click_the_button_explanation', 'en_US', 'Click the button below to start your virtual SOLE:'),
    new SoleText('ui.virtual.copy_link', 'en_US', 'Copy the link below to send to the participants:'),
    new SoleText('ui.virtual.explanation', 'en_US', 'StartSOLE Virtual is a tool we are piloting to allow educators to facilitate a SOLE session remotely.  Looks like it\'s not available in your region yet, but if you\'re interested in learning more please let us know using the form below:'),
    new SoleText('ui.virtual.get_started', 'en_US', 'Get Started'),
    new SoleText('ui.virtual.share_to_classroom', 'en_US', 'Share to Google Classroom'),
    new SoleText('ui.virtual.explanation_details', 'en_US', 'StartSOLE Virtual allows you to facilitate a SOLE session remotely with useful features like chat, webcam sharing, multi-user whiteboards, screen sharing, breakout rooms for small group work.'),
    new SoleText('ui.virtual.need_a_question', 'en_US', 'To get started you\'ll need a Big Question. Use one of the options below to find or create a question, then click on the blue \'Virtual StartSOLE\' button to setup your session.'),
    new SoleText('ui.plan.class_label', 'en_US', 'Class label (optional)'),
    new SoleText('error.min.default', 'en_US', 'This value is too low'),
    new SoleText('error.min.session.planned_duration', 'en_US', 'SOLEs should be at least 30 minutes'),
    new SoleText('error.min.session.num_devices', 'en_US', 'A SOLE requires at least 1 device'),
    new SoleText('error.min.session.num_students', 'en_US', 'A SOLE requires at least 3 students'),
    new SoleText('error.min.session.num_groups', 'en_US', 'A SOLE requires at least 2 groups'),
    new SoleText('ui.login.logging_in', 'en_US', 'Logging In...'),
    new SoleText('ui.login.sign_in', 'en_US', 'Sign In'),
    new SoleText('ui.login.google_unavailable', 'en_US', 'Google login is unavailable'),
    new SoleText('ui.login.or', 'en_US', 'or'),
    new SoleText('ui.login.forgot_password', 'en_US', 'Forgot password?'),
    new SoleText('ui.login.no_account', 'en_US', 'Don\'t have an account? Sign Up'),
    new SoleText('ui.login.reset_password', 'en_US', 'Reset Password'),
    new SoleText('ui.login.reset_password_confirmation', 'en_US', 'Enter your email below and we\'ll send you a link to reset your password.'),
    new SoleText('ui.login.reset_password_cancel', 'en_US', 'Send Reset Email'),
    new SoleText('ui.login.reset_password_success', 'en_US', 'Password reset!'),
    new SoleText('ui.register', 'en_US', 'Register'),
    new SoleText('ui.register.sign_up', 'en_US', 'Sign Up'),
    new SoleText('ui.register.register_with_google', 'en_US', 'Register with your Google Account'),
    new SoleText('ui.register.already_have_account', 'en_US', 'Already have an account? Login'),
    new SoleText('ui.maintenance.title', 'en_US', 'Upcoming Maintenance'),
    new SoleText('ui.maintenance.description', 'en_US', 'We will be performing scheduled maintenance on our website and mobile apps.  We apologize for any inconvenience.  Our tools will be unavailable from '),
    new SoleText('ui.maintenance.token', 'en_US', '5'), // change this when showing a new message

    new SoleText('ui.save', 'es_CO', 'Guardar'),
    new SoleText('ui.delete', 'es_CO', 'Borrar'),
    new SoleText('ui.cancel', 'es_CO', 'Cancelar'),
    new SoleText('ui.download', 'es_CO', 'Descargar'),
    new SoleText('ui.contact_us', 'es_CO', 'Contáctanos'),
    new SoleText('ui.add_a_question', 'es_CO', 'Añadir una pregunta'),
    new SoleText('ui.adding_question', 'es_CO', 'Añadiendo pregunta...'),
    new SoleText('ui.enter_your_question', 'es_CO', 'Introduce tu pregunta aquí'),
    new SoleText('ui.submit_question', 'es_CO', 'Enviar pregunta'),
    new SoleText('ui.back', 'es_CO', 'Atrás'),
    new SoleText('ui.close', 'es_CO', 'Cerrar'),
    new SoleText('ui.continue', 'es_CO', 'Continuar'),
    new SoleText('ui.how_to_sole', 'es_CO', 'Cómo hacer SOLE?'),
    new SoleText('ui.history_of_startsole', 'es_CO', 'Historia de StartSOLE'),
    new SoleText('ui.loading', 'es_CO', 'Cargando...'),
    new SoleText('ui.my_profile', 'es_CO', 'Mi perfil'),
    new SoleText('ui.admin', 'es_CO', 'Administración'),
    new SoleText('ui.my_questions', 'es_CO', 'Mis preguntas'),
    new SoleText('ui.my_soles', 'es_CO', 'Mis SOLEs'),
    new SoleText('ui.plan_a_lesson', 'es_CO', 'Planear una lección'),
    new SoleText('ui.teacher_resources', 'es_CO', 'Recursos para maestros'),
    new SoleText('ui.virtual', 'es_CO', 'Virtual'),
    new SoleText('ui.explore_questions', 'es_CO', 'Explora Preguntas'),
    new SoleText('ui.home.lets_get_started', 'es_CO', 'Empecemos'),
    new SoleText('ui.home.welcome_card_text', 'es_CO', 'Podemos personalizar un breve mensaje aquí para diferentes círculos o para lo que sea'),
    new SoleText('ui.home.start_tour', 'es_CO', 'Empezar recorrido'),
    new SoleText('ui.admin.educators', 'es_CO', 'Los educadores'),
    new SoleText('ui.admin.map', 'es_CO', 'Mapa'),
    new SoleText('ui.admin.soles', 'es_CO', 'SOLEs'),
    new SoleText('ui.admin.schools', 'es_CO', 'Colegios'),
    new SoleText('ui.admin.loading_schools', 'es_CO', 'Cargando colegios...'),
    new SoleText('ui.admin.loading_educators', 'es_CO', 'Cargando educadores...'),
    new SoleText('ui.admin.soles_by_grade', 'es_CO', 'SOLEs por Grado'),
    new SoleText('ui.admin.soles_by_subject', 'es_CO', 'SOLEs por asignatura'),
    new SoleText('ui.admin.users_by_subject', 'es_CO', 'Educadores por asignatura'),
    new SoleText('ui.admin.users_by_grade', 'es_CO', 'Educadores por grado'),
    new SoleText('ui.admin.users_per_month_title', 'es_CO', 'Nuevos registros de usuario'),
    new SoleText('ui.admin.users_per_month_x_axis', 'es_CO', 'Mes'),
    new SoleText('ui.admin.users_per_month_y_axis', 'es_CO', 'Usuarios nuevos'),
    new SoleText('ui.admin.loading_soles', 'es_CO', 'Cargando SOLEs...'),
    new SoleText('ui.learn_more', 'es_CO', 'Aprende más'),
    new SoleText('ui.plan.big_question_and_standard', 'es_CO', 'Gran Pregunta'),
    new SoleText('ui.plan.what_is_your_big_question', 'es_CO', '¿Cuál es tu Gran Pregunta?'),
    new SoleText('ui.profile.about_me', 'es_CO', 'Sobre mí'),
    new SoleText('ui.profile.location', 'es_CO', 'Localización'),
    new SoleText('ui.profile.email', 'es_CO', 'Correo electrónico'),
    new SoleText('ui.profile.certificates', 'es_CO', 'certificados'),
    new SoleText('ui.profile.password', 'es_CO', 'Contraseña'),
    new SoleText('ui.profile.trainer_materials', 'es_CO', 'Entrenador'),
    new SoleText('ui.profile.certificate_explanation', 'es_CO', 'Los educadores ganan puntos por su trabajo con StartSOLE. Puedes descargar tus certificados aquí.'),
    new SoleText('ui.profile.trainer_explanation', 'es_CO', 'Por favor recuerda compartir cualquier contenido copiado con support@startsole.org para que podemos aprender de tu experiencia.'),
    new SoleText('ui.profile.about_first_name', 'es_CO', 'Nombre'),
    new SoleText('ui.profile.about_last_name', 'es_CO', 'Apellido'),
    new SoleText('ui.profile.about_role', 'es_CO', 'Rol'),
    new SoleText('ui.profile.about_grades', 'es_CO', 'Grados'),
    new SoleText('ui.profile.about_subjects', 'es_CO', 'Asignaturas'),
    new SoleText('ui.profile.location_add', 'es_CO', 'Añade tu ubicación'),
    new SoleText('ui.profile.location_update', 'es_CO', 'Cambia tu ubicación'),
    new SoleText('ui.profile.location_explanation', 'es_CO', 'Utilizamos tu ubicación para personalizar tu experiencia y entregar contenido en tu idioma, si está disponible.'),
    new SoleText('ui.profile.complete_header', 'es_CO', 'Completa tu perfil'),
    new SoleText('ui.profile.need_more_info', 'es_CO', 'Necesitamos un poco más de información para personalizar tu experiencia, por favor completa los campos siguientes.'),
    new SoleText('ui.profile.new_password', 'es_CO', 'Nueva contraseña'),
    new SoleText('ui.profile.update_password', 'es_CO', 'Actualiza contraseña'),
    new SoleText('ui.profile.current_password', 'es_CO', 'Contraseña actual'),
    new SoleText('ui.profile.password_explanation', 'es_CO', 'Utiliza el siguiente formulario para restablecer la contraseña. Si estás teniendo dificultades y necesitas apoyo puedes contactarnos:'),
    new SoleText('ui.profile.password_updated', 'es_CO', 'Contraseña actualizada correctamente'),
    new SoleText('ui.profile.privacy_header', 'es_CO', 'Política de privacidad de StartSOLE'),
    new SoleText('ui.profile.privacy_body', 'es_CO', 'Tu privacidad es importante para nosotros. Utilizamos la información en esta página para personalizar el contenido para tu experiencia SOLE y entregar Grandes Preguntas alineados con tus estándares, si están disponibles. Utilizamos cookies para almacenar en caché los datos para personalizar el contenido. No compartimos tu información con los anunciantes. Si deseas leer nuestra política de privacidad completa puedes hacer clic en el enlace de abajo.'),
    new SoleText('ui.profile.privacy_button', 'es_CO', 'Política de privacidad'),
    new SoleText('ui.questions.all_subjects', 'es_CO', 'Todas las asignaturas'),
    new SoleText('ui.questions.mine', 'es_CO', 'MÍA'),
    new SoleText('ui.questions.saved', 'es_CO', 'GUARDADO'),
    new SoleText('ui.profile.email_explanation', 'es_CO', 'Utiliza las casillas de verificación para gestionar cuando recibir correos electrónicos de nosotros en base a tus acciones con StartSOLE.'),
    new SoleText('ui.plan.class_details', 'es_CO', 'Detalles de la clase'),
    new SoleText('ui.plan.what_is_the_planned_date?', 'es_CO', '¿Cuál es la fecha prevista?'),
    new SoleText('ui.plan.what_is_the_planned_time?', 'es_CO', '¿Cuál es el plazo previsto?'),
    new SoleText('ui.plan.objective_logistics', 'es_CO', 'Objetivos y Logística'),
    new SoleText('ui.plan.missing_question', 'es_CO', 'Por favor añade una pregunta antes de guardar'),
    new SoleText('ui.plan.missing_details', 'es_CO', 'Por favor completa los detalles de tu clase antes de guardar'),
    new SoleText('ui.reflect.add_photos', 'es_CO', 'Agrega fotos'),
    new SoleText('ui.reflect.drop_photos', 'es_CO', 'Arrastra y suelta fotos aquí o haz clic para navegar'),
    new SoleText('ui.reflect.review_photos', 'es_CO', 'Revisa las fotos'),
    new SoleText('ui.reflect.objective_question', 'es_CO', ' ¿Cuál fue tu objetivo para este SOLE?'),
    new SoleText('ui.reflect.objective_question_suffix', 'es_CO', ' fue tu objetivo para este SOLE.'),
    new SoleText('ui.reflect.objective_question_achieved', 'es_CO', '¿Crees que alcanzaste este objetivo?'),
    new SoleText('ui.reflect_objectives', 'es_CO', 'Objetivos'),
    new SoleText('ui.reflect_ratings', 'es_CO', 'calificaciones'),
    new SoleText('ui.reflect_final', 'es_CO', 'Finalmente'),
    new SoleText('ui.resources.shared_by', 'es_CO', 'Compartido por'),
    new SoleText('ui.soles.loading_soles', 'es_CO', 'Cargando SOLEs...'),
    new SoleText('ui.soles.planned', 'es_CO', 'Planificado'),
    new SoleText('ui.soles.reflect', 'es_CO', 'Reflexiona'),
    new SoleText('ui.soles.completed', 'es_CO', 'Terminado'),
    new SoleText('ui.sole-card.virtual', 'es_CO', 'Virtual'),
    new SoleText('ui.sole-card.delete_title', 'es_CO', '¿Seguro que quieres eliminar este SOLE?'),
    new SoleText('ui.sole-card.delete_body', 'es_CO', 'La eliminación de este SOLE eliminará todos los datos asociados y quitará este SOLE de tu lista. ¿Estas seguro que deseas continuar?'),
    new SoleText('ui.sole-card.download_plan', 'es_CO', 'Descargar plan '),
    new SoleText('ui.sole-card.download_summary', 'es_CO', 'Resumen de la descarga'),
    new SoleText('ui.sole-card.reflect', 'es_CO', 'Reflexiona'),
    new SoleText('ui.virtual.title', 'es_CO', 'Bienvenido a StartSOLE Virtual'),
    new SoleText('ui.virtual.ready_to_go', 'es_CO', 'Estás listo para arrancar el SOLE Virtual!'),
    new SoleText('ui.virtual.button_text', 'es_CO', 'Estoy interesado'),
    new SoleText('ui.virtual.button_setup', 'es_CO', 'Configuración de SOLE Virtual'),
    new SoleText('ui.virtual.button_instructions', 'es_CO', 'Haz clic en el botón de abajo para crear la sesión de SOLE Virtual.'),
    new SoleText('ui.virtual.click_the_button_explanation', 'es_CO', 'Haz clic en el botón de abajo para empezar tu SOLE Virtual'),
    new SoleText('ui.virtual.copy_link', 'es_CO', 'Copia el enlace de abajo para enviarlo a los participantes:'),
    new SoleText('ui.virtual.explanation', 'es_CO', 'StartSOLE Virtual es una herramienta que estamos poniendo a prueba para permitir a los educadores facilitar una sesión SOLE de forma remota. Parece que no está disponible en tu región todavía, pero si estás interesado en aprender más, por favor, házlosno saber mediante el siguiente formulario:'),
    new SoleText('ui.virtual.get_started', 'es_CO', 'Empieza'),
    new SoleText('ui.virtual.share_to_classroom', 'es_CO', 'Comparte con Google Classroom'),
    new SoleText('ui.virtual.explanation_details', 'es_CO', 'StartSOLE Virtual te permite facilitar una sesión SOLE de forma remota con características útiles como el chat, intercambio de webcam, pizarras de varios usuarios, compartir pantalla, salas externas en grupos pequeños.'),
    new SoleText('ui.virtual.need_a_question', 'es_CO', 'Para empezar se necesita un Gran Pregunta. Utiliza una de las siguientes opciones para encontrar o crear una pregunta, a continuación, haz clic en el botón azul \'StartSOLE Virtual\' para configurar tu sesión.'),
    new SoleText('ui.plan.class_label', 'es_CO', 'Etiqueta del curso (opcional)'),
];
