import React, {useEffect, useState} from "react";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import {
    Card,
    makeStyles,
    createStyles,
    CardActions,
    CardHeader,
    CardMedia,
    CardContent,
    Typography,
    Grid, CardActionArea, Button
} from "@material-ui/core";
import {Link as RouterLink, match} from "react-router-dom";
import {useSessionContext} from "../../hooks/useSessionContext";
import {red} from "@material-ui/core/colors";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../TabPanel";
import {trainings, Training} from "./trainings/trainings";
import {Tag} from "../../data/Tag";
import Parse from "parse";

interface Point {
    createdAt: Date,
    points: number,
    rdn: string,
    object: {
        className: string,
        objectId: string
    }
}

const useStyles = makeStyles((theme) => createStyles({
        root: {
            maxWidth: 345,
            marginBottom: '5px'
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
    }),
);

interface CommunityProps {
    match?: match<{ ringRdn: string }>
}

async function getPoints(): Promise<any> {
    const sessionToken = Parse.User.current()?.getSessionToken();
    return await Parse.Cloud.run('userpub.getPoints', {}, {
        sessionToken: sessionToken
    });
}

function filterByValue(array: Point[], trainingId: string) {
    return array.some(o =>
        o.rdn.includes(trainingId)
    );
}

const LakeErieTrainings: React.FC<CommunityProps> = (props) => {
    const [points, setPoints] = useState<Point[]>([]);
    useEffect(() => {
        (async () => {
            const points = await getPoints();
            setPoints(points);
        })();
    }, []);
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const pub = useSelector((state: AppState) => state.user.pub);
    const ringRdn = props.match?.params.ringRdn;
    const ring = pub?.rings?.find(ring => ring.rdn === ringRdn);
    const [expanded, setExpanded] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const completedTrainings:Training[] = [];
    const incompleteTraings:Training[] = [];
    trainings.map((training, index) => {
        if(points.some((o) => o.rdn.includes(training.id))){
            completedTrainings.push(training)
        } else {
            incompleteTraings.push(training)
        }
    });

    useSessionContext([
        {
            callback(): void {
            },
            text: getLabel('ui.my_community') ?? 'My Community'
        },
        {
            callback(): void {
            },
            text: ring?.name ?? 'My Ring'
        },
        {
            callback(): void {
            },
            text: ring?.name ?? 'Trainings'
        }
    ]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return <>
        <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
        >
            <Tab label={'incomplete'}/>
            <Tab label={'complete'}/>
        </Tabs>
        <TabPanel value={value} index={0}>
            <Grid container>
                {incompleteTraings.map((training, index) => {
                    return <Grid item sm={12} md={6} lg={4}>
                        <Card className={classes.root}>
                            <CardActionArea component={RouterLink} to={{
                                pathname: `/training/${training.id}`,
                            }}>
                                <CardHeader
                                    title={training.title}
                                    subheader={training.time ?? ''}
                                />
                                <CardMedia
                                    className={classes.media}
                                    image={training.image}
                                    title={training.title}
                                />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {training.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions disableSpacing>
                                <Button
                                    size="small"
                                    component={RouterLink}
                                    to={{
                                        pathname: `/training/${training.id}`,
                                    }}>Start Training</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                })}
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <Grid container>
                {completedTrainings.map((training, index) => {
                    return <Grid item sm={12} md={6} lg={4}>
                        <Card className={classes.root}>
                            <CardActionArea component={RouterLink} to={{
                                pathname: `/training/${training.id}`,
                            }}>
                                <CardHeader
                                    title={training.title}
                                    subheader={training.time ?? ''}
                                />
                                <CardMedia
                                    className={classes.media}
                                    image={training.image}
                                    title={training.title}
                                />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {training.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions disableSpacing>
                                <Button
                                    size="small"
                                    component={RouterLink}
                                    to={{
                                        pathname: `/training/${training.id}`,
                                    }}>Start Training</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                })}
            </Grid>
        </TabPanel>
    </>;
};

export default LakeErieTrainings;