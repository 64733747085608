// Action types

import {IReport} from "../shared/soleTypes";

export enum ReportActionType {
    LoadReport = "LoadReport",
    SetReport = "SetReport",
    LoadReportFinished = "LoadReportFinished",
    LoadReportFailed = "LoadReportFailed",
}

export interface LoadReportAction {
    name: string,
    type: typeof ReportActionType.LoadReport
    ring: string,
    parms: {}
}

export interface SetReportAction {
    type: typeof ReportActionType.SetReport
    report: IReport
}

export interface LoadReportFinishedAction {
    type: typeof ReportActionType.LoadReportFinished,
}

export interface LoadReportFailedAction {
    type: typeof ReportActionType.LoadReportFailed
    errorMessage: string
}

export type ReportActions =
    LoadReportAction |
    SetReportAction |
    LoadReportFinishedAction |
    LoadReportFailedAction;
