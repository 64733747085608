import {DataPointActionType, DataPointsActions} from './DataPointTypes';
import {DataPoint} from "../data/DataPoint";

export const loadDataPoints = (): DataPointsActions => ({
    type: DataPointActionType.DataPointLoad
});

export const dataPointsLoading = (): DataPointsActions => ({
    type: DataPointActionType.DataPointLoading
});

export const dataPointsLoadingFailed = (errorMessage: string): DataPointsActions => ({
    type: DataPointActionType.DataPointLoadingFailed,
    errorMessage: errorMessage
});

export const setDataPoints = (dataPoints: DataPoint[]): DataPointsActions => ({
    type: DataPointActionType.SetDataPoints,
    dataPoints: dataPoints
});
