import red from '@material-ui/core/colors/red';
import {createMuiTheme} from '@material-ui/core/styles';

// A custom theme for StartSOLE
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#28A79A',
        },
        secondary: {
          main: '#2196F3'
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fbfdfa',
        }
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                color: 'white'
            }
        }
    },
    spacing: 5
});

const colombiaTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1a84ae',
        },
        secondary: {
            main: '#26bfb8',
        },
        background: {
            default: '#DCFF75',
        },
        warning: {
            main: '#eb3b81',
        },
        info: {
            main: '#26bfb8',
        },
        success: {
            main: '#a8d543',
        },
        text: {
            primary: '#7f7f7f',
        },
    },
    typography: {
        fontFamily: 'Space Grotesk',
        fontWeightLight: 200,
        h3: {
            fontFamily: 'Space Grotesk',
            lineHeight: 0.94,
            fontWeight: 400,
        },
        h4: {
            fontFamily: 'Raleway',
            fontWeight: 700,
        },
        body1: {
            fontFamily: 'Raleway',
            lineHeight: 1.41,
        },
        body2: {
            fontFamily: 'Raleway',
        },
        fontWeightBold: 700,
        subtitle1: {
            lineHeight: 1.4,
            fontSize: '0.8rem',
        },
        button: {
            fontWeight: 800,
            lineHeight: 1.73,
        },
        h2: {
            fontWeight: 600,
        },
    },
});

export default theme;
