import React, {useState} from 'react';
import {AutoRotatingCarousel, Slide} from "material-auto-rotating-carousel";
import {teal} from "@material-ui/core/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useSelector} from "react-redux";
import {AppState} from "../redux/configureStore";
import {getRingProps} from "../theme/RingProps";

const AutoRotatingCarouselModal: React.FC = (props) => {

    const [handleOpen, setHandleOpen] = useState({ open: true });
    const pub = useSelector((state: AppState) => state.user.pub);
    const mobile = useMediaQuery("(max-width:600px)");
    let customSlides = pub ? getRingProps(pub).customSlides : [];
    customSlides = customSlides ?? [];
    let slides = [
        <Slide
            media={
                <img src="/images/carousel/slide-1.png" alt={''}/>
            }
            mediaBackgroundStyle={{ backgroundColor: teal[50] }}
            style={{ backgroundColor: teal[600] }}
            title="Let Learning Happen"
            subtitle="StartSOLE is a simple tool to help you plan, facilitate, and reflect on engaging inquiry experiences."
        />,<Slide
            media={
                <img src="/images/carousel/slide-2.png" alt={''} />
            }
            mediaBackgroundStyle={{ backgroundColor: teal[50] }}
            style={{ backgroundColor: teal[600] }}
            title="Support your Process"
            subtitle="Explore 15,000+ standards-aligned essential questions and create a lesson plan in minutes."
            landscape
        />,<Slide
            media={
                <img src="/images/carousel/slide-3.png"  alt={''}/>
            }
            mediaBackgroundStyle={{ backgroundColor: teal[50] }}
            style={{ backgroundColor: teal[600] }}
            title="Help is on the Way!"
            subtitle="Reflect on your experience and get personalized help while earning certificates for SOLE"
        />].concat(customSlides);

    const renderSlides = (customSlides:JSX.Element[]) => {
        return <AutoRotatingCarousel
                label="Get started"
                open={handleOpen.open}
                onClose={() => setHandleOpen({ open: false })}
                onStart={() => setHandleOpen({ open: false })}
                // autoplay={false}
                interval={7000}
                mobile={mobile}
                style={{ position: "absolute" }}
            >
            {slides.map((slide, index)=>{
                return slide;
            })}

            </AutoRotatingCarousel>;
    };

    return <div>
        {renderSlides(customSlides)}
    </div>;
};

export default AutoRotatingCarouselModal;

