import Parse from "parse";
import {UserActions, UserActionType} from "./UserTypes";
import {ISchool, IUserPub} from "../shared/soleTypes";

export const login = (email: string, password: string): UserActions => ({
    type: UserActionType.Login,
    email: email,
    password: password
});

export const loginWithToken = (sessionToken: string, email: string): UserActions => ({
   type: UserActionType.LoginWithToken,
   sessionToken: sessionToken,
    email: email
});

export const loginPending = (): UserActions => ({
    type: UserActionType.LoginPending
});

export const loginFailed = (errorMessage: string): UserActions => ({
    type: UserActionType.LoginFailed,
    errorMessage: errorMessage
});

export const setCurrentUser = (user: Parse.User, pub: IUserPub): UserActions => ({
    type: UserActionType.SetCurrentUser,
    user: user,
    pub: pub
});

export const saveUser = (pub: IUserPub): UserActions => ({
    type: UserActionType.SaveUser,
    pub: pub
});

export const setSchool = (school: ISchool): UserActions => ({
    type: UserActionType.SetSchool,
    school: school
});

export const saveSchool = (placeId: string): UserActions => ({
    type: UserActionType.SaveSchool,
    placeId: placeId
});

export const resetUser = (): UserActions => ({
    type: UserActionType.ResetUser
});

export const signUp = (email: string, password: string): UserActions => ({
    type: UserActionType.SignUp,
    email: email,
    password: password
});

export const completeOnboarding = (pub: IUserPub): UserActions => ({
    type: UserActionType.CompleteOnboarding,
    pub: pub
});