import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {IUserPub} from "../../shared/soleTypes";
import {saveUser} from "../../redux/UserCreators";
import {Avatar, Button, makeStyles, Theme} from "@material-ui/core";
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";
import {AboutMe, AboutMeState, createAboutMeState} from "./ProfileAboutMeComponent";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        margin: theme.spacing(1)
    },
    marginAutoContainer: {
        width: '100%',
        display: 'flex'
    },
    marginAutoItem: {
        margin: 'auto'
    },
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        backgroundColor: "#F6F6F6",
        margin: "auto",
        '&:hover': {
            backgroundColor: "#d5d5d5",
        },
    },
    savedAlert: {
        marginTop: "1rem"
    }
}));

export const AboutMeForm: React.FC = () => {
    const pub = useSelector((state: AppState) => state.user.pub);
    const [updatedAt, setUpdatedAt] = useState(pub?.updatedAt);
    const [wasSaved, setWasSaved] = useState(false);
    const [state, setState] = useState<AboutMeState>(createAboutMeState(pub));
    const dispatch = useDispatch();
    const classes = useStyles();
    const getLabel = useTranslationLabel();

    const onChangedState = (newState: AboutMeState) => {
        setState(newState);
    };

    useEffect(() => {
        const oldUpdatedAt = updatedAt;
        const newUpdatedAt = pub?.updatedAt;
        setUpdatedAt(newUpdatedAt);
        if (oldUpdatedAt !== undefined && newUpdatedAt !== undefined) {
            if (newUpdatedAt > oldUpdatedAt) {
                setWasSaved(true);
                setTimeout(() => setWasSaved(false), 2000);
            }
        }
    }, [pub]);

    const save = () => {
        if (pub) {
            const newPub: IUserPub = {
                ...pub,
                firstName: state.firstName,
                lastName: state.lastName,
                role: state.role,
                grades: state.grades,
                subjects: state.subjects
            };
            console.log('save ' + JSON.stringify(newPub));
            dispatch(saveUser(newPub));
        }
    };

    return <div>
        <Avatar alt="profile image" src="/images/logos/logo-with-margin.svg" className={classes.avatar}/>
        <AboutMe callback={onChangedState}/>

        <div className={classes.marginAutoContainer}>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={clsx(classes.button, classes.marginAutoItem)}
                onClick={() => save()}
                startIcon={<SaveIcon/>}
            >
                {getLabel('ui.save')}
            </Button>
        </div>

        {wasSaved ?
            <Alert
                className={classes.savedAlert}
                severity="success">
                User updated
            </Alert>
            : <div/>
        }

    </div>
};
