import React from "react";
import {AppState} from "../../redux/configureStore";
import {useSelector} from "react-redux";
import Loading from "../LoadingComponent";
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {SoleState} from "../../shared/soleTypes";
import SoleCard from "./SoleCardComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";
import TabPanel from "../TabPanel";
import {useSessionContext} from "../../hooks/useSessionContext";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const PlannedSoles: React.FC = () => {
    const soles = useSelector((state: AppState) => state.soles.soles);

    const items = soles
        .filter(sole => sole.state === SoleState.StatePlanned || sole.state === SoleState.StateFacilitate)
        .map(sole => <SoleCard key={sole.id} sole={sole}/>);
    return <>
        {items}
    </>;
};

const ReflectSoles: React.FC = () => {
    const soles = useSelector((state: AppState) => state.soles.soles);

    const items = soles
        .filter(sole => sole.state === SoleState.StateReflect)
        .map(sole => <SoleCard key={sole.id} sole={sole}/>);
    return <>
        {items}
    </>;
};

const CompletedSoles: React.FC = () => {
    const soles = useSelector((state: AppState) => state.soles.soles);

    const items = soles
        .filter(sole => sole.state === SoleState.StateCompleted)
        .map(sole => <SoleCard key={sole.id} sole={sole}/>);
    return <>
        {items}
    </>;
};

const Soles: React.FC = () => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const [value, setValue] = React.useState(0);
    useSessionContext([
        {
            callback(): void { },
            text: getLabel('ui.my_soles')!
        }
    ]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const isLoading = useSelector((state: AppState) => state.soles.isLoading);
    const errorMessage = useSelector((state: AppState) => state.soles.errorMessage);

    if (isLoading) {
        return <div>
            <Loading text={ getLabel('ui.soles.loading_soles') }/>
        </div>
    } else if (errorMessage) {
        return <h1>Error {errorMessage}</h1>
    } else {
        return <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={ getLabel('ui.soles.planned') }/>
                <Tab label={ getLabel('ui.soles.reflect') }/>
                <Tab label={ getLabel('ui.soles.completed') }/>
            </Tabs>
            <TabPanel value={value} index={0}>
                <PlannedSoles/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ReflectSoles/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <CompletedSoles/>
            </TabPanel>
        </div>
    }
};

export default Soles;
