import React, {useEffect, useState} from "react";
import {Avatar, Box, CircularProgress, LinearProgress, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Parse from "parse";
import {ICertificate} from "../../shared/soleTypes";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";

const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        margin: "auto",
        backgroundColor: "#F6F6F6"
    }
}));

const useCertificates = (): [ICertificate[], boolean] => {
    const [certificates, setCertificates] = useState<ICertificate[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const c = await Parse.Cloud.run('document.getAll',
                    { type: 'certificate'}, {
                        sessionToken: Parse.User.current()?.getSessionToken()
                    });
                setCertificates(c);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        })();
    }, []);

    return [certificates, isLoading];
};

const Certificates: React.FC = () => {
    const getLabel = useTranslationLabel();
    const classes = useStyles();
    const pub = useSelector((state: AppState) => state.user.pub);
    const [certificates, isLoading] = useCertificates();

    const renderCertificates = () => {
        return certificates.length>0? <ul>
            {certificates.map(c => <li><b><a target="_blank" href={c.file}>{c.createdAt?.toLocaleString()}</a></b></li>)}
        </ul> : <Typography variant="body1" gutterBottom align={"center"}>{getLabel('ui.profile.no_certificates')}</Typography>;
    };

    const renderProgress = () => {
        return pub?.points? <Box m={5}>
            <LinearProgress variant="determinate" value={pub.points/20} />
            <Typography display="block"
                        color="textSecondary"
                        variant="caption"
                        align="center">{pub.points}{getLabel('ui.profile.progress_caption')}</Typography>
        </Box> : <></>;
    };

    return <div>
        <Avatar alt="Certificate Image" src="/images/profile/certificate-avatar.svg" className={classes.avatar} />
        <Typography variant="body1" gutterBottom={true} align={"center"}>
            {getLabel('ui.profile.certificate_explanation')}
        </Typography>
        { isLoading ? <></> : renderProgress() }
        { isLoading ? <Box mt={5} display="flex" justifyContent="center"><CircularProgress/></Box> : renderCertificates() }
    </div>
};

export default Certificates;
