import {IResource, IResourceTag} from "../shared/soleTypes";
import {ResourceActions, ResourceActionType} from "./ResourceTypes";
import {resources, resourceTags} from "./resources";

export interface ResourceState {
    resources: IResource[],
    resourceTags: IResourceTag[]
}

const initialState: ResourceState = {
    resources: resources,
    resourceTags: resourceTags
};

export const ResourceReducer = (state = initialState, action: ResourceActions): ResourceState => {
    switch (action.type) {
        case ResourceActionType.SetResources:
            return {
                ...state,
                resources: action.resources
            };
        default:
            return state;
    }
};
