export const ohMap ={
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.879834,
              40.732443
            ],
            [
              -83.880194,
              40.64469
            ],
            [
              -84.107787,
              40.643069
            ],
            [
              -84.107908,
              40.657938
            ],
            [
              -84.231913,
              40.656943
            ],
            [
              -84.23445,
              40.681021
            ],
            [
              -84.276934,
              40.685759
            ],
            [
              -84.28718,
              40.656609
            ],
            [
              -84.31815,
              40.656542
            ],
            [
              -84.346289,
              40.633048
            ],
            [
              -84.425655,
              40.633248
            ],
            [
              -84.425609,
              40.656515
            ],
            [
              -84.456007,
              40.658737
            ],
            [
              -84.456172,
              40.728306
            ],
            [
              -84.456336,
              40.800965
            ],
            [
              -84.416217,
              40.79388
            ],
            [
              -84.417297,
              40.844794
            ],
            [
              -84.39801,
              40.844873
            ],
            [
              -84.398407,
              40.903265
            ],
            [
              -84.340969,
              40.903596
            ],
            [
              -84.340531,
              40.859099
            ],
            [
              -84.109586,
              40.860994
            ],
            [
              -84.10968,
              40.868148
            ],
            [
              -83.995021,
              40.876387
            ],
            [
              -83.995208,
              40.915026
            ],
            [
              -83.937745,
              40.920261
            ],
            [
              -83.880423,
              40.920429
            ],
            [
              -83.88026,
              40.863482
            ],
            [
              -83.871059,
              40.841399
            ],
            [
              -83.880063,
              40.819919
            ],
            [
              -83.879834,
              40.732443
            ]
          ]
        ]
      },
      "properties": {
        "name": "Allen County",
        "teachers": 963
      },
      "rdn": "us.oh.allen-county-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -80.519225,
              41.499924
            ],
            [
              -80.713163,
              41.499762
            ],
            [
              -81.003125,
              41.501685
            ],
            [
              -81.003631,
              41.715137
            ],
            [
              -81.002176,
              41.853157
            ],
            [
              -80.900342,
              41.868912
            ],
            [
              -80.824949,
              41.893358
            ],
            [
              -80.803342,
              41.907686
            ],
            [
              -80.773135,
              41.908218
            ],
            [
              -80.63049,
              41.943528
            ],
            [
              -80.519425,
              41.977523
            ],
            [
              -80.519357,
              41.669767
            ],
            [
              -80.519225,
              41.499924
            ]
          ]
        ]
      },
      "properties": {
        "name": "Ashtabula County",
        "teachers": 772
      },
      "rdn": "us.oh.ashtabula-county-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -82.289663,
              39.384195
            ],
            [
              -82.28041,
              39.472906
            ],
            [
              -82.274095,
              39.562554
            ],
            [
              -82.28112,
              39.59141
            ],
            [
              -82.261954,
              39.590592
            ],
            [
              -82.256708,
              39.648331
            ],
            [
              -82.166784,
              39.644066
            ],
            [
              -82.161181,
              39.687648
            ],
            [
              -82.085863,
              39.684991
            ],
            [
              -82.089071,
              39.63932
            ],
            [
              -82.032075,
              39.63666
            ],
            [
              -82.039605,
              39.550217
            ],
            [
              -82.047528,
              39.550825
            ],
            [
              -82.052889,
              39.491876
            ],
            [
              -82.025147,
              39.490791
            ],
            [
              -82.027002,
              39.458636
            ],
            [
              -81.869618,
              39.451293
            ],
            [
              -81.84651,
              39.42361
            ],
            [
              -81.853668,
              39.318165
            ],
            [
              -81.816614,
              39.302058
            ],
            [
              -81.818361,
              39.272581
            ],
            [
              -81.72181,
              39.269568
            ],
            [
              -81.725583,
              39.215835
            ],
            [
              -81.756254,
              39.177276
            ],
            [
              -81.744621,
              39.148413
            ],
            [
              -81.742153,
              39.116777
            ],
            [
              -81.752353,
              39.089878
            ],
            [
              -81.775554,
              39.078378
            ],
            [
              -81.807855,
              39.083978
            ],
            [
              -81.811655,
              39.059578
            ],
            [
              -81.764253,
              39.015279
            ],
            [
              -81.78182,
              38.964935
            ],
            [
              -81.756131,
              38.933545
            ],
            [
              -81.785647,
              38.926193
            ],
            [
              -81.814235,
              38.946168
            ],
            [
              -81.844486,
              38.928746
            ],
            [
              -81.848653,
              38.901407
            ],
            [
              -81.889233,
              38.874279
            ],
            [
              -81.910312,
              38.879294
            ],
            [
              -81.926671,
              38.901311
            ],
            [
              -81.899953,
              38.925405
            ],
            [
              -81.900595,
              38.937671
            ],
            [
              -81.933186,
              38.987659
            ],
            [
              -81.977455,
              38.992679
            ],
            [
              -81.994961,
              39.022478
            ],
            [
              -82.035963,
              39.025478
            ],
            [
              -82.051563,
              38.994378
            ],
            [
              -82.089065,
              38.975978
            ],
            [
              -82.098873,
              38.958319
            ],
            [
              -82.095465,
              39.002778
            ],
            [
              -82.323894,
              39.013154
            ],
            [
              -82.306669,
              39.205494
            ],
            [
              -82.268583,
              39.20376
            ],
            [
              -82.260936,
              39.285514
            ],
            [
              -82.298476,
              39.294996
            ],
            [
              -82.289663,
              39.384195
            ]
          ]
        ]
      },
      "properties": {
        "name": "Athens-Meigs",
        "teachers": 821
      },
      "rdn": "us.oh.athens-meig-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -84.440173,
                40.325244
              ],
              [
                -84.472639,
                40.329108
              ],
              [
                -84.454821,
                40.354198
              ],
              [
                -84.455386,
                40.402423
              ],
              [
                -84.43385,
                40.402425
              ],
              [
                -84.426535,
                40.429383
              ],
              [
                -84.43861,
                40.440904
              ],
              [
                -84.474195,
                40.444995
              ],
              [
                -84.469516,
                40.480196
              ],
              [
                -84.455152,
                40.48164
              ],
              [
                -84.456007,
                40.658737
              ],
              [
                -84.425609,
                40.656515
              ],
              [
                -84.425655,
                40.633248
              ],
              [
                -84.346289,
                40.633048
              ],
              [
                -84.31815,
                40.656542
              ],
              [
                -84.28718,
                40.656609
              ],
              [
                -84.276934,
                40.685759
              ],
              [
                -84.23445,
                40.681021
              ],
              [
                -84.231913,
                40.656943
              ],
              [
                -84.107908,
                40.657938
              ],
              [
                -84.107787,
                40.643069
              ],
              [
                -83.880194,
                40.64469
              ],
              [
                -83.880338,
                40.593942
              ],
              [
                -83.903936,
                40.593823
              ],
              [
                -83.903957,
                40.565908
              ],
              [
                -83.880018,
                40.5656
              ],
              [
                -83.879932,
                40.538708
              ],
              [
                -83.917447,
                40.535376
              ],
              [
                -83.917609,
                40.520651
              ],
              [
                -83.963571,
                40.521361
              ],
              [
                -83.993917,
                40.527874
              ],
              [
                -84.011947,
                40.497624
              ],
              [
                -84.107147,
                40.483161
              ],
              [
                -84.107124,
                40.505834
              ],
              [
                -84.221514,
                40.505687
              ],
              [
                -84.221084,
                40.482227
              ],
              [
                -84.25077,
                40.481819
              ],
              [
                -84.259742,
                40.461162
              ],
              [
                -84.285194,
                40.461208
              ],
              [
                -84.303009,
                40.445243
              ],
              [
                -84.307334,
                40.419342
              ],
              [
                -84.297477,
                40.389823
              ],
              [
                -84.370665,
                40.367364
              ],
              [
                -84.428901,
                40.36605
              ],
              [
                -84.440173,
                40.325244
              ]
            ]
          ],
          [
            [
              [
                -83.477759,
                40.730106
              ],
              [
                -83.459415,
                40.702251
              ],
              [
                -83.419836,
                40.686823
              ],
              [
                -83.420251,
                40.613982
              ],
              [
                -83.44973,
                40.601519
              ],
              [
                -83.437988,
                40.58685
              ],
              [
                -83.440769,
                40.556252
              ],
              [
                -83.417965,
                40.556202
              ],
              [
                -83.418378,
                40.505232
              ],
              [
                -83.520227,
                40.504077
              ],
              [
                -83.524982,
                40.465347
              ],
              [
                -83.607025,
                40.466774
              ],
              [
                -83.605019,
                40.487138
              ],
              [
                -83.626078,
                40.513361
              ],
              [
                -83.696565,
                40.558391
              ],
              [
                -83.685579,
                40.571737
              ],
              [
                -83.70878,
                40.585954
              ],
              [
                -83.732308,
                40.58368
              ],
              [
                -83.738718,
                40.650083
              ],
              [
                -83.727049,
                40.657265
              ],
              [
                -83.730358,
                40.702688
              ],
              [
                -83.765968,
                40.732857
              ],
              [
                -83.879834,
                40.732443
              ],
              [
                -83.880063,
                40.819919
              ],
              [
                -83.871059,
                40.841399
              ],
              [
                -83.79501,
                40.841565
              ],
              [
                -83.774815,
                40.819328
              ],
              [
                -83.727869,
                40.819342
              ],
              [
                -83.727672,
                40.848451
              ],
              [
                -83.689307,
                40.848512
              ],
              [
                -83.689575,
                40.819496
              ],
              [
                -83.580716,
                40.818926
              ],
              [
                -83.580704,
                40.783729
              ],
              [
                -83.563993,
                40.780787
              ],
              [
                -83.563749,
                40.730705
              ],
              [
                -83.571222,
                40.709795
              ],
              [
                -83.495832,
                40.708815
              ],
              [
                -83.477759,
                40.730106
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "Auglaize County",
        "teachers": 732
      },
      "rdn": "us.oh.auglaize-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.696194,
              38.761722
            ],
            [
              -83.705314,
              38.639761
            ],
            [
              -83.77216,
              38.65815
            ],
            [
              -83.78362,
              38.695641
            ],
            [
              -83.840595,
              38.721912
            ],
            [
              -83.859028,
              38.756793
            ],
            [
              -83.926986,
              38.771562
            ],
            [
              -83.943978,
              38.783616
            ],
            [
              -83.978814,
              38.787104
            ],
            [
              -84.052685,
              38.771375
            ],
            [
              -84.025441,
              38.985821
            ],
            [
              -84.008067,
              39.092067
            ],
            [
              -83.99748,
              39.19289
            ],
            [
              -83.986677,
              39.233231
            ],
            [
              -83.964753,
              39.252099
            ],
            [
              -83.865679,
              39.247333
            ],
            [
              -83.86846,
              39.104138
            ],
            [
              -83.872214,
              39.021304
            ],
            [
              -83.750864,
              39.020465
            ],
            [
              -83.710181,
              39.022433
            ],
            [
              -83.704116,
              39.038182
            ],
            [
              -83.673018,
              39.02043
            ],
            [
              -83.696194,
              38.761722
            ]
          ]
        ]
      },
      "properties": {
        "name": "Brown County",
        "teachers": 432
      },
      "rdn": "us.oh.brown-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.815148,
              39.548687
            ],
            [
              -84.785967,
              39.567664
            ],
            [
              -84.650372,
              39.567365
            ],
            [
              -84.630035,
              39.588226
            ],
            [
              -84.591926,
              39.588222
            ],
            [
              -84.591531,
              39.567532
            ],
            [
              -84.518666,
              39.562208
            ],
            [
              -84.510337,
              39.573585
            ],
            [
              -84.47908,
              39.573844
            ],
            [
              -84.479213,
              39.591024
            ],
            [
              -84.365232,
              39.589493
            ],
            [
              -84.364633,
              39.544668
            ],
            [
              -84.406643,
              39.536557
            ],
            [
              -84.423043,
              39.497457
            ],
            [
              -84.442106,
              39.481767
            ],
            [
              -84.393461,
              39.465004
            ],
            [
              -84.350478,
              39.461442
            ],
            [
              -84.325313,
              39.476625
            ],
            [
              -84.325179,
              39.444193
            ],
            [
              -84.33984,
              39.427458
            ],
            [
              -84.343054,
              39.397814
            ],
            [
              -84.351972,
              39.306901
            ],
            [
              -84.369919,
              39.294299
            ],
            [
              -84.452667,
              39.301483
            ],
            [
              -84.598746,
              39.308556
            ],
            [
              -84.625717,
              39.319518
            ],
            [
              -84.641814,
              39.305044
            ],
            [
              -84.74971,
              39.304926
            ],
            [
              -84.760964,
              39.322968
            ],
            [
              -84.795648,
              39.321267
            ],
            [
              -84.819451,
              39.305153
            ],
            [
              -84.815148,
              39.548687
            ]
          ]
        ]
      },
      "properties": {
        "name": "Butler County",
        "teachers": 2774
      },
      "rdn": "us.oh.butler-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -83.016806,
                40.484263
              ],
              [
                -82.989879,
                40.448776
              ],
              [
                -82.911074,
                40.444198
              ],
              [
                -82.898234,
                40.428801
              ],
              [
                -82.869382,
                40.427508
              ],
              [
                -82.868606,
                40.355236
              ],
              [
                -82.824125,
                40.353205
              ],
              [
                -82.794265,
                40.333008
              ],
              [
                -82.771661,
                40.314434
              ],
              [
                -82.778198,
                40.278053
              ],
              [
                -82.68918,
                40.274489
              ],
              [
                -82.689818,
                40.264439
              ],
              [
                -82.628165,
                40.255974
              ],
              [
                -82.59576,
                40.257498
              ],
              [
                -82.592042,
                40.293913
              ],
              [
                -82.570855,
                40.317816
              ],
              [
                -82.552561,
                40.298272
              ],
              [
                -82.481245,
                40.295136
              ],
              [
                -82.491184,
                40.265396
              ],
              [
                -82.462763,
                40.245152
              ],
              [
                -82.467364,
                40.187073
              ],
              [
                -82.561787,
                40.191399
              ],
              [
                -82.573903,
                40.041625
              ],
              [
                -82.645726,
                40.035687
              ],
              [
                -82.650805,
                40.050686
              ],
              [
                -82.679852,
                40.046528
              ],
              [
                -82.681668,
                40.023988
              ],
              [
                -82.697257,
                39.991948
              ],
              [
                -82.715137,
                39.973455
              ],
              [
                -82.761313,
                39.965709
              ],
              [
                -82.768805,
                39.939085
              ],
              [
                -82.811962,
                39.926491
              ],
              [
                -82.816565,
                39.877441
              ],
              [
                -82.792479,
                39.874087
              ],
              [
                -82.761525,
                39.857513
              ],
              [
                -82.76307,
                39.836303
              ],
              [
                -82.747548,
                39.819701
              ],
              [
                -82.79467,
                39.802383
              ],
              [
                -82.798015,
                39.822533
              ],
              [
                -82.821496,
                39.824271
              ],
              [
                -82.824199,
                39.795592
              ],
              [
                -82.793755,
                39.779273
              ],
              [
                -82.750178,
                39.77639
              ],
              [
                -82.752114,
                39.76168
              ],
              [
                -82.791491,
                39.734271
              ],
              [
                -82.806803,
                39.749255
              ],
              [
                -82.828416,
                39.750063
              ],
              [
                -82.824254,
                39.794996
              ],
              [
                -83.037634,
                39.805567
              ],
              [
                -83.153257,
                39.808465
              ],
              [
                -83.180238,
                39.803421
              ],
              [
                -83.200943,
                39.810656
              ],
              [
                -83.227398,
                39.823156
              ],
              [
                -83.22464,
                39.851021
              ],
              [
                -83.247301,
                39.860777
              ],
              [
                -83.252031,
                39.917363
              ],
              [
                -83.225643,
                39.932262
              ],
              [
                -83.241699,
                39.952552
              ],
              [
                -83.260877,
                40.002807
              ],
              [
                -83.25168,
                40.008376
              ],
              [
                -83.25383,
                40.049218
              ],
              [
                -83.212196,
                40.048069
              ],
              [
                -83.207884,
                40.092816
              ],
              [
                -83.187269,
                40.114627
              ],
              [
                -83.208023,
                40.1359
              ],
              [
                -83.255345,
                40.154757
              ],
              [
                -83.27197,
                40.172387
              ],
              [
                -83.294879,
                40.161496
              ],
              [
                -83.291621,
                40.109363
              ],
              [
                -83.350583,
                40.110004
              ],
              [
                -83.384356,
                40.089611
              ],
              [
                -83.394943,
                40.057161
              ],
              [
                -83.511323,
                40.0474
              ],
              [
                -83.503714,
                40.111468
              ],
              [
                -83.494406,
                40.22717
              ],
              [
                -83.495045,
                40.253916
              ],
              [
                -83.525521,
                40.262524
              ],
              [
                -83.545567,
                40.280467
              ],
              [
                -83.534762,
                40.378897
              ],
              [
                -83.51452,
                40.386921
              ],
              [
                -83.482464,
                40.375899
              ],
              [
                -83.398953,
                40.3695
              ],
              [
                -83.395255,
                40.383776
              ],
              [
                -83.361419,
                40.377416
              ],
              [
                -83.350373,
                40.361622
              ],
              [
                -83.356668,
                40.33778
              ],
              [
                -83.352724,
                40.309548
              ],
              [
                -83.322269,
                40.294586
              ],
              [
                -83.279998,
                40.302774
              ],
              [
                -83.231778,
                40.332613
              ],
              [
                -83.206733,
                40.329716
              ],
              [
                -83.214872,
                40.355475
              ],
              [
                -83.249591,
                40.364305
              ],
              [
                -83.24884,
                40.419088
              ],
              [
                -83.237014,
                40.427341
              ],
              [
                -83.191993,
                40.425093
              ],
              [
                -83.191683,
                40.440542
              ],
              [
                -83.020798,
                40.433795
              ],
              [
                -83.016806,
                40.484263
              ]
            ],
            [
              [
                -82.755943,
                40.206813
              ],
              [
                -82.762755,
                40.088325
              ],
              [
                -82.753134,
                40.080469
              ],
              [
                -82.71031,
                40.086102
              ],
              [
                -82.693483,
                40.110814
              ],
              [
                -82.663343,
                40.121768
              ],
              [
                -82.626892,
                40.120523
              ],
              [
                -82.60849,
                40.169219
              ],
              [
                -82.626017,
                40.192792
              ],
              [
                -82.693218,
                40.195868
              ],
              [
                -82.692888,
                40.204353
              ],
              [
                -82.755943,
                40.206813
              ]
            ]
          ],
          [
            [
              [
                -83.02763,
                39.327952
              ],
              [
                -83.020428,
                39.349035
              ],
              [
                -82.994341,
                39.361417
              ],
              [
                -82.94384,
                39.339948
              ],
              [
                -82.966475,
                39.320847
              ],
              [
                -82.937539,
                39.283131
              ],
              [
                -82.966754,
                39.240525
              ],
              [
                -82.981909,
                39.248156
              ],
              [
                -83.00818,
                39.312095
              ],
              [
                -83.02763,
                39.327952
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "ESC Of Central Ohio",
        "teachers": 13297
      },
      "rdn": "us.oh.central-ohio-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.036069,
              40.040182
            ],
            [
              -83.69192,
              40.020059
            ],
            [
              -83.635206,
              40.032684
            ],
            [
              -83.602207,
              40.030743
            ],
            [
              -83.6041,
              40.01478
            ],
            [
              -83.509933,
              40.009911
            ],
            [
              -83.547375,
              39.903002
            ],
            [
              -83.588041,
              39.768778
            ],
            [
              -83.647169,
              39.773029
            ],
            [
              -83.717744,
              39.756745
            ],
            [
              -83.735362,
              39.776172
            ],
            [
              -83.767578,
              39.785129
            ],
            [
              -83.785988,
              39.82076
            ],
            [
              -83.941289,
              39.828838
            ],
            [
              -83.939838,
              39.843412
            ],
            [
              -84.053736,
              39.850458
            ],
            [
              -84.051039,
              39.879807
            ],
            [
              -84.062438,
              39.887927
            ],
            [
              -84.047377,
              39.923442
            ],
            [
              -84.036069,
              40.040182
            ]
          ]
        ]
      },
      "properties": {
        "name": "Clark County",
        "teachers": 1280
      },
      "rdn": "us.oh.clark-county-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.232544,
              39.264805
            ],
            [
              -84.201802,
              39.267167
            ],
            [
              -84.133658,
              39.262962
            ],
            [
              -84.142788,
              39.282836
            ],
            [
              -84.108724,
              39.295615
            ],
            [
              -84.087902,
              39.274777
            ],
            [
              -84.072728,
              39.283056
            ],
            [
              -84.046074,
              39.257468
            ],
            [
              -84.085624,
              39.242174
            ],
            [
              -84.093032,
              39.224611
            ],
            [
              -84.080136,
              39.211567
            ],
            [
              -84.025266,
              39.206957
            ],
            [
              -83.99748,
              39.19289
            ],
            [
              -84.008067,
              39.092067
            ],
            [
              -84.025441,
              38.985821
            ],
            [
              -84.052685,
              38.771375
            ],
            [
              -84.071491,
              38.770475
            ],
            [
              -84.155912,
              38.794909
            ],
            [
              -84.212904,
              38.805707
            ],
            [
              -84.230181,
              38.826547
            ],
            [
              -84.234453,
              38.893226
            ],
            [
              -84.288164,
              38.955789
            ],
            [
              -84.304698,
              39.006455
            ],
            [
              -84.319936,
              39.022081
            ],
            [
              -84.300047,
              39.175667
            ],
            [
              -84.276377,
              39.203873
            ],
            [
              -84.309356,
              39.208774
            ],
            [
              -84.307114,
              39.234413
            ],
            [
              -84.239426,
              39.222942
            ],
            [
              -84.217554,
              39.24753
            ],
            [
              -84.232544,
              39.264805
            ]
          ]
        ]
      },
      "properties": {
        "name": "Clermont County",
        "teachers": 1711
      },
      "rdn": "us.oh.clermont-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -80.519777,
                40.900324
              ],
              [
                -80.519054,
                40.783811
              ],
              [
                -80.556142,
                40.777774
              ],
              [
                -80.574075,
                40.790142
              ],
              [
                -80.573984,
                40.812857
              ],
              [
                -80.714609,
                40.813193
              ],
              [
                -80.727313,
                40.796617
              ],
              [
                -80.727527,
                40.767731
              ],
              [
                -80.70781,
                40.766598
              ],
              [
                -80.706572,
                40.73217
              ],
              [
                -80.746969,
                40.726529
              ],
              [
                -80.746519,
                40.7554
              ],
              [
                -80.815953,
                40.755831
              ],
              [
                -80.844276,
                40.72656
              ],
              [
                -80.917172,
                40.726934
              ],
              [
                -80.974818,
                40.727003
              ],
              [
                -80.974676,
                40.780917
              ],
              [
                -81.016665,
                40.778763
              ],
              [
                -81.017825,
                40.813772
              ],
              [
                -80.974598,
                40.813783
              ],
              [
                -80.934013,
                40.849883
              ],
              [
                -80.933724,
                40.871531
              ],
              [
                -80.896816,
                40.871466
              ],
              [
                -80.895813,
                40.930187
              ],
              [
                -80.821635,
                40.929775
              ],
              [
                -80.821923,
                40.900935
              ],
              [
                -80.783932,
                40.907978
              ],
              [
                -80.707065,
                40.900204
              ],
              [
                -80.710901,
                40.871213
              ],
              [
                -80.631966,
                40.86712
              ],
              [
                -80.632067,
                40.899782
              ],
              [
                -80.519777,
                40.900324
              ]
            ]
          ],
          [
            [
              [
                -80.667957,
                40.582496
              ],
              [
                -80.670705,
                40.626102
              ],
              [
                -80.689421,
                40.648043
              ],
              [
                -80.674812,
                40.655091
              ],
              [
                -80.632067,
                40.655009
              ],
              [
                -80.609776,
                40.669562
              ],
              [
                -80.593897,
                40.654307
              ],
              [
                -80.518998,
                40.654089
              ],
              [
                -80.518991,
                40.638801
              ],
              [
                -80.576736,
                40.614224
              ],
              [
                -80.598764,
                40.625263
              ],
              [
                -80.634355,
                40.616095
              ],
              [
                -80.667957,
                40.582496
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "Columbiana County",
        "teachers": 799
      },
      "rdn": "us.oh.columbiana"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.804075,
              40.343556
            ],
            [
              -84.775312,
              40.319971
            ],
            [
              -84.727731,
              40.323123
            ],
            [
              -84.718755,
              40.337555
            ],
            [
              -84.66041,
              40.331036
            ],
            [
              -84.648693,
              40.344658
            ],
            [
              -84.618674,
              40.337528
            ],
            [
              -84.539989,
              40.349273
            ],
            [
              -84.519306,
              40.31619
            ],
            [
              -84.519566,
              40.332889
            ],
            [
              -84.472639,
              40.329108
            ],
            [
              -84.440173,
              40.325244
            ],
            [
              -84.433959,
              40.270149
            ],
            [
              -84.452668,
              40.260109
            ],
            [
              -84.452094,
              40.240788
            ],
            [
              -84.43357,
              40.231689
            ],
            [
              -84.423142,
              40.206505
            ],
            [
              -84.432702,
              40.192714
            ],
            [
              -84.473081,
              40.170875
            ],
            [
              -84.473045,
              40.131395
            ],
            [
              -84.454459,
              40.123163
            ],
            [
              -84.453317,
              40.090057
            ],
            [
              -84.434895,
              40.085134
            ],
            [
              -84.438469,
              40.058033
            ],
            [
              -84.428731,
              40.021888
            ],
            [
              -84.426489,
              39.942559
            ],
            [
              -84.445416,
              39.926262
            ],
            [
              -84.505833,
              39.926244
            ],
            [
              -84.505486,
              39.918135
            ],
            [
              -84.596249,
              39.916743
            ],
            [
              -84.779181,
              39.916826
            ],
            [
              -84.81219,
              39.92758
            ],
            [
              -84.804075,
              40.343556
            ]
          ]
        ]
      },
      "properties": {
        "name": "Darke County",
        "teachers": 508
      },
      "rdn": "us.oh.darke-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -80.974818,
                40.727003
              ],
              [
                -80.917172,
                40.726934
              ],
              [
                -80.918125,
                40.643464
              ],
              [
                -80.860822,
                40.64257
              ],
              [
                -80.863219,
                40.564951
              ],
              [
                -80.880657,
                40.555674
              ],
              [
                -80.92092,
                40.556315
              ],
              [
                -80.923578,
                40.489807
              ],
              [
                -80.945585,
                40.490542
              ],
              [
                -80.941911,
                40.4247
              ],
              [
                -81.091253,
                40.428703
              ],
              [
                -81.139841,
                40.431753
              ],
              [
                -81.159666,
                40.394996
              ],
              [
                -81.160958,
                40.366938
              ],
              [
                -81.180162,
                40.36745
              ],
              [
                -81.198371,
                40.34643
              ],
              [
                -81.222501,
                40.33822
              ],
              [
                -81.273695,
                40.345465
              ],
              [
                -81.275319,
                40.303434
              ],
              [
                -81.334601,
                40.304316
              ],
              [
                -81.338057,
                40.214253
              ],
              [
                -81.505007,
                40.218545
              ],
              [
                -81.524969,
                40.213234
              ],
              [
                -81.526358,
                40.191904
              ],
              [
                -81.548144,
                40.165065
              ],
              [
                -81.622788,
                40.164856
              ],
              [
                -81.649428,
                40.15045
              ],
              [
                -81.670899,
                40.150976
              ],
              [
                -81.66759,
                40.222468
              ],
              [
                -81.636348,
                40.221615
              ],
              [
                -81.632934,
                40.262259
              ],
              [
                -81.649061,
                40.268448
              ],
              [
                -81.649107,
                40.295814
              ],
              [
                -81.637369,
                40.318682
              ],
              [
                -81.61802,
                40.330488
              ],
              [
                -81.614101,
                40.410133
              ],
              [
                -81.63482,
                40.41098
              ],
              [
                -81.645944,
                40.3848
              ],
              [
                -81.681446,
                40.369998
              ],
              [
                -81.712288,
                40.370804
              ],
              [
                -81.711383,
                40.392179
              ],
              [
                -81.744637,
                40.401305
              ],
              [
                -81.80483,
                40.395323
              ],
              [
                -81.804523,
                40.446796
              ],
              [
                -81.772033,
                40.454085
              ],
              [
                -81.755027,
                40.445641
              ],
              [
                -81.669654,
                40.443866
              ],
              [
                -81.662002,
                40.627788
              ],
              [
                -81.649199,
                40.635106
              ],
              [
                -81.605045,
                40.639733
              ],
              [
                -81.576969,
                40.622637
              ],
              [
                -81.578208,
                40.574624
              ],
              [
                -81.5498,
                40.550516
              ],
              [
                -81.512961,
                40.550305
              ],
              [
                -81.517147,
                40.578818
              ],
              [
                -81.502304,
                40.585923
              ],
              [
                -81.510522,
                40.607161
              ],
              [
                -81.51084,
                40.660374
              ],
              [
                -81.472131,
                40.679073
              ],
              [
                -81.442678,
                40.678649
              ],
              [
                -81.440009,
                40.71522
              ],
              [
                -81.396237,
                40.706595
              ],
              [
                -81.393239,
                40.652838
              ],
              [
                -81.41279,
                40.653096
              ],
              [
                -81.413338,
                40.615246
              ],
              [
                -81.319138,
                40.615479
              ],
              [
                -81.321659,
                40.566648
              ],
              [
                -81.268575,
                40.565158
              ],
              [
                -81.27127,
                40.584065
              ],
              [
                -81.253873,
                40.601745
              ],
              [
                -81.226147,
                40.601569
              ],
              [
                -81.214865,
                40.641622
              ],
              [
                -81.160231,
                40.648755
              ],
              [
                -81.089086,
                40.646915
              ],
              [
                -81.087954,
                40.697319
              ],
              [
                -81.051985,
                40.697204
              ],
              [
                -81.043399,
                40.727427
              ],
              [
                -80.974818,
                40.727003
              ]
            ]
          ],
          [
            [
              [
                -80.708023,
                40.143274
              ],
              [
                -80.710203,
                40.099572
              ],
              [
                -80.738604,
                40.075672
              ],
              [
                -80.730904,
                40.049172
              ],
              [
                -80.742045,
                40.005641
              ],
              [
                -80.740126,
                39.970793
              ],
              [
                -80.764511,
                39.946602
              ],
              [
                -80.755936,
                39.916554
              ],
              [
                -80.768272,
                39.909642
              ],
              [
                -80.807782,
                39.914481
              ],
              [
                -80.841758,
                39.923076
              ],
              [
                -80.885422,
                39.925113
              ],
              [
                -80.898353,
                39.939939
              ],
              [
                -81.010622,
                39.944931
              ],
              [
                -81.013444,
                39.909359
              ],
              [
                -81.059929,
                39.912016
              ],
              [
                -81.060073,
                39.89485
              ],
              [
                -81.104849,
                39.895046
              ],
              [
                -81.10595,
                39.86325
              ],
              [
                -81.237316,
                39.867828
              ],
              [
                -81.225924,
                40.170075
              ],
              [
                -81.112072,
                40.167917
              ],
              [
                -80.775429,
                40.155423
              ],
              [
                -80.758486,
                40.131362
              ],
              [
                -80.708023,
                40.143274
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "East Central Ohio",
        "teachers": 1562
      },
      "rdn": "us.oh.east-central-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -82.768805,
              39.939085
            ],
            [
              -82.697184,
              39.935267
            ],
            [
              -82.698926,
              39.913122
            ],
            [
              -82.684136,
              39.898996
            ],
            [
              -82.659876,
              39.896484
            ],
            [
              -82.65002,
              39.919169
            ],
            [
              -82.591421,
              39.930452
            ],
            [
              -82.469179,
              39.927231
            ],
            [
              -82.477008,
              39.888278
            ],
            [
              -82.467423,
              39.884718
            ],
            [
              -82.472402,
              39.837223
            ],
            [
              -82.396594,
              39.833057
            ],
            [
              -82.403303,
              39.752228
            ],
            [
              -82.365363,
              39.750173
            ],
            [
              -82.37168,
              39.68463
            ],
            [
              -82.358452,
              39.679509
            ],
            [
              -82.359833,
              39.654178
            ],
            [
              -82.490329,
              39.661704
            ],
            [
              -82.480977,
              39.632124
            ],
            [
              -82.496123,
              39.602849
            ],
            [
              -82.574249,
              39.606757
            ],
            [
              -82.577488,
              39.593772
            ],
            [
              -82.618327,
              39.59571
            ],
            [
              -82.620088,
              39.563987
            ],
            [
              -82.730272,
              39.569066
            ],
            [
              -82.731518,
              39.554445
            ],
            [
              -82.842953,
              39.56148
            ],
            [
              -82.834378,
              39.649445
            ],
            [
              -82.795334,
              39.666861
            ],
            [
              -82.782374,
              39.713629
            ],
            [
              -82.791491,
              39.734271
            ],
            [
              -82.752114,
              39.76168
            ],
            [
              -82.750178,
              39.77639
            ],
            [
              -82.793755,
              39.779273
            ],
            [
              -82.824199,
              39.795592
            ],
            [
              -82.821496,
              39.824271
            ],
            [
              -82.798015,
              39.822533
            ],
            [
              -82.79467,
              39.802383
            ],
            [
              -82.747548,
              39.819701
            ],
            [
              -82.76307,
              39.836303
            ],
            [
              -82.761525,
              39.857513
            ],
            [
              -82.792479,
              39.874087
            ],
            [
              -82.816565,
              39.877441
            ],
            [
              -82.811962,
              39.926491
            ],
            [
              -82.768805,
              39.939085
            ]
          ]
        ]
      },
      "properties": {
        "name": "Fairfield County",
        "teachers": 1399
      },
      "rdn": "us.oh.fairfield-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -82.806544,
              38.948045
            ],
            [
              -82.801361,
              39.005312
            ],
            [
              -82.773985,
              39.01564
            ],
            [
              -82.772386,
              39.032757
            ],
            [
              -82.79957,
              39.03563
            ],
            [
              -82.785891,
              39.168769
            ],
            [
              -82.766692,
              39.167768
            ],
            [
              -82.748591,
              39.368165
            ],
            [
              -82.635686,
              39.361566
            ],
            [
              -82.634386,
              39.375966
            ],
            [
              -82.51758,
              39.369468
            ],
            [
              -82.514479,
              39.395767
            ],
            [
              -82.289663,
              39.384195
            ],
            [
              -82.298476,
              39.294996
            ],
            [
              -82.260936,
              39.285514
            ],
            [
              -82.268583,
              39.20376
            ],
            [
              -82.306669,
              39.205494
            ],
            [
              -82.323894,
              39.013154
            ],
            [
              -82.095465,
              39.002778
            ],
            [
              -82.098873,
              38.958319
            ],
            [
              -82.111666,
              38.932579
            ],
            [
              -82.143167,
              38.898079
            ],
            [
              -82.139224,
              38.86502
            ],
            [
              -82.144867,
              38.84048
            ],
            [
              -82.16157,
              38.824632
            ],
            [
              -82.191172,
              38.815137
            ],
            [
              -82.217269,
              38.79568
            ],
            [
              -82.216614,
              38.76835
            ],
            [
              -82.198882,
              38.757725
            ],
            [
              -82.182467,
              38.708782
            ],
            [
              -82.190867,
              38.680383
            ],
            [
              -82.172066,
              38.625984
            ],
            [
              -82.188767,
              38.594984
            ],
            [
              -82.222168,
              38.591384
            ],
            [
              -82.245969,
              38.598483
            ],
            [
              -82.273401,
              38.59421
            ],
            [
              -82.304614,
              38.583342
            ],
            [
              -82.361674,
              38.585182
            ],
            [
              -82.354468,
              38.67607
            ],
            [
              -82.441812,
              38.680629
            ],
            [
              -82.427748,
              38.700464
            ],
            [
              -82.470179,
              38.706339
            ],
            [
              -82.466878,
              38.741478
            ],
            [
              -82.486579,
              38.742978
            ],
            [
              -82.483579,
              38.772278
            ],
            [
              -82.563058,
              38.777749
            ],
            [
              -82.582683,
              38.779077
            ],
            [
              -82.574383,
              38.853475
            ],
            [
              -82.465297,
              38.84566
            ],
            [
              -82.48239,
              38.863705
            ],
            [
              -82.480937,
              38.894043
            ],
            [
              -82.513667,
              38.912167
            ],
            [
              -82.512382,
              38.951512
            ],
            [
              -82.44366,
              38.947439
            ],
            [
              -82.435309,
              39.035079
            ],
            [
              -82.513258,
              39.040103
            ],
            [
              -82.512034,
              39.052301
            ],
            [
              -82.550509,
              39.055148
            ],
            [
              -82.554561,
              39.020843
            ],
            [
              -82.58225,
              39.022757
            ],
            [
              -82.578746,
              38.98624
            ],
            [
              -82.559149,
              38.983955
            ],
            [
              -82.566431,
              38.93231
            ],
            [
              -82.806544,
              38.948045
            ]
          ]
        ]
      },
      "properties": {
        "name": "Gallia-Vinton",
        "teachers": 757
      },
      "rdn": "us.oh.gallia-vinton-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -81.101422,
              41.641457
            ],
            [
              -81.102258,
              41.714307
            ],
            [
              -81.003631,
              41.715137
            ],
            [
              -81.003125,
              41.501685
            ],
            [
              -81.003319,
              41.34786
            ],
            [
              -81.19142,
              41.347569
            ],
            [
              -81.19057,
              41.425643
            ],
            [
              -81.297732,
              41.425093
            ],
            [
              -81.297821,
              41.439674
            ],
            [
              -81.366411,
              41.439042
            ],
            [
              -81.391518,
              41.445222
            ],
            [
              -81.382594,
              41.469244
            ],
            [
              -81.390866,
              41.499214
            ],
            [
              -81.391005,
              41.569716
            ],
            [
              -81.29588,
              41.56995
            ],
            [
              -81.274981,
              41.499707
            ],
            [
              -81.171683,
              41.50775
            ],
            [
              -81.161407,
              41.562089
            ],
            [
              -81.100546,
              41.571595
            ],
            [
              -81.101422,
              41.641457
            ]
          ]
        ]
      },
      "properties": {
        "name": "Geauga County",
        "teachers": 302
      },
      "rdn": "us.oh.geauga-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.053736,
              39.850458
            ],
            [
              -83.939838,
              39.843412
            ],
            [
              -83.941289,
              39.828838
            ],
            [
              -83.785988,
              39.82076
            ],
            [
              -83.767578,
              39.785129
            ],
            [
              -83.735362,
              39.776172
            ],
            [
              -83.717744,
              39.756745
            ],
            [
              -83.647169,
              39.773029
            ],
            [
              -83.651342,
              39.716883
            ],
            [
              -83.658598,
              39.664994
            ],
            [
              -83.638184,
              39.66337
            ],
            [
              -83.634776,
              39.641106
            ],
            [
              -83.661666,
              39.638285
            ],
            [
              -83.670196,
              39.550254
            ],
            [
              -83.704861,
              39.526904
            ],
            [
              -83.735869,
              39.561352
            ],
            [
              -83.788096,
              39.557533
            ],
            [
              -83.821883,
              39.564943
            ],
            [
              -83.843332,
              39.561065
            ],
            [
              -83.938946,
              39.567028
            ],
            [
              -83.979443,
              39.544116
            ],
            [
              -84.000112,
              39.568821
            ],
            [
              -84.097015,
              39.577137
            ],
            [
              -84.113333,
              39.588743
            ],
            [
              -84.111935,
              39.626359
            ],
            [
              -84.092938,
              39.838345
            ],
            [
              -84.055238,
              39.835958
            ],
            [
              -84.053736,
              39.850458
            ]
          ]
        ]
      },
      "properties": {
        "name": "Greene County",
        "teachers": 1315
      },
      "rdn": "us.oh.greene-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.819451,
              39.305153
            ],
            [
              -84.795648,
              39.321267
            ],
            [
              -84.760964,
              39.322968
            ],
            [
              -84.74971,
              39.304926
            ],
            [
              -84.641814,
              39.305044
            ],
            [
              -84.625717,
              39.319518
            ],
            [
              -84.598746,
              39.308556
            ],
            [
              -84.452667,
              39.301483
            ],
            [
              -84.369919,
              39.294299
            ],
            [
              -84.351972,
              39.306901
            ],
            [
              -84.343054,
              39.397814
            ],
            [
              -84.286453,
              39.394286
            ],
            [
              -84.267795,
              39.39982
            ],
            [
              -84.278969,
              39.341954
            ],
            [
              -84.304427,
              39.30743
            ],
            [
              -84.307268,
              39.29244
            ],
            [
              -84.262461,
              39.288379
            ],
            [
              -84.232544,
              39.264805
            ],
            [
              -84.217554,
              39.24753
            ],
            [
              -84.239426,
              39.222942
            ],
            [
              -84.307114,
              39.234413
            ],
            [
              -84.309356,
              39.208774
            ],
            [
              -84.276377,
              39.203873
            ],
            [
              -84.300047,
              39.175667
            ],
            [
              -84.319936,
              39.022081
            ],
            [
              -84.346039,
              39.036963
            ],
            [
              -84.406941,
              39.045662
            ],
            [
              -84.429841,
              39.058262
            ],
            [
              -84.432841,
              39.094261
            ],
            [
              -84.445242,
              39.114461
            ],
            [
              -84.470542,
              39.12146
            ],
            [
              -84.509743,
              39.09366
            ],
            [
              -84.550844,
              39.09936
            ],
            [
              -84.572144,
              39.08206
            ],
            [
              -84.601682,
              39.074115
            ],
            [
              -84.632446,
              39.07676
            ],
            [
              -84.650346,
              39.09166
            ],
            [
              -84.689747,
              39.104159
            ],
            [
              -84.718548,
              39.137059
            ],
            [
              -84.754449,
              39.146658
            ],
            [
              -84.783991,
              39.11806
            ],
            [
              -84.820157,
              39.10548
            ],
            [
              -84.819451,
              39.305153
            ]
          ]
        ]
      },
      "properties": {
        "name": "Hamilton County",
        "teachers": 7577
      },
      "rdn": "us.oh.hamilton-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.478615,
              41.166054
            ],
            [
              -83.438501,
              41.166639
            ],
            [
              -83.431134,
              41.15031
            ],
            [
              -83.421232,
              41.150458
            ],
            [
              -83.420786,
              41.057869
            ],
            [
              -83.407555,
              41.057854
            ],
            [
              -83.402498,
              40.992072
            ],
            [
              -83.446314,
              40.987285
            ],
            [
              -83.456875,
              40.954322
            ],
            [
              -83.456888,
              40.926604
            ],
            [
              -83.410543,
              40.905042
            ],
            [
              -83.410393,
              40.817642
            ],
            [
              -83.42976,
              40.804609
            ],
            [
              -83.414796,
              40.764087
            ],
            [
              -83.477759,
              40.730106
            ],
            [
              -83.495832,
              40.708815
            ],
            [
              -83.571222,
              40.709795
            ],
            [
              -83.563749,
              40.730705
            ],
            [
              -83.563993,
              40.780787
            ],
            [
              -83.580704,
              40.783729
            ],
            [
              -83.580716,
              40.818926
            ],
            [
              -83.689575,
              40.819496
            ],
            [
              -83.689307,
              40.848512
            ],
            [
              -83.727672,
              40.848451
            ],
            [
              -83.727869,
              40.819342
            ],
            [
              -83.774815,
              40.819328
            ],
            [
              -83.79501,
              40.841565
            ],
            [
              -83.871059,
              40.841399
            ],
            [
              -83.88026,
              40.863482
            ],
            [
              -83.880423,
              40.920429
            ],
            [
              -83.881117,
              41.153136
            ],
            [
              -83.881539,
              41.193615
            ],
            [
              -83.861745,
              41.226313
            ],
            [
              -83.862319,
              41.255203
            ],
            [
              -83.77523,
              41.255525
            ],
            [
              -83.765858,
              41.244013
            ],
            [
              -83.765123,
              41.168345
            ],
            [
              -83.581865,
              41.167174
            ],
            [
              -83.573331,
              41.137888
            ],
            [
              -83.545063,
              41.137649
            ],
            [
              -83.535084,
              41.166196
            ],
            [
              -83.478615,
              41.166054
            ]
          ]
        ]
      },
      "properties": {
        "name": "Hancock",
        "teachers": 674
      },
      "rdn": "us.oh.hancock-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -80.667957,
              40.582496
            ],
            [
              -80.627507,
              40.535793
            ],
            [
              -80.618003,
              40.502049
            ],
            [
              -80.599194,
              40.482566
            ],
            [
              -80.598294,
              40.458366
            ],
            [
              -80.611195,
              40.437767
            ],
            [
              -80.611795,
              40.403867
            ],
            [
              -80.628096,
              40.395867
            ],
            [
              -80.607595,
              40.369568
            ],
            [
              -80.612796,
              40.347668
            ],
            [
              -80.599895,
              40.317669
            ],
            [
              -80.622497,
              40.26177
            ],
            [
              -80.644598,
              40.25127
            ],
            [
              -80.661543,
              40.229798
            ],
            [
              -80.6681,
              40.199671
            ],
            [
              -80.686137,
              40.181607
            ],
            [
              -80.708023,
              40.143274
            ],
            [
              -80.758486,
              40.131362
            ],
            [
              -80.775429,
              40.155423
            ],
            [
              -81.112072,
              40.167917
            ],
            [
              -81.225924,
              40.170075
            ],
            [
              -81.339565,
              40.171999
            ],
            [
              -81.338057,
              40.214253
            ],
            [
              -81.334601,
              40.304316
            ],
            [
              -81.275319,
              40.303434
            ],
            [
              -81.273695,
              40.345465
            ],
            [
              -81.222501,
              40.33822
            ],
            [
              -81.198371,
              40.34643
            ],
            [
              -81.180162,
              40.36745
            ],
            [
              -81.160958,
              40.366938
            ],
            [
              -81.159666,
              40.394996
            ],
            [
              -81.139841,
              40.431753
            ],
            [
              -81.091253,
              40.428703
            ],
            [
              -80.941911,
              40.4247
            ],
            [
              -80.945585,
              40.490542
            ],
            [
              -80.923578,
              40.489807
            ],
            [
              -80.92092,
              40.556315
            ],
            [
              -80.880657,
              40.555674
            ],
            [
              -80.863219,
              40.564951
            ],
            [
              -80.860822,
              40.64257
            ],
            [
              -80.918125,
              40.643464
            ],
            [
              -80.917172,
              40.726934
            ],
            [
              -80.844276,
              40.72656
            ],
            [
              -80.815953,
              40.755831
            ],
            [
              -80.746519,
              40.7554
            ],
            [
              -80.746969,
              40.726529
            ],
            [
              -80.746578,
              40.662836
            ],
            [
              -80.683238,
              40.662483
            ],
            [
              -80.674812,
              40.655091
            ],
            [
              -80.689421,
              40.648043
            ],
            [
              -80.670705,
              40.626102
            ],
            [
              -80.667957,
              40.582496
            ]
          ]
        ]
      },
      "properties": {
        "name": "Jefferson County",
        "teachers": 665
      },
      "rdn": "us.oh.jefferson-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -82.384556,
              40.638504
            ],
            [
              -82.338483,
              40.638502
            ],
            [
              -82.336962,
              40.555001
            ],
            [
              -82.323347,
              40.52604
            ],
            [
              -82.262983,
              40.53454
            ],
            [
              -82.234622,
              40.566472
            ],
            [
              -82.179069,
              40.573112
            ],
            [
              -82.18166,
              40.512297
            ],
            [
              -82.141716,
              40.491988
            ],
            [
              -82.142074,
              40.46976
            ],
            [
              -82.162103,
              40.455377
            ],
            [
              -82.184607,
              40.45628
            ],
            [
              -82.187364,
              40.397799
            ],
            [
              -82.157311,
              40.401994
            ],
            [
              -82.160718,
              40.382267
            ],
            [
              -82.188184,
              40.383355
            ],
            [
              -82.195911,
              40.239071
            ],
            [
              -82.233448,
              40.236245
            ],
            [
              -82.235744,
              40.224427
            ],
            [
              -82.281449,
              40.200114
            ],
            [
              -82.301272,
              40.207037
            ],
            [
              -82.302868,
              40.272321
            ],
            [
              -82.320398,
              40.305465
            ],
            [
              -82.368433,
              40.308618
            ],
            [
              -82.375423,
              40.315321
            ],
            [
              -82.472307,
              40.318865
            ],
            [
              -82.481245,
              40.295136
            ],
            [
              -82.552561,
              40.298272
            ],
            [
              -82.570855,
              40.317816
            ],
            [
              -82.592042,
              40.293913
            ],
            [
              -82.59576,
              40.257498
            ],
            [
              -82.628165,
              40.255974
            ],
            [
              -82.689818,
              40.264439
            ],
            [
              -82.68918,
              40.274489
            ],
            [
              -82.778198,
              40.278053
            ],
            [
              -82.771661,
              40.314434
            ],
            [
              -82.794265,
              40.333008
            ],
            [
              -82.76881,
              40.350649
            ],
            [
              -82.646503,
              40.345076
            ],
            [
              -82.633181,
              40.523066
            ],
            [
              -82.642796,
              40.550011
            ],
            [
              -82.604635,
              40.557704
            ],
            [
              -82.607369,
              40.633329
            ],
            [
              -82.551189,
              40.635923
            ],
            [
              -82.536936,
              40.665289
            ],
            [
              -82.491984,
              40.665703
            ],
            [
              -82.454464,
              40.676541
            ],
            [
              -82.453325,
              40.638039
            ],
            [
              -82.384556,
              40.638504
            ]
          ]
        ]
      },
      "properties": {
        "name": "Knox County",
        "teachers": 608
      },
      "rdn": "us.oh.knox-county-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -81.002176,
                41.853157
              ],
              [
                -81.003631,
                41.715137
              ],
              [
                -81.102258,
                41.714307
              ],
              [
                -81.101422,
                41.641457
              ],
              [
                -81.241529,
                41.641115
              ],
              [
                -81.261207,
                41.626513
              ],
              [
                -81.275197,
                41.647099
              ],
              [
                -81.274479,
                41.681208
              ],
              [
                -81.293126,
                41.683845
              ],
              [
                -81.29083,
                41.757667
              ],
              [
                -81.227521,
                41.767744
              ],
              [
                -81.112885,
                41.817571
              ],
              [
                -81.002176,
                41.853157
              ]
            ]
          ],
          [
            [
              [
                -81.29588,
                41.56995
              ],
              [
                -81.391005,
                41.569716
              ],
              [
                -81.487842,
                41.570051
              ],
              [
                -81.48864,
                41.631348
              ],
              [
                -81.441946,
                41.674081
              ],
              [
                -81.388898,
                41.70697
              ],
              [
                -81.390954,
                41.648817
              ],
              [
                -81.37403,
                41.640201
              ],
              [
                -81.294669,
                41.640889
              ],
              [
                -81.29588,
                41.56995
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "Lake County",
        "teachers": 1369
      },
      "rdn": "us.oh.lake-county-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.781446,
              41.723111
            ],
            [
              -83.453832,
              41.732647
            ],
            [
              -83.477073,
              41.698485
            ],
            [
              -83.46675,
              41.691267
            ],
            [
              -83.428017,
              41.712107
            ],
            [
              -83.423026,
              41.689392
            ],
            [
              -83.352435,
              41.687439
            ],
            [
              -83.335609,
              41.706022
            ],
            [
              -83.214761,
              41.638348
            ],
            [
              -83.165713,
              41.623246
            ],
            [
              -83.415919,
              41.618938
            ],
            [
              -83.542989,
              41.617904
            ],
            [
              -83.527572,
              41.601856
            ],
            [
              -83.528894,
              41.487501
            ],
            [
              -83.567666,
              41.484265
            ],
            [
              -83.592558,
              41.499923
            ],
            [
              -83.646117,
              41.474905
            ],
            [
              -83.660822,
              41.499264
            ],
            [
              -83.712843,
              41.492494
            ],
            [
              -83.754529,
              41.462824
            ],
            [
              -83.814143,
              41.454469
            ],
            [
              -83.826116,
              41.473667
            ],
            [
              -83.882897,
              41.478841
            ],
            [
              -83.882696,
              41.545295
            ],
            [
              -83.805634,
              41.567348
            ],
            [
              -83.807004,
              41.657914
            ],
            [
              -83.769018,
              41.660988
            ],
            [
              -83.780683,
              41.68721
            ],
            [
              -83.781446,
              41.723111
            ]
          ]
        ]
      },
      "properties": {
        "name": "ESC of Lake Erie West",
        "teachers": 3826
      },
      "rdn": "us.oh.lakeeriewest"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -82.816012,
              38.570733
            ],
            [
              -82.740383,
              38.597167
            ],
            [
              -82.766188,
              38.644678
            ],
            [
              -82.763895,
              38.679974
            ],
            [
              -82.706383,
              38.677485
            ],
            [
              -82.695387,
              38.749977
            ],
            [
              -82.662886,
              38.747977
            ],
            [
              -82.661386,
              38.762477
            ],
            [
              -82.585133,
              38.75687
            ],
            [
              -82.563058,
              38.777749
            ],
            [
              -82.483579,
              38.772278
            ],
            [
              -82.486579,
              38.742978
            ],
            [
              -82.466878,
              38.741478
            ],
            [
              -82.470179,
              38.706339
            ],
            [
              -82.427748,
              38.700464
            ],
            [
              -82.441812,
              38.680629
            ],
            [
              -82.354468,
              38.67607
            ],
            [
              -82.361674,
              38.585182
            ],
            [
              -82.304614,
              38.583342
            ],
            [
              -82.273401,
              38.59421
            ],
            [
              -82.291271,
              38.578983
            ],
            [
              -82.304223,
              38.496308
            ],
            [
              -82.323999,
              38.449268
            ],
            [
              -82.34064,
              38.440948
            ],
            [
              -82.389746,
              38.434355
            ],
            [
              -82.404882,
              38.439347
            ],
            [
              -82.529579,
              38.405182
            ],
            [
              -82.571613,
              38.405138
            ],
            [
              -82.593482,
              38.418252
            ],
            [
              -82.613802,
              38.474529
            ],
            [
              -82.657051,
              38.496816
            ],
            [
              -82.689965,
              38.53592
            ],
            [
              -82.724846,
              38.5576
            ],
            [
              -82.779472,
              38.559023
            ],
            [
              -82.816012,
              38.570733
            ]
          ]
        ]
      },
      "properties": {
        "name": "Lawrence County",
        "teachers": 630
      },
      "rdn": "us.oh.lawrence-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -82.481245,
                40.295136
              ],
              [
                -82.472307,
                40.318865
              ],
              [
                -82.375423,
                40.315321
              ],
              [
                -82.368433,
                40.308618
              ],
              [
                -82.320398,
                40.305465
              ],
              [
                -82.302868,
                40.272321
              ],
              [
                -82.301272,
                40.207037
              ],
              [
                -82.281449,
                40.200114
              ],
              [
                -82.281727,
                40.16984
              ],
              [
                -82.187105,
                40.16688
              ],
              [
                -82.19534,
                40.005967
              ],
              [
                -82.24428,
                39.988293
              ],
              [
                -82.292003,
                39.989105
              ],
              [
                -82.294499,
                39.95348
              ],
              [
                -82.310031,
                39.940557
              ],
              [
                -82.345977,
                39.932417
              ],
              [
                -82.408617,
                39.93234
              ],
              [
                -82.469179,
                39.927231
              ],
              [
                -82.591421,
                39.930452
              ],
              [
                -82.65002,
                39.919169
              ],
              [
                -82.659876,
                39.896484
              ],
              [
                -82.684136,
                39.898996
              ],
              [
                -82.698926,
                39.913122
              ],
              [
                -82.697184,
                39.935267
              ],
              [
                -82.768805,
                39.939085
              ],
              [
                -82.761313,
                39.965709
              ],
              [
                -82.715137,
                39.973455
              ],
              [
                -82.697257,
                39.991948
              ],
              [
                -82.681668,
                40.023988
              ],
              [
                -82.679852,
                40.046528
              ],
              [
                -82.650805,
                40.050686
              ],
              [
                -82.645726,
                40.035687
              ],
              [
                -82.573903,
                40.041625
              ],
              [
                -82.561787,
                40.191399
              ],
              [
                -82.467364,
                40.187073
              ],
              [
                -82.462763,
                40.245152
              ],
              [
                -82.491184,
                40.265396
              ],
              [
                -82.481245,
                40.295136
              ]
            ]
          ],
          [
            [
              [
                -82.755943,
                40.206813
              ],
              [
                -82.692888,
                40.204353
              ],
              [
                -82.693218,
                40.195868
              ],
              [
                -82.626017,
                40.192792
              ],
              [
                -82.60849,
                40.169219
              ],
              [
                -82.626892,
                40.120523
              ],
              [
                -82.663343,
                40.121768
              ],
              [
                -82.693483,
                40.110814
              ],
              [
                -82.71031,
                40.086102
              ],
              [
                -82.753134,
                40.080469
              ],
              [
                -82.762755,
                40.088325
              ],
              [
                -82.755943,
                40.206813
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "Licking County",
        "teachers": 1443
      },
      "rdn": "us.oh.licking-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -81.968277,
              41.505265
            ],
            [
              -81.971313,
              41.349102
            ],
            [
              -81.937585,
              41.337708
            ],
            [
              -81.912403,
              41.350832
            ],
            [
              -81.87696,
              41.350685
            ],
            [
              -81.87801,
              41.283013
            ],
            [
              -81.897813,
              41.275917
            ],
            [
              -81.972485,
              41.274829
            ],
            [
              -81.973895,
              41.199831
            ],
            [
              -82.072465,
              41.19985
            ],
            [
              -82.074266,
              41.136456
            ],
            [
              -82.269235,
              41.137678
            ],
            [
              -82.270217,
              41.079315
            ],
            [
              -82.296953,
              41.091798
            ],
            [
              -82.296053,
              41.129251
            ],
            [
              -82.330596,
              41.129627
            ],
            [
              -82.369389,
              41.150562
            ],
            [
              -82.37006,
              41.188617
            ],
            [
              -82.344086,
              41.199152
            ],
            [
              -82.342314,
              41.283554
            ],
            [
              -82.435716,
              41.283891
            ],
            [
              -82.443889,
              41.357248
            ],
            [
              -82.345202,
              41.35743
            ],
            [
              -82.34627,
              41.382943
            ],
            [
              -82.321711,
              41.382512
            ],
            [
              -82.344158,
              41.4134
            ],
            [
              -82.295568,
              41.419601
            ],
            [
              -82.273446,
              41.408198
            ],
            [
              -82.251778,
              41.418242
            ],
            [
              -82.252499,
              41.435742
            ],
            [
              -82.177905,
              41.471587
            ],
            [
              -82.11788,
              41.489524
            ],
            [
              -82.011966,
              41.515639
            ],
            [
              -81.968277,
              41.505265
            ]
          ]
        ]
      },
      "properties": {
        "name": "ESC Of Lorain County",
        "teachers": 2531
      },
      "rdn": "us.oh.loraincounty"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.036069,
              40.040182
            ],
            [
              -84.022919,
              40.183945
            ],
            [
              -84.014763,
              40.273459
            ],
            [
              -83.841394,
              40.263288
            ],
            [
              -83.830324,
              40.299743
            ],
            [
              -83.779379,
              40.303857
            ],
            [
              -83.770512,
              40.29111
            ],
            [
              -83.713299,
              40.302746
            ],
            [
              -83.708017,
              40.283133
            ],
            [
              -83.66518,
              40.280412
            ],
            [
              -83.676717,
              40.264395
            ],
            [
              -83.668757,
              40.245261
            ],
            [
              -83.633335,
              40.257012
            ],
            [
              -83.630254,
              40.2349
            ],
            [
              -83.573864,
              40.25769
            ],
            [
              -83.548306,
              40.256012
            ],
            [
              -83.545567,
              40.280467
            ],
            [
              -83.525521,
              40.262524
            ],
            [
              -83.495045,
              40.253916
            ],
            [
              -83.494406,
              40.22717
            ],
            [
              -83.503714,
              40.111468
            ],
            [
              -83.511323,
              40.0474
            ],
            [
              -83.394943,
              40.057161
            ],
            [
              -83.384356,
              40.089611
            ],
            [
              -83.350583,
              40.110004
            ],
            [
              -83.291621,
              40.109363
            ],
            [
              -83.294879,
              40.161496
            ],
            [
              -83.27197,
              40.172387
            ],
            [
              -83.255345,
              40.154757
            ],
            [
              -83.208023,
              40.1359
            ],
            [
              -83.187269,
              40.114627
            ],
            [
              -83.207884,
              40.092816
            ],
            [
              -83.212196,
              40.048069
            ],
            [
              -83.25383,
              40.049218
            ],
            [
              -83.25168,
              40.008376
            ],
            [
              -83.260877,
              40.002807
            ],
            [
              -83.241699,
              39.952552
            ],
            [
              -83.225643,
              39.932262
            ],
            [
              -83.252031,
              39.917363
            ],
            [
              -83.247301,
              39.860777
            ],
            [
              -83.22464,
              39.851021
            ],
            [
              -83.227398,
              39.823156
            ],
            [
              -83.200943,
              39.810656
            ],
            [
              -83.243702,
              39.812503
            ],
            [
              -83.252435,
              39.695438
            ],
            [
              -83.460601,
              39.70504
            ],
            [
              -83.651342,
              39.716883
            ],
            [
              -83.647169,
              39.773029
            ],
            [
              -83.588041,
              39.768778
            ],
            [
              -83.547375,
              39.903002
            ],
            [
              -83.509933,
              40.009911
            ],
            [
              -83.6041,
              40.01478
            ],
            [
              -83.602207,
              40.030743
            ],
            [
              -83.635206,
              40.032684
            ],
            [
              -83.69192,
              40.020059
            ],
            [
              -84.036069,
              40.040182
            ]
          ]
        ]
      },
      "properties": {
        "name": "Madison-Champaign",
        "teachers": 850
      },
      "rdn": "us.oh.madison-champaign-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -81.00288,
                41.271842
              ],
              [
                -81.002758,
                41.229527
              ],
              [
                -81.048871,
                41.230626
              ],
              [
                -81.098733,
                41.22533
              ],
              [
                -81.098645,
                41.272461
              ],
              [
                -81.00288,
                41.271842
              ]
            ]
          ],
          [
            [
              [
                -81.301237,
                41.064874
              ],
              [
                -81.300256,
                41.134137
              ],
              [
                -81.254369,
                41.138004
              ],
              [
                -81.193268,
                41.134058
              ],
              [
                -81.192656,
                41.177448
              ],
              [
                -81.188822,
                41.166955
              ],
              [
                -81.098276,
                41.162502
              ],
              [
                -81.002572,
                41.192746
              ],
              [
                -81.00229,
                41.134189
              ],
              [
                -80.973416,
                41.14913
              ],
              [
                -80.953892,
                41.134213
              ],
              [
                -80.783716,
                41.134156
              ],
              [
                -80.758034,
                41.127785
              ],
              [
                -80.723729,
                41.133955
              ],
              [
                -80.519167,
                41.133388
              ],
              [
                -80.519777,
                40.900324
              ],
              [
                -80.632067,
                40.899782
              ],
              [
                -80.631966,
                40.86712
              ],
              [
                -80.710901,
                40.871213
              ],
              [
                -80.707065,
                40.900204
              ],
              [
                -80.783932,
                40.907978
              ],
              [
                -80.821923,
                40.900935
              ],
              [
                -80.821635,
                40.929775
              ],
              [
                -80.895813,
                40.930187
              ],
              [
                -80.896816,
                40.871466
              ],
              [
                -80.933724,
                40.871531
              ],
              [
                -80.934013,
                40.849883
              ],
              [
                -80.974598,
                40.813783
              ],
              [
                -81.017825,
                40.813772
              ],
              [
                -81.086757,
                40.813936
              ],
              [
                -81.086723,
                40.887004
              ],
              [
                -81.049694,
                40.89639
              ],
              [
                -81.065006,
                40.92076
              ],
              [
                -81.086726,
                40.930126
              ],
              [
                -81.086312,
                40.988031
              ],
              [
                -81.09882,
                40.988074
              ],
              [
                -81.098634,
                41.032922
              ],
              [
                -81.085897,
                41.052719
              ],
              [
                -81.099016,
                41.062462
              ],
              [
                -81.301237,
                41.064874
              ]
            ]
          ],
          [
            [
              [
                -80.746969,
                40.726529
              ],
              [
                -80.706572,
                40.73217
              ],
              [
                -80.70781,
                40.766598
              ],
              [
                -80.727527,
                40.767731
              ],
              [
                -80.727313,
                40.796617
              ],
              [
                -80.714609,
                40.813193
              ],
              [
                -80.573984,
                40.812857
              ],
              [
                -80.574075,
                40.790142
              ],
              [
                -80.556142,
                40.777774
              ],
              [
                -80.519054,
                40.783811
              ],
              [
                -80.518998,
                40.654089
              ],
              [
                -80.593897,
                40.654307
              ],
              [
                -80.609776,
                40.669562
              ],
              [
                -80.632067,
                40.655009
              ],
              [
                -80.674812,
                40.655091
              ],
              [
                -80.683238,
                40.662483
              ],
              [
                -80.746578,
                40.662836
              ],
              [
                -80.746969,
                40.726529
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "Mahoning County",
        "teachers": 2468
      },
      "rdn": "us.oh.mahoning-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -81.687051,
              41.127962
            ],
            [
              -81.688491,
              40.98859
            ],
            [
              -81.805484,
              40.988253
            ],
            [
              -81.851153,
              40.992781
            ],
            [
              -81.866522,
              41.009865
            ],
            [
              -81.889565,
              40.99511
            ],
            [
              -81.996376,
              40.995852
            ],
            [
              -82.035962,
              41.000601
            ],
            [
              -82.078627,
              40.991153
            ],
            [
              -82.129334,
              40.991807
            ],
            [
              -82.127917,
              40.937169
            ],
            [
              -82.167737,
              40.930354
            ],
            [
              -82.169887,
              40.959124
            ],
            [
              -82.184726,
              40.981497
            ],
            [
              -82.207336,
              40.979412
            ],
            [
              -82.234577,
              40.992456
            ],
            [
              -82.271948,
              40.992817
            ],
            [
              -82.270217,
              41.079315
            ],
            [
              -82.269235,
              41.137678
            ],
            [
              -82.074266,
              41.136456
            ],
            [
              -82.072465,
              41.19985
            ],
            [
              -81.973895,
              41.199831
            ],
            [
              -81.972485,
              41.274829
            ],
            [
              -81.897813,
              41.275917
            ],
            [
              -81.68495,
              41.277146
            ],
            [
              -81.687051,
              41.127962
            ]
          ]
        ]
      },
      "properties": {
        "name": "ESC of Medina County",
        "teachers": 1606
      },
      "rdn": "us.oh.medina"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.802474,
              40.771652
            ],
            [
              -84.727327,
              40.771555
            ],
            [
              -84.727319,
              40.786091
            ],
            [
              -84.688879,
              40.786001
            ],
            [
              -84.689163,
              40.727705
            ],
            [
              -84.456172,
              40.728306
            ],
            [
              -84.456007,
              40.658737
            ],
            [
              -84.455152,
              40.48164
            ],
            [
              -84.469516,
              40.480196
            ],
            [
              -84.474195,
              40.444995
            ],
            [
              -84.43861,
              40.440904
            ],
            [
              -84.426535,
              40.429383
            ],
            [
              -84.43385,
              40.402425
            ],
            [
              -84.455386,
              40.402423
            ],
            [
              -84.454821,
              40.354198
            ],
            [
              -84.472639,
              40.329108
            ],
            [
              -84.519566,
              40.332889
            ],
            [
              -84.519306,
              40.31619
            ],
            [
              -84.539989,
              40.349273
            ],
            [
              -84.618674,
              40.337528
            ],
            [
              -84.648693,
              40.344658
            ],
            [
              -84.66041,
              40.331036
            ],
            [
              -84.718755,
              40.337555
            ],
            [
              -84.727731,
              40.323123
            ],
            [
              -84.775312,
              40.319971
            ],
            [
              -84.804075,
              40.343556
            ],
            [
              -84.802547,
              40.50181
            ],
            [
              -84.802474,
              40.771652
            ]
          ]
        ]
      },
      "properties": {
        "name": "Mercer County",
        "teachers": 529
      },
      "rdn": "us.oh.mercer-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.423142,
              40.206505
            ],
            [
              -84.408316,
              40.197401
            ],
            [
              -84.218658,
              40.200004
            ],
            [
              -84.219137,
              40.195499
            ],
            [
              -84.022919,
              40.183945
            ],
            [
              -84.036069,
              40.040182
            ],
            [
              -84.047377,
              39.923442
            ],
            [
              -84.062438,
              39.887927
            ],
            [
              -84.051039,
              39.879807
            ],
            [
              -84.157223,
              39.88563
            ],
            [
              -84.167065,
              39.912197
            ],
            [
              -84.157671,
              39.92297
            ],
            [
              -84.347418,
              39.920784
            ],
            [
              -84.395913,
              39.927407
            ],
            [
              -84.426489,
              39.942559
            ],
            [
              -84.428731,
              40.021888
            ],
            [
              -84.438469,
              40.058033
            ],
            [
              -84.434895,
              40.085134
            ],
            [
              -84.453317,
              40.090057
            ],
            [
              -84.454459,
              40.123163
            ],
            [
              -84.473045,
              40.131395
            ],
            [
              -84.473081,
              40.170875
            ],
            [
              -84.432702,
              40.192714
            ],
            [
              -84.423142,
              40.206505
            ]
          ]
        ]
      },
      "properties": {
        "name": "Miami County",
        "teachers": 999
      },
      "rdn": "us.oh.miami-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -83.073579,
                41.080788
              ],
              [
                -82.960705,
                41.081094
              ],
              [
                -82.958694,
                40.993668
              ],
              [
                -82.862333,
                40.993672
              ],
              [
                -82.829617,
                40.999622
              ],
              [
                -82.820318,
                40.996476
              ],
              [
                -82.688134,
                40.995416
              ],
              [
                -82.673108,
                41.010934
              ],
              [
                -82.643896,
                41.011758
              ],
              [
                -82.630592,
                40.99464
              ],
              [
                -82.581315,
                40.994187
              ],
              [
                -82.572353,
                40.986187
              ],
              [
                -82.496079,
                40.983458
              ],
              [
                -82.449744,
                40.985324
              ],
              [
                -82.432852,
                40.992944
              ],
              [
                -82.333304,
                40.992926
              ],
              [
                -82.330172,
                40.959694
              ],
              [
                -82.339686,
                40.940322
              ],
              [
                -82.339435,
                40.901925
              ],
              [
                -82.416,
                40.901898
              ],
              [
                -82.416847,
                40.814567
              ],
              [
                -82.398934,
                40.792702
              ],
              [
                -82.378953,
                40.787227
              ],
              [
                -82.378402,
                40.734219
              ],
              [
                -82.325387,
                40.726547
              ],
              [
                -82.338984,
                40.698538
              ],
              [
                -82.338739,
                40.669914
              ],
              [
                -82.386379,
                40.664166
              ],
              [
                -82.384556,
                40.638504
              ],
              [
                -82.453325,
                40.638039
              ],
              [
                -82.454464,
                40.676541
              ],
              [
                -82.491984,
                40.665703
              ],
              [
                -82.536936,
                40.665289
              ],
              [
                -82.551189,
                40.635923
              ],
              [
                -82.607369,
                40.633329
              ],
              [
                -82.604635,
                40.557704
              ],
              [
                -82.642796,
                40.550011
              ],
              [
                -82.633181,
                40.523066
              ],
              [
                -82.646503,
                40.345076
              ],
              [
                -82.76881,
                40.350649
              ],
              [
                -82.794265,
                40.333008
              ],
              [
                -82.824125,
                40.353205
              ],
              [
                -82.868606,
                40.355236
              ],
              [
                -82.869382,
                40.427508
              ],
              [
                -82.898234,
                40.428801
              ],
              [
                -82.911074,
                40.444198
              ],
              [
                -82.989879,
                40.448776
              ],
              [
                -83.016806,
                40.484263
              ],
              [
                -82.995743,
                40.491664
              ],
              [
                -82.995599,
                40.520026
              ],
              [
                -83.00971,
                40.54329
              ],
              [
                -82.967368,
                40.550561
              ],
              [
                -82.897672,
                40.550174
              ],
              [
                -82.850972,
                40.512548
              ],
              [
                -82.836356,
                40.51061
              ],
              [
                -82.811349,
                40.476503
              ],
              [
                -82.773186,
                40.470354
              ],
              [
                -82.76617,
                40.506851
              ],
              [
                -82.731588,
                40.513043
              ],
              [
                -82.729106,
                40.575867
              ],
              [
                -82.747728,
                40.595288
              ],
              [
                -82.804558,
                40.60324
              ],
              [
                -82.860905,
                40.603487
              ],
              [
                -82.857518,
                40.756043
              ],
              [
                -82.799426,
                40.755894
              ],
              [
                -82.765434,
                40.761443
              ],
              [
                -82.763577,
                40.843317
              ],
              [
                -82.812838,
                40.850519
              ],
              [
                -82.822485,
                40.90795
              ],
              [
                -82.978334,
                40.906683
              ],
              [
                -82.977914,
                40.986605
              ],
              [
                -82.973232,
                40.993669
              ],
              [
                -83.07421,
                40.993616
              ],
              [
                -83.073579,
                41.080788
              ]
            ]
          ],
          [
            [
              [
                -83.188892,
                41.167659
              ],
              [
                -83.103101,
                41.167811
              ],
              [
                -83.102895,
                41.182351
              ],
              [
                -83.074119,
                41.197067
              ],
              [
                -83.069841,
                41.169073
              ],
              [
                -83.074272,
                41.166597
              ],
              [
                -83.073579,
                41.080788
              ],
              [
                -83.189753,
                41.080294
              ],
              [
                -83.18991,
                41.098714
              ],
              [
                -83.21091,
                41.113184
              ],
              [
                -83.189671,
                41.138536
              ],
              [
                -83.188892,
                41.167659
              ]
            ]
          ],
          [
            [
              [
                -82.998158,
                40.803371
              ],
              [
                -82.997975,
                40.819764
              ],
              [
                -82.959157,
                40.823281
              ],
              [
                -82.949223,
                40.797946
              ],
              [
                -82.993438,
                40.790205
              ],
              [
                -82.998158,
                40.803371
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "Mid-Ohio",
        "teachers": 1737
      },
      "rdn": "us.oh.midohio"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.880194,
              40.64469
            ],
            [
              -83.879834,
              40.732443
            ],
            [
              -83.765968,
              40.732857
            ],
            [
              -83.730358,
              40.702688
            ],
            [
              -83.727049,
              40.657265
            ],
            [
              -83.738718,
              40.650083
            ],
            [
              -83.732308,
              40.58368
            ],
            [
              -83.70878,
              40.585954
            ],
            [
              -83.685579,
              40.571737
            ],
            [
              -83.696565,
              40.558391
            ],
            [
              -83.626078,
              40.513361
            ],
            [
              -83.605019,
              40.487138
            ],
            [
              -83.607025,
              40.466774
            ],
            [
              -83.524982,
              40.465347
            ],
            [
              -83.511955,
              40.438018
            ],
            [
              -83.51452,
              40.386921
            ],
            [
              -83.534762,
              40.378897
            ],
            [
              -83.545567,
              40.280467
            ],
            [
              -83.548306,
              40.256012
            ],
            [
              -83.573864,
              40.25769
            ],
            [
              -83.630254,
              40.2349
            ],
            [
              -83.633335,
              40.257012
            ],
            [
              -83.668757,
              40.245261
            ],
            [
              -83.676717,
              40.264395
            ],
            [
              -83.66518,
              40.280412
            ],
            [
              -83.708017,
              40.283133
            ],
            [
              -83.713299,
              40.302746
            ],
            [
              -83.770512,
              40.29111
            ],
            [
              -83.779379,
              40.303857
            ],
            [
              -83.830324,
              40.299743
            ],
            [
              -83.841394,
              40.263288
            ],
            [
              -84.014763,
              40.273459
            ],
            [
              -84.022919,
              40.183945
            ],
            [
              -84.219137,
              40.195499
            ],
            [
              -84.218658,
              40.200004
            ],
            [
              -84.408316,
              40.197401
            ],
            [
              -84.423142,
              40.206505
            ],
            [
              -84.43357,
              40.231689
            ],
            [
              -84.452094,
              40.240788
            ],
            [
              -84.452668,
              40.260109
            ],
            [
              -84.433959,
              40.270149
            ],
            [
              -84.440173,
              40.325244
            ],
            [
              -84.428901,
              40.36605
            ],
            [
              -84.370665,
              40.367364
            ],
            [
              -84.297477,
              40.389823
            ],
            [
              -84.307334,
              40.419342
            ],
            [
              -84.303009,
              40.445243
            ],
            [
              -84.285194,
              40.461208
            ],
            [
              -84.259742,
              40.461162
            ],
            [
              -84.25077,
              40.481819
            ],
            [
              -84.221084,
              40.482227
            ],
            [
              -84.221514,
              40.505687
            ],
            [
              -84.107124,
              40.505834
            ],
            [
              -84.107147,
              40.483161
            ],
            [
              -84.011947,
              40.497624
            ],
            [
              -83.993917,
              40.527874
            ],
            [
              -83.963571,
              40.521361
            ],
            [
              -83.917609,
              40.520651
            ],
            [
              -83.917447,
              40.535376
            ],
            [
              -83.879932,
              40.538708
            ],
            [
              -83.880018,
              40.5656
            ],
            [
              -83.903957,
              40.565908
            ],
            [
              -83.903936,
              40.593823
            ],
            [
              -83.880338,
              40.593942
            ],
            [
              -83.880194,
              40.64469
            ]
          ]
        ]
      },
      "properties": {
        "name": "Midwest Regional ESC",
        "teachers": 967
      },
      "rdn": "us.oh.midwest-regional-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.445416,
              39.926262
            ],
            [
              -84.426489,
              39.942559
            ],
            [
              -84.395913,
              39.927407
            ],
            [
              -84.347418,
              39.920784
            ],
            [
              -84.157671,
              39.92297
            ],
            [
              -84.167065,
              39.912197
            ],
            [
              -84.157223,
              39.88563
            ],
            [
              -84.051039,
              39.879807
            ],
            [
              -84.053736,
              39.850458
            ],
            [
              -84.055238,
              39.835958
            ],
            [
              -84.092938,
              39.838345
            ],
            [
              -84.111935,
              39.626359
            ],
            [
              -84.113333,
              39.588743
            ],
            [
              -84.114195,
              39.577983
            ],
            [
              -84.28574,
              39.589158
            ],
            [
              -84.294775,
              39.614431
            ],
            [
              -84.32752,
              39.614832
            ],
            [
              -84.326785,
              39.589313
            ],
            [
              -84.365232,
              39.589493
            ],
            [
              -84.479213,
              39.591024
            ],
            [
              -84.46898,
              39.656614
            ],
            [
              -84.480304,
              39.663561
            ],
            [
              -84.484215,
              39.85991
            ],
            [
              -84.445001,
              39.864192
            ],
            [
              -84.445416,
              39.926262
            ]
          ]
        ]
      },
      "properties": {
        "name": "Montgomery County",
        "teachers": 4297
      },
      "rdn": "us.oh.montgomery-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -81.869618,
              39.451293
            ],
            [
              -82.027002,
              39.458636
            ],
            [
              -82.025147,
              39.490791
            ],
            [
              -82.052889,
              39.491876
            ],
            [
              -82.047528,
              39.550825
            ],
            [
              -82.039605,
              39.550217
            ],
            [
              -82.032075,
              39.63666
            ],
            [
              -82.089071,
              39.63932
            ],
            [
              -82.085863,
              39.684991
            ],
            [
              -82.161181,
              39.687648
            ],
            [
              -82.166784,
              39.644066
            ],
            [
              -82.256708,
              39.648331
            ],
            [
              -82.261954,
              39.590592
            ],
            [
              -82.28112,
              39.59141
            ],
            [
              -82.274095,
              39.562554
            ],
            [
              -82.28041,
              39.472906
            ],
            [
              -82.289663,
              39.384195
            ],
            [
              -82.514479,
              39.395767
            ],
            [
              -82.51758,
              39.369468
            ],
            [
              -82.634386,
              39.375966
            ],
            [
              -82.624885,
              39.490808
            ],
            [
              -82.611284,
              39.490151
            ],
            [
              -82.621914,
              39.534903
            ],
            [
              -82.620088,
              39.563987
            ],
            [
              -82.618327,
              39.59571
            ],
            [
              -82.577488,
              39.593772
            ],
            [
              -82.574249,
              39.606757
            ],
            [
              -82.496123,
              39.602849
            ],
            [
              -82.480977,
              39.632124
            ],
            [
              -82.490329,
              39.661704
            ],
            [
              -82.359833,
              39.654178
            ],
            [
              -82.358452,
              39.679509
            ],
            [
              -82.37168,
              39.68463
            ],
            [
              -82.365363,
              39.750173
            ],
            [
              -82.403303,
              39.752228
            ],
            [
              -82.396594,
              39.833057
            ],
            [
              -82.472402,
              39.837223
            ],
            [
              -82.467423,
              39.884718
            ],
            [
              -82.477008,
              39.888278
            ],
            [
              -82.469179,
              39.927231
            ],
            [
              -82.408617,
              39.93234
            ],
            [
              -82.345977,
              39.932417
            ],
            [
              -82.310031,
              39.940557
            ],
            [
              -82.294499,
              39.95348
            ],
            [
              -82.292003,
              39.989105
            ],
            [
              -82.24428,
              39.988293
            ],
            [
              -82.19534,
              40.005967
            ],
            [
              -82.187105,
              40.16688
            ],
            [
              -82.281727,
              40.16984
            ],
            [
              -82.281449,
              40.200114
            ],
            [
              -82.235744,
              40.224427
            ],
            [
              -82.233448,
              40.236245
            ],
            [
              -82.195911,
              40.239071
            ],
            [
              -82.188184,
              40.383355
            ],
            [
              -82.160718,
              40.382267
            ],
            [
              -82.157311,
              40.401994
            ],
            [
              -82.187364,
              40.397799
            ],
            [
              -82.184607,
              40.45628
            ],
            [
              -82.162103,
              40.455377
            ],
            [
              -82.035781,
              40.452611
            ],
            [
              -82.027744,
              40.439668
            ],
            [
              -81.934337,
              40.425623
            ],
            [
              -81.918483,
              40.405841
            ],
            [
              -81.894858,
              40.420557
            ],
            [
              -81.854262,
              40.425932
            ],
            [
              -81.847397,
              40.439732
            ],
            [
              -81.804523,
              40.446796
            ],
            [
              -81.80483,
              40.395323
            ],
            [
              -81.744637,
              40.401305
            ],
            [
              -81.711383,
              40.392179
            ],
            [
              -81.712288,
              40.370804
            ],
            [
              -81.681446,
              40.369998
            ],
            [
              -81.645944,
              40.3848
            ],
            [
              -81.63482,
              40.41098
            ],
            [
              -81.614101,
              40.410133
            ],
            [
              -81.61802,
              40.330488
            ],
            [
              -81.637369,
              40.318682
            ],
            [
              -81.649107,
              40.295814
            ],
            [
              -81.649061,
              40.268448
            ],
            [
              -81.632934,
              40.262259
            ],
            [
              -81.636348,
              40.221615
            ],
            [
              -81.66759,
              40.222468
            ],
            [
              -81.670899,
              40.150976
            ],
            [
              -81.649428,
              40.15045
            ],
            [
              -81.650198,
              40.1294
            ],
            [
              -81.671424,
              40.116515
            ],
            [
              -81.665783,
              40.077367
            ],
            [
              -81.626987,
              40.076187
            ],
            [
              -81.634347,
              39.928878
            ],
            [
              -81.691106,
              39.930673
            ],
            [
              -81.697461,
              39.754995
            ],
            [
              -81.639886,
              39.75342
            ],
            [
              -81.6432,
              39.665681
            ],
            [
              -81.700256,
              39.667626
            ],
            [
              -81.701948,
              39.59086
            ],
            [
              -81.714447,
              39.584236
            ],
            [
              -81.723511,
              39.547608
            ],
            [
              -81.705075,
              39.53099
            ],
            [
              -81.708527,
              39.480775
            ],
            [
              -81.823158,
              39.494072
            ],
            [
              -81.825757,
              39.449172
            ],
            [
              -81.869618,
              39.451293
            ]
          ]
        ]
      },
      "properties": {
        "name": "Muskingum Valley",
        "teachers": 1878
      },
      "rdn": "us.oh.muskingum-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -83.431134,
                41.15031
              ],
              [
                -83.438501,
                41.166639
              ],
              [
                -83.478615,
                41.166054
              ],
              [
                -83.487934,
                41.202865
              ],
              [
                -83.477355,
                41.224346
              ],
              [
                -83.420335,
                41.224868
              ],
              [
                -83.420806,
                41.188539
              ],
              [
                -83.410382,
                41.17424
              ],
              [
                -83.382009,
                41.166631
              ],
              [
                -83.264791,
                41.166517
              ],
              [
                -83.263838,
                41.253307
              ],
              [
                -83.187755,
                41.254913
              ],
              [
                -83.185693,
                41.342205
              ],
              [
                -83.277447,
                41.341328
              ],
              [
                -83.301111,
                41.327062
              ],
              [
                -83.300957,
                41.305921
              ],
              [
                -83.358946,
                41.306403
              ],
              [
                -83.378541,
                41.312813
              ],
              [
                -83.388367,
                41.334499
              ],
              [
                -83.417139,
                41.348282
              ],
              [
                -83.426646,
                41.361763
              ],
              [
                -83.41606,
                41.39919
              ],
              [
                -83.405868,
                41.406453
              ],
              [
                -83.304636,
                41.406497
              ],
              [
                -83.299235,
                41.428766
              ],
              [
                -83.240058,
                41.429173
              ],
              [
                -83.230668,
                41.458158
              ],
              [
                -83.03789,
                41.458107
              ],
              [
                -83.003181,
                41.437234
              ],
              [
                -82.971399,
                41.449433
              ],
              [
                -82.953021,
                41.425157
              ],
              [
                -82.954691,
                41.357965
              ],
              [
                -83.014855,
                41.34251
              ],
              [
                -83.069043,
                41.341609
              ],
              [
                -83.061179,
                41.306772
              ],
              [
                -83.091798,
                41.260427
              ],
              [
                -83.089489,
                41.241127
              ],
              [
                -83.070112,
                41.22975
              ],
              [
                -83.074119,
                41.197067
              ],
              [
                -83.102895,
                41.182351
              ],
              [
                -83.103101,
                41.167811
              ],
              [
                -83.188892,
                41.167659
              ],
              [
                -83.189671,
                41.138536
              ],
              [
                -83.21091,
                41.113184
              ],
              [
                -83.18991,
                41.098714
              ],
              [
                -83.189753,
                41.080294
              ],
              [
                -83.073579,
                41.080788
              ],
              [
                -83.07421,
                40.993616
              ],
              [
                -82.973232,
                40.993669
              ],
              [
                -82.977914,
                40.986605
              ],
              [
                -82.978334,
                40.906683
              ],
              [
                -82.822485,
                40.90795
              ],
              [
                -82.812838,
                40.850519
              ],
              [
                -82.763577,
                40.843317
              ],
              [
                -82.765434,
                40.761443
              ],
              [
                -82.799426,
                40.755894
              ],
              [
                -82.857518,
                40.756043
              ],
              [
                -82.860905,
                40.603487
              ],
              [
                -82.804558,
                40.60324
              ],
              [
                -82.747728,
                40.595288
              ],
              [
                -82.729106,
                40.575867
              ],
              [
                -82.731588,
                40.513043
              ],
              [
                -82.76617,
                40.506851
              ],
              [
                -82.773186,
                40.470354
              ],
              [
                -82.811349,
                40.476503
              ],
              [
                -82.836356,
                40.51061
              ],
              [
                -82.850972,
                40.512548
              ],
              [
                -82.897672,
                40.550174
              ],
              [
                -82.967368,
                40.550561
              ],
              [
                -83.00971,
                40.54329
              ],
              [
                -82.995599,
                40.520026
              ],
              [
                -82.995743,
                40.491664
              ],
              [
                -83.016806,
                40.484263
              ],
              [
                -83.020798,
                40.433795
              ],
              [
                -83.191683,
                40.440542
              ],
              [
                -83.191993,
                40.425093
              ],
              [
                -83.237014,
                40.427341
              ],
              [
                -83.24884,
                40.419088
              ],
              [
                -83.249591,
                40.364305
              ],
              [
                -83.214872,
                40.355475
              ],
              [
                -83.206733,
                40.329716
              ],
              [
                -83.231778,
                40.332613
              ],
              [
                -83.279998,
                40.302774
              ],
              [
                -83.322269,
                40.294586
              ],
              [
                -83.352724,
                40.309548
              ],
              [
                -83.356668,
                40.33778
              ],
              [
                -83.350373,
                40.361622
              ],
              [
                -83.361419,
                40.377416
              ],
              [
                -83.395255,
                40.383776
              ],
              [
                -83.398953,
                40.3695
              ],
              [
                -83.482464,
                40.375899
              ],
              [
                -83.51452,
                40.386921
              ],
              [
                -83.511955,
                40.438018
              ],
              [
                -83.524982,
                40.465347
              ],
              [
                -83.520227,
                40.504077
              ],
              [
                -83.418378,
                40.505232
              ],
              [
                -83.417965,
                40.556202
              ],
              [
                -83.440769,
                40.556252
              ],
              [
                -83.437988,
                40.58685
              ],
              [
                -83.44973,
                40.601519
              ],
              [
                -83.420251,
                40.613982
              ],
              [
                -83.419836,
                40.686823
              ],
              [
                -83.459415,
                40.702251
              ],
              [
                -83.477759,
                40.730106
              ],
              [
                -83.414796,
                40.764087
              ],
              [
                -83.42976,
                40.804609
              ],
              [
                -83.410393,
                40.817642
              ],
              [
                -83.410543,
                40.905042
              ],
              [
                -83.456888,
                40.926604
              ],
              [
                -83.456875,
                40.954322
              ],
              [
                -83.446314,
                40.987285
              ],
              [
                -83.402498,
                40.992072
              ],
              [
                -83.407555,
                41.057854
              ],
              [
                -83.420786,
                41.057869
              ],
              [
                -83.421232,
                41.150458
              ],
              [
                -83.431134,
                41.15031
              ]
            ],
            [
              [
                -82.998158,
                40.803371
              ],
              [
                -82.993438,
                40.790205
              ],
              [
                -82.949223,
                40.797946
              ],
              [
                -82.959157,
                40.823281
              ],
              [
                -82.997975,
                40.819764
              ],
              [
                -82.998158,
                40.803371
              ]
            ]
          ],
          [
            [
              [
                -83.073579,
                41.080788
              ],
              [
                -83.074272,
                41.166597
              ],
              [
                -83.069841,
                41.169073
              ],
              [
                -83.033771,
                41.169254
              ],
              [
                -83.034009,
                41.198425
              ],
              [
                -82.99516,
                41.197937
              ],
              [
                -82.988985,
                41.172024
              ],
              [
                -82.942722,
                41.168598
              ],
              [
                -82.909724,
                41.179395
              ],
              [
                -82.880141,
                41.178656
              ],
              [
                -82.864655,
                41.189795
              ],
              [
                -82.799871,
                41.189811
              ],
              [
                -82.799889,
                41.208099
              ],
              [
                -82.777452,
                41.212405
              ],
              [
                -82.767671,
                41.18668
              ],
              [
                -82.738136,
                41.184201
              ],
              [
                -82.736344,
                41.142955
              ],
              [
                -82.777773,
                41.12127
              ],
              [
                -82.806315,
                41.078733
              ],
              [
                -82.832577,
                41.078303
              ],
              [
                -82.819616,
                41.043239
              ],
              [
                -82.818239,
                41.002245
              ],
              [
                -82.829617,
                40.999622
              ],
              [
                -82.862333,
                40.993672
              ],
              [
                -82.958694,
                40.993668
              ],
              [
                -82.960705,
                41.081094
              ],
              [
                -83.073579,
                41.080788
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "North Central Ohio",
        "teachers": 1746
      },
      "rdn": "us.oh.northcentral"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -81.968277,
                41.505265
              ],
              [
                -81.944658,
                41.492432
              ],
              [
                -81.87736,
                41.483445
              ],
              [
                -81.820158,
                41.494047
              ],
              [
                -81.782258,
                41.49605
              ],
              [
                -81.744755,
                41.48715
              ],
              [
                -81.665065,
                41.53162
              ],
              [
                -81.655462,
                41.531101
              ],
              [
                -81.599747,
                41.560649
              ],
              [
                -81.529742,
                41.614548
              ],
              [
                -81.48864,
                41.631348
              ],
              [
                -81.487842,
                41.570051
              ],
              [
                -81.391005,
                41.569716
              ],
              [
                -81.390866,
                41.499214
              ],
              [
                -81.382594,
                41.469244
              ],
              [
                -81.391518,
                41.445222
              ],
              [
                -81.366411,
                41.439042
              ],
              [
                -81.297821,
                41.439674
              ],
              [
                -81.297732,
                41.425093
              ],
              [
                -81.19057,
                41.425643
              ],
              [
                -81.19142,
                41.347569
              ],
              [
                -81.003319,
                41.34786
              ],
              [
                -81.00288,
                41.271842
              ],
              [
                -81.098645,
                41.272461
              ],
              [
                -81.098733,
                41.22533
              ],
              [
                -81.121648,
                41.213378
              ],
              [
                -81.192752,
                41.192869
              ],
              [
                -81.192656,
                41.177448
              ],
              [
                -81.193268,
                41.134058
              ],
              [
                -81.254369,
                41.138004
              ],
              [
                -81.300256,
                41.134137
              ],
              [
                -81.392114,
                41.135722
              ],
              [
                -81.391831,
                41.276654
              ],
              [
                -81.458855,
                41.276848
              ],
              [
                -81.479678,
                41.296166
              ],
              [
                -81.479406,
                41.327653
              ],
              [
                -81.488988,
                41.349753
              ],
              [
                -81.598052,
                41.351157
              ],
              [
                -81.585444,
                41.308864
              ],
              [
                -81.563147,
                41.278579
              ],
              [
                -81.587418,
                41.2776
              ],
              [
                -81.590569,
                41.226696
              ],
              [
                -81.602766,
                41.205199
              ],
              [
                -81.567541,
                41.199398
              ],
              [
                -81.586894,
                41.176657
              ],
              [
                -81.587417,
                41.13584
              ],
              [
                -81.519438,
                41.135855
              ],
              [
                -81.519538,
                41.123955
              ],
              [
                -81.466043,
                41.117189
              ],
              [
                -81.465751,
                41.093486
              ],
              [
                -81.428653,
                41.084676
              ],
              [
                -81.424598,
                41.040325
              ],
              [
                -81.450009,
                41.040367
              ],
              [
                -81.453735,
                41.019396
              ],
              [
                -81.491821,
                41.029207
              ],
              [
                -81.53894,
                41.028455
              ],
              [
                -81.568289,
                41.021735
              ],
              [
                -81.589243,
                41.041455
              ],
              [
                -81.588342,
                41.095434
              ],
              [
                -81.604631,
                41.102818
              ],
              [
                -81.604171,
                41.143361
              ],
              [
                -81.651002,
                41.142069
              ],
              [
                -81.654012,
                41.121385
              ],
              [
                -81.687051,
                41.127962
              ],
              [
                -81.68495,
                41.277146
              ],
              [
                -81.897813,
                41.275917
              ],
              [
                -81.87801,
                41.283013
              ],
              [
                -81.87696,
                41.350685
              ],
              [
                -81.912403,
                41.350832
              ],
              [
                -81.937585,
                41.337708
              ],
              [
                -81.971313,
                41.349102
              ],
              [
                -81.968277,
                41.505265
              ]
            ]
          ],
          [
            [
              [
                -81.101422,
                41.641457
              ],
              [
                -81.100546,
                41.571595
              ],
              [
                -81.161407,
                41.562089
              ],
              [
                -81.171683,
                41.50775
              ],
              [
                -81.274981,
                41.499707
              ],
              [
                -81.29588,
                41.56995
              ],
              [
                -81.294669,
                41.640889
              ],
              [
                -81.37403,
                41.640201
              ],
              [
                -81.390954,
                41.648817
              ],
              [
                -81.388898,
                41.70697
              ],
              [
                -81.29083,
                41.757667
              ],
              [
                -81.293126,
                41.683845
              ],
              [
                -81.274479,
                41.681208
              ],
              [
                -81.275197,
                41.647099
              ],
              [
                -81.261207,
                41.626513
              ],
              [
                -81.241529,
                41.641115
              ],
              [
                -81.101422,
                41.641457
              ]
            ]
          ],
          [
            [
              [
                -81.301237,
                41.064874
              ],
              [
                -81.099016,
                41.062462
              ],
              [
                -81.085897,
                41.052719
              ],
              [
                -81.098634,
                41.032922
              ],
              [
                -81.09882,
                40.988074
              ],
              [
                -81.302728,
                40.988534
              ],
              [
                -81.301237,
                41.064874
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "ESC of Northeast Ohio",
        "teachers": 11537
      },
      "rdn": "us.oh.northeast-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -83.069841,
                41.169073
              ],
              [
                -83.074119,
                41.197067
              ],
              [
                -83.070112,
                41.22975
              ],
              [
                -83.089489,
                41.241127
              ],
              [
                -83.091798,
                41.260427
              ],
              [
                -83.061179,
                41.306772
              ],
              [
                -83.069043,
                41.341609
              ],
              [
                -83.014855,
                41.34251
              ],
              [
                -82.954691,
                41.357965
              ],
              [
                -82.953021,
                41.425157
              ],
              [
                -82.92271,
                41.42118
              ],
              [
                -82.858537,
                41.441139
              ],
              [
                -82.812664,
                41.469818
              ],
              [
                -82.766317,
                41.445771
              ],
              [
                -82.730658,
                41.450017
              ],
              [
                -82.700717,
                41.464297
              ],
              [
                -82.625696,
                41.423648
              ],
              [
                -82.599901,
                41.41898
              ],
              [
                -82.513827,
                41.384257
              ],
              [
                -82.481214,
                41.381342
              ],
              [
                -82.431315,
                41.396866
              ],
              [
                -82.363879,
                41.428108
              ],
              [
                -82.29158,
                41.428442
              ],
              [
                -82.252499,
                41.435742
              ],
              [
                -82.251778,
                41.418242
              ],
              [
                -82.273446,
                41.408198
              ],
              [
                -82.295568,
                41.419601
              ],
              [
                -82.344158,
                41.4134
              ],
              [
                -82.321711,
                41.382512
              ],
              [
                -82.34627,
                41.382943
              ],
              [
                -82.345202,
                41.35743
              ],
              [
                -82.443889,
                41.357248
              ],
              [
                -82.435716,
                41.283891
              ],
              [
                -82.342314,
                41.283554
              ],
              [
                -82.344086,
                41.199152
              ],
              [
                -82.37006,
                41.188617
              ],
              [
                -82.369389,
                41.150562
              ],
              [
                -82.330596,
                41.129627
              ],
              [
                -82.296053,
                41.129251
              ],
              [
                -82.296953,
                41.091798
              ],
              [
                -82.28994,
                41.065531
              ],
              [
                -82.436916,
                41.065379
              ],
              [
                -82.432852,
                40.992944
              ],
              [
                -82.449744,
                40.985324
              ],
              [
                -82.496079,
                40.983458
              ],
              [
                -82.572353,
                40.986187
              ],
              [
                -82.581315,
                40.994187
              ],
              [
                -82.630592,
                40.99464
              ],
              [
                -82.643896,
                41.011758
              ],
              [
                -82.673108,
                41.010934
              ],
              [
                -82.688134,
                40.995416
              ],
              [
                -82.820318,
                40.996476
              ],
              [
                -82.829617,
                40.999622
              ],
              [
                -82.818239,
                41.002245
              ],
              [
                -82.819616,
                41.043239
              ],
              [
                -82.832577,
                41.078303
              ],
              [
                -82.806315,
                41.078733
              ],
              [
                -82.777773,
                41.12127
              ],
              [
                -82.736344,
                41.142955
              ],
              [
                -82.738136,
                41.184201
              ],
              [
                -82.767671,
                41.18668
              ],
              [
                -82.777452,
                41.212405
              ],
              [
                -82.799889,
                41.208099
              ],
              [
                -82.799871,
                41.189811
              ],
              [
                -82.864655,
                41.189795
              ],
              [
                -82.880141,
                41.178656
              ],
              [
                -82.909724,
                41.179395
              ],
              [
                -82.942722,
                41.168598
              ],
              [
                -82.988985,
                41.172024
              ],
              [
                -82.99516,
                41.197937
              ],
              [
                -83.034009,
                41.198425
              ],
              [
                -83.033771,
                41.169254
              ],
              [
                -83.069841,
                41.169073
              ]
            ]
          ],
          [
            [
              [
                -83.165713,
                41.623246
              ],
              [
                -83.103928,
                41.613558
              ],
              [
                -83.066593,
                41.59534
              ],
              [
                -83.028072,
                41.555656
              ],
              [
                -82.969642,
                41.524229
              ],
              [
                -82.912252,
                41.515928
              ],
              [
                -82.8882,
                41.522508
              ],
              [
                -82.85677,
                41.548262
              ],
              [
                -82.8602,
                41.574631
              ],
              [
                -82.834101,
                41.587587
              ],
              [
                -82.803883,
                41.552274
              ],
              [
                -82.774855,
                41.537388
              ],
              [
                -82.749907,
                41.54647
              ],
              [
                -82.717878,
                41.54193
              ],
              [
                -82.726476,
                41.511621
              ],
              [
                -82.765604,
                41.502401
              ],
              [
                -82.807198,
                41.505961
              ],
              [
                -82.824073,
                41.490296
              ],
              [
                -82.844513,
                41.501689
              ],
              [
                -82.883025,
                41.488607
              ],
              [
                -82.953841,
                41.488297
              ],
              [
                -82.974451,
                41.467386
              ],
              [
                -83.03789,
                41.458107
              ],
              [
                -83.230668,
                41.458158
              ],
              [
                -83.240058,
                41.429173
              ],
              [
                -83.299235,
                41.428766
              ],
              [
                -83.304636,
                41.406497
              ],
              [
                -83.405868,
                41.406453
              ],
              [
                -83.41606,
                41.39919
              ],
              [
                -83.414577,
                41.515001
              ],
              [
                -83.415919,
                41.618938
              ],
              [
                -83.165713,
                41.623246
              ]
            ]
          ],
          [
            [
              [
                -82.735707,
                41.603361
              ],
              [
                -82.718802,
                41.619629
              ],
              [
                -82.681106,
                41.610843
              ],
              [
                -82.680669,
                41.594611
              ],
              [
                -82.725827,
                41.595199
              ],
              [
                -82.735707,
                41.603361
              ]
            ]
          ],
          [
            [
              [
                -82.84385,
                41.646475
              ],
              [
                -82.834092,
                41.657121
              ],
              [
                -82.802616,
                41.653946
              ],
              [
                -82.834662,
                41.629331
              ],
              [
                -82.84385,
                41.646475
              ]
            ]
          ],
          [
            [
              [
                -83.417139,
                41.348282
              ],
              [
                -83.388367,
                41.334499
              ],
              [
                -83.378541,
                41.312813
              ],
              [
                -83.358946,
                41.306403
              ],
              [
                -83.300957,
                41.305921
              ],
              [
                -83.301111,
                41.327062
              ],
              [
                -83.277447,
                41.341328
              ],
              [
                -83.185693,
                41.342205
              ],
              [
                -83.187755,
                41.254913
              ],
              [
                -83.263838,
                41.253307
              ],
              [
                -83.264791,
                41.166517
              ],
              [
                -83.382009,
                41.166631
              ],
              [
                -83.410382,
                41.17424
              ],
              [
                -83.420806,
                41.188539
              ],
              [
                -83.420335,
                41.224868
              ],
              [
                -83.477355,
                41.224346
              ],
              [
                -83.477678,
                41.253668
              ],
              [
                -83.466957,
                41.280076
              ],
              [
                -83.448192,
                41.283289
              ],
              [
                -83.445339,
                41.352467
              ],
              [
                -83.417139,
                41.348282
              ]
            ]
          ]
        ]
      },
      "properties": {
        "name": "North Point",
        "teachers": 1848
      },
      "rdn": "us.oh.northpoint"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.331905,
              41.165441
            ],
            [
              -84.341607,
              41.20906
            ],
            [
              -84.362999,
              41.209266
            ],
            [
              -84.390492,
              41.194668
            ],
            [
              -84.399529,
              41.209281
            ],
            [
              -84.457059,
              41.209434
            ],
            [
              -84.457217,
              41.253485
            ],
            [
              -84.803492,
              41.252562
            ],
            [
              -84.803919,
              41.435531
            ],
            [
              -84.805972,
              41.696118
            ],
            [
              -84.749955,
              41.698245
            ],
            [
              -84.360546,
              41.706621
            ],
            [
              -84.134417,
              41.712931
            ],
            [
              -83.781446,
              41.723111
            ],
            [
              -83.780683,
              41.68721
            ],
            [
              -83.769018,
              41.660988
            ],
            [
              -83.807004,
              41.657914
            ],
            [
              -83.805634,
              41.567348
            ],
            [
              -83.882696,
              41.545295
            ],
            [
              -83.882897,
              41.478841
            ],
            [
              -83.892669,
              41.444092
            ],
            [
              -83.911955,
              41.444181
            ],
            [
              -83.917211,
              41.400737
            ],
            [
              -83.883226,
              41.40082
            ],
            [
              -83.882206,
              41.247598
            ],
            [
              -83.862319,
              41.255203
            ],
            [
              -83.861745,
              41.226313
            ],
            [
              -83.881539,
              41.193615
            ],
            [
              -83.881117,
              41.153136
            ],
            [
              -83.91929,
              41.153415
            ],
            [
              -83.919398,
              41.167504
            ],
            [
              -84.331905,
              41.165441
            ]
          ]
        ]
      },
      "properties": {
        "name": "Northwest Ohio",
        "teachers": 1757
      },
      "rdn": "us.oh.northwest"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -81.649428,
              40.15045
            ],
            [
              -81.622788,
              40.164856
            ],
            [
              -81.548144,
              40.165065
            ],
            [
              -81.526358,
              40.191904
            ],
            [
              -81.524969,
              40.213234
            ],
            [
              -81.505007,
              40.218545
            ],
            [
              -81.338057,
              40.214253
            ],
            [
              -81.339565,
              40.171999
            ],
            [
              -81.225924,
              40.170075
            ],
            [
              -81.237316,
              39.867828
            ],
            [
              -81.10595,
              39.86325
            ],
            [
              -81.104849,
              39.895046
            ],
            [
              -81.060073,
              39.89485
            ],
            [
              -81.059929,
              39.912016
            ],
            [
              -81.013444,
              39.909359
            ],
            [
              -81.010622,
              39.944931
            ],
            [
              -80.898353,
              39.939939
            ],
            [
              -80.885422,
              39.925113
            ],
            [
              -80.841758,
              39.923076
            ],
            [
              -80.807782,
              39.914481
            ],
            [
              -80.793131,
              39.863751
            ],
            [
              -80.826964,
              39.841656
            ],
            [
              -80.826079,
              39.798584
            ],
            [
              -80.863048,
              39.775197
            ],
            [
              -80.865339,
              39.753251
            ],
            [
              -80.831551,
              39.719475
            ],
            [
              -80.833882,
              39.703497
            ],
            [
              -80.861718,
              39.693625
            ],
            [
              -80.866647,
              39.652616
            ],
            [
              -80.88036,
              39.620706
            ],
            [
              -80.933292,
              39.614812
            ],
            [
              -81.0239,
              39.552313
            ],
            [
              -81.100833,
              39.487175
            ],
            [
              -81.134434,
              39.445075
            ],
            [
              -81.185946,
              39.430731
            ],
            [
              -81.223581,
              39.386062
            ],
            [
              -81.270716,
              39.385914
            ],
            [
              -81.347567,
              39.34577
            ],
            [
              -81.375961,
              39.341697
            ],
            [
              -81.395883,
              39.355553
            ],
            [
              -81.406689,
              39.38809
            ],
            [
              -81.428642,
              39.405374
            ],
            [
              -81.456143,
              39.409274
            ],
            [
              -81.503189,
              39.373242
            ],
            [
              -81.557547,
              39.338774
            ],
            [
              -81.570247,
              39.267675
            ],
            [
              -81.608408,
              39.276043
            ],
            [
              -81.678331,
              39.273755
            ],
            [
              -81.69638,
              39.257035
            ],
            [
              -81.691339,
              39.227947
            ],
            [
              -81.725583,
              39.215835
            ],
            [
              -81.72181,
              39.269568
            ],
            [
              -81.818361,
              39.272581
            ],
            [
              -81.816614,
              39.302058
            ],
            [
              -81.853668,
              39.318165
            ],
            [
              -81.84651,
              39.42361
            ],
            [
              -81.869618,
              39.451293
            ],
            [
              -81.825757,
              39.449172
            ],
            [
              -81.823158,
              39.494072
            ],
            [
              -81.708527,
              39.480775
            ],
            [
              -81.705075,
              39.53099
            ],
            [
              -81.723511,
              39.547608
            ],
            [
              -81.714447,
              39.584236
            ],
            [
              -81.701948,
              39.59086
            ],
            [
              -81.700256,
              39.667626
            ],
            [
              -81.6432,
              39.665681
            ],
            [
              -81.639886,
              39.75342
            ],
            [
              -81.697461,
              39.754995
            ],
            [
              -81.691106,
              39.930673
            ],
            [
              -81.634347,
              39.928878
            ],
            [
              -81.626987,
              40.076187
            ],
            [
              -81.665783,
              40.077367
            ],
            [
              -81.671424,
              40.116515
            ],
            [
              -81.650198,
              40.1294
            ],
            [
              -81.649428,
              40.15045
            ]
          ]
        ]
      },
      "properties": {
        "name": "Ohio Valley",
        "teachers": 1080
      },
      "rdn": "us.oh.ohio-valley-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.223237,
              39.519178
            ],
            [
              -83.22753,
              39.572426
            ],
            [
              -83.261235,
              39.581874
            ],
            [
              -83.252435,
              39.695438
            ],
            [
              -83.243702,
              39.812503
            ],
            [
              -83.200943,
              39.810656
            ],
            [
              -83.180238,
              39.803421
            ],
            [
              -83.153257,
              39.808465
            ],
            [
              -83.037634,
              39.805567
            ],
            [
              -82.824254,
              39.794996
            ],
            [
              -82.828416,
              39.750063
            ],
            [
              -82.806803,
              39.749255
            ],
            [
              -82.791491,
              39.734271
            ],
            [
              -82.782374,
              39.713629
            ],
            [
              -82.795334,
              39.666861
            ],
            [
              -82.834378,
              39.649445
            ],
            [
              -82.842953,
              39.56148
            ],
            [
              -82.731518,
              39.554445
            ],
            [
              -82.730272,
              39.569066
            ],
            [
              -82.620088,
              39.563987
            ],
            [
              -82.621914,
              39.534903
            ],
            [
              -82.611284,
              39.490151
            ],
            [
              -82.624885,
              39.490808
            ],
            [
              -82.634386,
              39.375966
            ],
            [
              -82.635686,
              39.361566
            ],
            [
              -82.748591,
              39.368165
            ],
            [
              -82.743389,
              39.435797
            ],
            [
              -82.749998,
              39.468062
            ],
            [
              -82.998204,
              39.480061
            ],
            [
              -82.980396,
              39.490261
            ],
            [
              -82.992597,
              39.506388
            ],
            [
              -83.065757,
              39.508761
            ],
            [
              -83.092476,
              39.522665
            ],
            [
              -83.111871,
              39.510274
            ],
            [
              -83.157518,
              39.539739
            ],
            [
              -83.223237,
              39.519178
            ]
          ]
        ]
      },
      "properties": {
        "name": "Pickaway County",
        "teachers": 571
      },
      "rdn": "us.oh.pickaway-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.81219,
              39.92758
            ],
            [
              -84.779181,
              39.916826
            ],
            [
              -84.596249,
              39.916743
            ],
            [
              -84.505486,
              39.918135
            ],
            [
              -84.505833,
              39.926244
            ],
            [
              -84.445416,
              39.926262
            ],
            [
              -84.445001,
              39.864192
            ],
            [
              -84.484215,
              39.85991
            ],
            [
              -84.480304,
              39.663561
            ],
            [
              -84.46898,
              39.656614
            ],
            [
              -84.479213,
              39.591024
            ],
            [
              -84.47908,
              39.573844
            ],
            [
              -84.510337,
              39.573585
            ],
            [
              -84.518666,
              39.562208
            ],
            [
              -84.591531,
              39.567532
            ],
            [
              -84.591926,
              39.588222
            ],
            [
              -84.630035,
              39.588226
            ],
            [
              -84.650372,
              39.567365
            ],
            [
              -84.785967,
              39.567664
            ],
            [
              -84.815148,
              39.548687
            ],
            [
              -84.814178,
              39.81424
            ],
            [
              -84.81219,
              39.92758
            ]
          ]
        ]
      },
      "properties": {
        "name": "Preble County",
        "teachers": 394
      },
      "rdn": "us.oh.preble-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.331905,
              41.165441
            ],
            [
              -83.919398,
              41.167504
            ],
            [
              -83.91929,
              41.153415
            ],
            [
              -83.881117,
              41.153136
            ],
            [
              -83.880423,
              40.920429
            ],
            [
              -83.937745,
              40.920261
            ],
            [
              -83.995208,
              40.915026
            ],
            [
              -83.995021,
              40.876387
            ],
            [
              -84.10968,
              40.868148
            ],
            [
              -84.109586,
              40.860994
            ],
            [
              -84.340531,
              40.859099
            ],
            [
              -84.340969,
              40.903596
            ],
            [
              -84.398407,
              40.903265
            ],
            [
              -84.399135,
              40.965336
            ],
            [
              -84.380065,
              40.968919
            ],
            [
              -84.370105,
              40.990531
            ],
            [
              -84.341911,
              40.990692
            ],
            [
              -84.341612,
              41.158013
            ],
            [
              -84.331905,
              41.165441
            ]
          ]
        ]
      },
      "properties": {
        "name": "Putnam County",
        "teachers": 440
      },
      "rdn": "us.oh.putnam-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.223237,
              39.519178
            ],
            [
              -83.157518,
              39.539739
            ],
            [
              -83.111871,
              39.510274
            ],
            [
              -83.092476,
              39.522665
            ],
            [
              -83.065757,
              39.508761
            ],
            [
              -82.992597,
              39.506388
            ],
            [
              -82.980396,
              39.490261
            ],
            [
              -82.998204,
              39.480061
            ],
            [
              -82.749998,
              39.468062
            ],
            [
              -82.743389,
              39.435797
            ],
            [
              -82.748591,
              39.368165
            ],
            [
              -82.766692,
              39.167768
            ],
            [
              -82.785891,
              39.168769
            ],
            [
              -82.79957,
              39.03563
            ],
            [
              -82.772386,
              39.032757
            ],
            [
              -82.773985,
              39.01564
            ],
            [
              -82.801361,
              39.005312
            ],
            [
              -82.806544,
              38.948045
            ],
            [
              -82.866656,
              38.919719
            ],
            [
              -82.912846,
              38.922014
            ],
            [
              -82.941534,
              38.942877
            ],
            [
              -82.941064,
              38.954139
            ],
            [
              -83.212107,
              38.960148
            ],
            [
              -83.214007,
              38.988393
            ],
            [
              -83.262554,
              38.993163
            ],
            [
              -83.270817,
              39.015791
            ],
            [
              -83.30811,
              39.04026
            ],
            [
              -83.314589,
              39.052195
            ],
            [
              -83.385637,
              39.055197
            ],
            [
              -83.353531,
              39.197585
            ],
            [
              -83.344812,
              39.251449
            ],
            [
              -83.308853,
              39.289099
            ],
            [
              -83.28814,
              39.269218
            ],
            [
              -83.266571,
              39.27688
            ],
            [
              -83.293854,
              39.296735
            ],
            [
              -83.2388,
              39.295325
            ],
            [
              -83.233775,
              39.330136
            ],
            [
              -83.250017,
              39.379513
            ],
            [
              -83.279776,
              39.401543
            ],
            [
              -83.280265,
              39.418364
            ],
            [
              -83.313409,
              39.433438
            ],
            [
              -83.317496,
              39.449993
            ],
            [
              -83.266737,
              39.516249
            ],
            [
              -83.223237,
              39.519178
            ]
          ],
          [
            [
              -83.02763,
              39.327952
            ],
            [
              -83.00818,
              39.312095
            ],
            [
              -82.981909,
              39.248156
            ],
            [
              -82.966754,
              39.240525
            ],
            [
              -82.937539,
              39.283131
            ],
            [
              -82.966475,
              39.320847
            ],
            [
              -82.94384,
              39.339948
            ],
            [
              -82.994341,
              39.361417
            ],
            [
              -83.020428,
              39.349035
            ],
            [
              -83.02763,
              39.327952
            ]
          ]
        ]
      },
      "properties": {
        "name": "Ross Pike County",
        "teachers": 760
      },
      "rdn": "us.oh.ross-pike-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.705314,
              38.639761
            ],
            [
              -83.696194,
              38.761722
            ],
            [
              -83.569503,
              38.754653
            ],
            [
              -83.550834,
              38.746244
            ],
            [
              -83.517568,
              38.748662
            ],
            [
              -83.4748,
              38.726615
            ],
            [
              -83.457034,
              38.728307
            ],
            [
              -83.43765,
              38.709892
            ],
            [
              -83.400101,
              38.730604
            ],
            [
              -83.388302,
              38.721485
            ],
            [
              -83.345117,
              38.71094
            ],
            [
              -83.311718,
              38.695419
            ],
            [
              -83.30344,
              38.712762
            ],
            [
              -83.268186,
              38.709889
            ],
            [
              -83.268578,
              38.882521
            ],
            [
              -83.270817,
              39.015791
            ],
            [
              -83.262554,
              38.993163
            ],
            [
              -83.214007,
              38.988393
            ],
            [
              -83.212107,
              38.960148
            ],
            [
              -82.941064,
              38.954139
            ],
            [
              -82.941534,
              38.942877
            ],
            [
              -82.912846,
              38.922014
            ],
            [
              -82.866656,
              38.919719
            ],
            [
              -82.806544,
              38.948045
            ],
            [
              -82.566431,
              38.93231
            ],
            [
              -82.559149,
              38.983955
            ],
            [
              -82.578746,
              38.98624
            ],
            [
              -82.58225,
              39.022757
            ],
            [
              -82.554561,
              39.020843
            ],
            [
              -82.550509,
              39.055148
            ],
            [
              -82.512034,
              39.052301
            ],
            [
              -82.513258,
              39.040103
            ],
            [
              -82.435309,
              39.035079
            ],
            [
              -82.44366,
              38.947439
            ],
            [
              -82.512382,
              38.951512
            ],
            [
              -82.513667,
              38.912167
            ],
            [
              -82.480937,
              38.894043
            ],
            [
              -82.48239,
              38.863705
            ],
            [
              -82.465297,
              38.84566
            ],
            [
              -82.574383,
              38.853475
            ],
            [
              -82.582683,
              38.779077
            ],
            [
              -82.563058,
              38.777749
            ],
            [
              -82.585133,
              38.75687
            ],
            [
              -82.661386,
              38.762477
            ],
            [
              -82.662886,
              38.747977
            ],
            [
              -82.695387,
              38.749977
            ],
            [
              -82.706383,
              38.677485
            ],
            [
              -82.763895,
              38.679974
            ],
            [
              -82.766188,
              38.644678
            ],
            [
              -82.740383,
              38.597167
            ],
            [
              -82.816012,
              38.570733
            ],
            [
              -82.847186,
              38.595166
            ],
            [
              -82.863291,
              38.669277
            ],
            [
              -82.877592,
              38.690177
            ],
            [
              -82.869892,
              38.728177
            ],
            [
              -82.894193,
              38.756576
            ],
            [
              -82.943147,
              38.74328
            ],
            [
              -82.979395,
              38.725976
            ],
            [
              -83.011816,
              38.730057
            ],
            [
              -83.033014,
              38.723805
            ],
            [
              -83.053104,
              38.695831
            ],
            [
              -83.102746,
              38.677316
            ],
            [
              -83.122547,
              38.6592
            ],
            [
              -83.142836,
              38.625076
            ],
            [
              -83.202453,
              38.616956
            ],
            [
              -83.239515,
              38.628588
            ],
            [
              -83.261126,
              38.622723
            ],
            [
              -83.294193,
              38.596588
            ],
            [
              -83.317542,
              38.609242
            ],
            [
              -83.322383,
              38.630615
            ],
            [
              -83.366661,
              38.658537
            ],
            [
              -83.468059,
              38.67547
            ],
            [
              -83.486477,
              38.690099
            ],
            [
              -83.520953,
              38.703045
            ],
            [
              -83.626922,
              38.679387
            ],
            [
              -83.645914,
              38.637007
            ],
            [
              -83.668111,
              38.628068
            ],
            [
              -83.705314,
              38.639761
            ]
          ]
        ]
      },
      "properties": {
        "name": "South Central Ohio",
        "teachers": 961
      },
      "rdn": "us.oh.south-central-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.270817,
              39.015791
            ],
            [
              -83.268578,
              38.882521
            ],
            [
              -83.268186,
              38.709889
            ],
            [
              -83.30344,
              38.712762
            ],
            [
              -83.311718,
              38.695419
            ],
            [
              -83.345117,
              38.71094
            ],
            [
              -83.388302,
              38.721485
            ],
            [
              -83.400101,
              38.730604
            ],
            [
              -83.43765,
              38.709892
            ],
            [
              -83.457034,
              38.728307
            ],
            [
              -83.4748,
              38.726615
            ],
            [
              -83.517568,
              38.748662
            ],
            [
              -83.550834,
              38.746244
            ],
            [
              -83.569503,
              38.754653
            ],
            [
              -83.696194,
              38.761722
            ],
            [
              -83.673018,
              39.02043
            ],
            [
              -83.704116,
              39.038182
            ],
            [
              -83.710181,
              39.022433
            ],
            [
              -83.750864,
              39.020465
            ],
            [
              -83.872214,
              39.021304
            ],
            [
              -83.86846,
              39.104138
            ],
            [
              -83.865679,
              39.247333
            ],
            [
              -83.964753,
              39.252099
            ],
            [
              -83.986677,
              39.233231
            ],
            [
              -83.99748,
              39.19289
            ],
            [
              -84.025266,
              39.206957
            ],
            [
              -84.080136,
              39.211567
            ],
            [
              -84.093032,
              39.224611
            ],
            [
              -84.085624,
              39.242174
            ],
            [
              -84.046074,
              39.257468
            ],
            [
              -84.014463,
              39.272079
            ],
            [
              -84.004119,
              39.288404
            ],
            [
              -84.004068,
              39.338556
            ],
            [
              -84.028338,
              39.360968
            ],
            [
              -84.030575,
              39.40056
            ],
            [
              -84.05344,
              39.39523
            ],
            [
              -84.063527,
              39.418615
            ],
            [
              -84.102337,
              39.416399
            ],
            [
              -84.08887,
              39.460619
            ],
            [
              -84.051797,
              39.489118
            ],
            [
              -84.038789,
              39.491724
            ],
            [
              -84.011695,
              39.523001
            ],
            [
              -83.979443,
              39.544116
            ],
            [
              -83.938946,
              39.567028
            ],
            [
              -83.843332,
              39.561065
            ],
            [
              -83.821883,
              39.564943
            ],
            [
              -83.788096,
              39.557533
            ],
            [
              -83.735869,
              39.561352
            ],
            [
              -83.704861,
              39.526904
            ],
            [
              -83.670196,
              39.550254
            ],
            [
              -83.661666,
              39.638285
            ],
            [
              -83.634776,
              39.641106
            ],
            [
              -83.638184,
              39.66337
            ],
            [
              -83.658598,
              39.664994
            ],
            [
              -83.651342,
              39.716883
            ],
            [
              -83.460601,
              39.70504
            ],
            [
              -83.252435,
              39.695438
            ],
            [
              -83.261235,
              39.581874
            ],
            [
              -83.22753,
              39.572426
            ],
            [
              -83.223237,
              39.519178
            ],
            [
              -83.266737,
              39.516249
            ],
            [
              -83.317496,
              39.449993
            ],
            [
              -83.313409,
              39.433438
            ],
            [
              -83.280265,
              39.418364
            ],
            [
              -83.279776,
              39.401543
            ],
            [
              -83.250017,
              39.379513
            ],
            [
              -83.233775,
              39.330136
            ],
            [
              -83.2388,
              39.295325
            ],
            [
              -83.293854,
              39.296735
            ],
            [
              -83.266571,
              39.27688
            ],
            [
              -83.28814,
              39.269218
            ],
            [
              -83.308853,
              39.289099
            ],
            [
              -83.344812,
              39.251449
            ],
            [
              -83.353531,
              39.197585
            ],
            [
              -83.385637,
              39.055197
            ],
            [
              -83.314589,
              39.052195
            ],
            [
              -83.30811,
              39.04026
            ],
            [
              -83.270817,
              39.015791
            ]
          ]
        ]
      },
      "properties": {
        "name": "Southern Ohio",
        "teachers": 1481
      },
      "rdn": "us.oh.southernohio"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -81.017825,
              40.813772
            ],
            [
              -81.016665,
              40.778763
            ],
            [
              -80.974676,
              40.780917
            ],
            [
              -80.974818,
              40.727003
            ],
            [
              -81.043399,
              40.727427
            ],
            [
              -81.051985,
              40.697204
            ],
            [
              -81.087954,
              40.697319
            ],
            [
              -81.089086,
              40.646915
            ],
            [
              -81.160231,
              40.648755
            ],
            [
              -81.214865,
              40.641622
            ],
            [
              -81.226147,
              40.601569
            ],
            [
              -81.253873,
              40.601745
            ],
            [
              -81.27127,
              40.584065
            ],
            [
              -81.268575,
              40.565158
            ],
            [
              -81.321659,
              40.566648
            ],
            [
              -81.319138,
              40.615479
            ],
            [
              -81.413338,
              40.615246
            ],
            [
              -81.41279,
              40.653096
            ],
            [
              -81.393239,
              40.652838
            ],
            [
              -81.396237,
              40.706595
            ],
            [
              -81.440009,
              40.71522
            ],
            [
              -81.442678,
              40.678649
            ],
            [
              -81.472131,
              40.679073
            ],
            [
              -81.51084,
              40.660374
            ],
            [
              -81.510522,
              40.607161
            ],
            [
              -81.502304,
              40.585923
            ],
            [
              -81.517147,
              40.578818
            ],
            [
              -81.512961,
              40.550305
            ],
            [
              -81.5498,
              40.550516
            ],
            [
              -81.578208,
              40.574624
            ],
            [
              -81.576969,
              40.622637
            ],
            [
              -81.605045,
              40.639733
            ],
            [
              -81.649199,
              40.635106
            ],
            [
              -81.649241,
              40.730093
            ],
            [
              -81.67901,
              40.726537
            ],
            [
              -81.765505,
              40.726538
            ],
            [
              -81.765295,
              40.790848
            ],
            [
              -81.745705,
              40.800569
            ],
            [
              -81.746191,
              40.829565
            ],
            [
              -81.715398,
              40.836439
            ],
            [
              -81.676729,
              40.836362
            ],
            [
              -81.658344,
              40.843947
            ],
            [
              -81.656039,
              40.889163
            ],
            [
              -81.647733,
              40.911387
            ],
            [
              -81.647719,
              40.948153
            ],
            [
              -81.616126,
              40.947944
            ],
            [
              -81.605444,
              40.988755
            ],
            [
              -81.590859,
              40.985917
            ],
            [
              -81.594481,
              40.913539
            ],
            [
              -81.536441,
              40.910312
            ],
            [
              -81.531639,
              40.988655
            ],
            [
              -81.302728,
              40.988534
            ],
            [
              -81.09882,
              40.988074
            ],
            [
              -81.086312,
              40.988031
            ],
            [
              -81.086726,
              40.930126
            ],
            [
              -81.065006,
              40.92076
            ],
            [
              -81.049694,
              40.89639
            ],
            [
              -81.086723,
              40.887004
            ],
            [
              -81.086757,
              40.813936
            ],
            [
              -81.017825,
              40.813772
            ]
          ]
        ]
      },
      "properties": {
        "name": "Stark County",
        "teachers": 3672
      },
      "rdn": "us.oh.stark-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -81.300256,
              41.134137
            ],
            [
              -81.301237,
              41.064874
            ],
            [
              -81.302728,
              40.988534
            ],
            [
              -81.531639,
              40.988655
            ],
            [
              -81.536441,
              40.910312
            ],
            [
              -81.594481,
              40.913539
            ],
            [
              -81.590859,
              40.985917
            ],
            [
              -81.605444,
              40.988755
            ],
            [
              -81.616126,
              40.947944
            ],
            [
              -81.647719,
              40.948153
            ],
            [
              -81.647689,
              40.988563
            ],
            [
              -81.688491,
              40.98859
            ],
            [
              -81.687051,
              41.127962
            ],
            [
              -81.654012,
              41.121385
            ],
            [
              -81.651002,
              41.142069
            ],
            [
              -81.604171,
              41.143361
            ],
            [
              -81.604631,
              41.102818
            ],
            [
              -81.588342,
              41.095434
            ],
            [
              -81.589243,
              41.041455
            ],
            [
              -81.568289,
              41.021735
            ],
            [
              -81.53894,
              41.028455
            ],
            [
              -81.491821,
              41.029207
            ],
            [
              -81.453735,
              41.019396
            ],
            [
              -81.450009,
              41.040367
            ],
            [
              -81.424598,
              41.040325
            ],
            [
              -81.428653,
              41.084676
            ],
            [
              -81.465751,
              41.093486
            ],
            [
              -81.466043,
              41.117189
            ],
            [
              -81.519538,
              41.123955
            ],
            [
              -81.519438,
              41.135855
            ],
            [
              -81.587417,
              41.13584
            ],
            [
              -81.586894,
              41.176657
            ],
            [
              -81.567541,
              41.199398
            ],
            [
              -81.602766,
              41.205199
            ],
            [
              -81.590569,
              41.226696
            ],
            [
              -81.587418,
              41.2776
            ],
            [
              -81.563147,
              41.278579
            ],
            [
              -81.585444,
              41.308864
            ],
            [
              -81.598052,
              41.351157
            ],
            [
              -81.488988,
              41.349753
            ],
            [
              -81.479406,
              41.327653
            ],
            [
              -81.479678,
              41.296166
            ],
            [
              -81.458855,
              41.276848
            ],
            [
              -81.391831,
              41.276654
            ],
            [
              -81.392114,
              41.135722
            ],
            [
              -81.300256,
              41.134137
            ]
          ]
        ]
      },
      "properties": {
        "name": "Summit County",
        "teachers": 4206
      },
      "rdn": "us.oh.summit"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -82.432852,
              40.992944
            ],
            [
              -82.436916,
              41.065379
            ],
            [
              -82.28994,
              41.065531
            ],
            [
              -82.296953,
              41.091798
            ],
            [
              -82.270217,
              41.079315
            ],
            [
              -82.271948,
              40.992817
            ],
            [
              -82.234577,
              40.992456
            ],
            [
              -82.207336,
              40.979412
            ],
            [
              -82.184726,
              40.981497
            ],
            [
              -82.169887,
              40.959124
            ],
            [
              -82.167737,
              40.930354
            ],
            [
              -82.127917,
              40.937169
            ],
            [
              -82.129334,
              40.991807
            ],
            [
              -82.078627,
              40.991153
            ],
            [
              -82.035962,
              41.000601
            ],
            [
              -81.996376,
              40.995852
            ],
            [
              -81.889565,
              40.99511
            ],
            [
              -81.866522,
              41.009865
            ],
            [
              -81.851153,
              40.992781
            ],
            [
              -81.805484,
              40.988253
            ],
            [
              -81.688491,
              40.98859
            ],
            [
              -81.647689,
              40.988563
            ],
            [
              -81.647719,
              40.948153
            ],
            [
              -81.647733,
              40.911387
            ],
            [
              -81.656039,
              40.889163
            ],
            [
              -81.658344,
              40.843947
            ],
            [
              -81.676729,
              40.836362
            ],
            [
              -81.715398,
              40.836439
            ],
            [
              -81.746191,
              40.829565
            ],
            [
              -81.745705,
              40.800569
            ],
            [
              -81.765295,
              40.790848
            ],
            [
              -81.765505,
              40.726538
            ],
            [
              -81.67901,
              40.726537
            ],
            [
              -81.649241,
              40.730093
            ],
            [
              -81.649199,
              40.635106
            ],
            [
              -81.662002,
              40.627788
            ],
            [
              -81.669654,
              40.443866
            ],
            [
              -81.755027,
              40.445641
            ],
            [
              -81.772033,
              40.454085
            ],
            [
              -81.804523,
              40.446796
            ],
            [
              -81.847397,
              40.439732
            ],
            [
              -81.854262,
              40.425932
            ],
            [
              -81.894858,
              40.420557
            ],
            [
              -81.918483,
              40.405841
            ],
            [
              -81.934337,
              40.425623
            ],
            [
              -82.027744,
              40.439668
            ],
            [
              -82.035781,
              40.452611
            ],
            [
              -82.162103,
              40.455377
            ],
            [
              -82.142074,
              40.46976
            ],
            [
              -82.141716,
              40.491988
            ],
            [
              -82.18166,
              40.512297
            ],
            [
              -82.179069,
              40.573112
            ],
            [
              -82.234622,
              40.566472
            ],
            [
              -82.262983,
              40.53454
            ],
            [
              -82.323347,
              40.52604
            ],
            [
              -82.336962,
              40.555001
            ],
            [
              -82.338483,
              40.638502
            ],
            [
              -82.384556,
              40.638504
            ],
            [
              -82.386379,
              40.664166
            ],
            [
              -82.338739,
              40.669914
            ],
            [
              -82.338984,
              40.698538
            ],
            [
              -82.325387,
              40.726547
            ],
            [
              -82.378402,
              40.734219
            ],
            [
              -82.378953,
              40.787227
            ],
            [
              -82.398934,
              40.792702
            ],
            [
              -82.416847,
              40.814567
            ],
            [
              -82.416,
              40.901898
            ],
            [
              -82.339435,
              40.901925
            ],
            [
              -82.339686,
              40.940322
            ],
            [
              -82.330172,
              40.959694
            ],
            [
              -82.333304,
              40.992926
            ],
            [
              -82.432852,
              40.992944
            ]
          ]
        ]
      },
      "properties": {
        "name": "Tri-County",
        "teachers": 1505
      },
      "rdn": "us.oh.tri-county-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -81.003319,
              41.34786
            ],
            [
              -81.003125,
              41.501685
            ],
            [
              -80.713163,
              41.499762
            ],
            [
              -80.519225,
              41.499924
            ],
            [
              -80.519167,
              41.133388
            ],
            [
              -80.723729,
              41.133955
            ],
            [
              -80.758034,
              41.127785
            ],
            [
              -80.783716,
              41.134156
            ],
            [
              -80.953892,
              41.134213
            ],
            [
              -80.973416,
              41.14913
            ],
            [
              -81.00229,
              41.134189
            ],
            [
              -81.002572,
              41.192746
            ],
            [
              -81.002758,
              41.229527
            ],
            [
              -81.00288,
              41.271842
            ],
            [
              -81.003319,
              41.34786
            ]
          ]
        ]
      },
      "properties": {
        "name": "Trumbull County",
        "teachers": 1784
      },
      "rdn": "us.oh.trumbull-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.113333,
              39.588743
            ],
            [
              -84.097015,
              39.577137
            ],
            [
              -84.000112,
              39.568821
            ],
            [
              -83.979443,
              39.544116
            ],
            [
              -84.011695,
              39.523001
            ],
            [
              -84.038789,
              39.491724
            ],
            [
              -84.051797,
              39.489118
            ],
            [
              -84.08887,
              39.460619
            ],
            [
              -84.102337,
              39.416399
            ],
            [
              -84.063527,
              39.418615
            ],
            [
              -84.05344,
              39.39523
            ],
            [
              -84.030575,
              39.40056
            ],
            [
              -84.028338,
              39.360968
            ],
            [
              -84.004068,
              39.338556
            ],
            [
              -84.004119,
              39.288404
            ],
            [
              -84.014463,
              39.272079
            ],
            [
              -84.046074,
              39.257468
            ],
            [
              -84.072728,
              39.283056
            ],
            [
              -84.087902,
              39.274777
            ],
            [
              -84.108724,
              39.295615
            ],
            [
              -84.142788,
              39.282836
            ],
            [
              -84.133658,
              39.262962
            ],
            [
              -84.201802,
              39.267167
            ],
            [
              -84.232544,
              39.264805
            ],
            [
              -84.262461,
              39.288379
            ],
            [
              -84.307268,
              39.29244
            ],
            [
              -84.304427,
              39.30743
            ],
            [
              -84.278969,
              39.341954
            ],
            [
              -84.267795,
              39.39982
            ],
            [
              -84.286453,
              39.394286
            ],
            [
              -84.343054,
              39.397814
            ],
            [
              -84.33984,
              39.427458
            ],
            [
              -84.325179,
              39.444193
            ],
            [
              -84.325313,
              39.476625
            ],
            [
              -84.350478,
              39.461442
            ],
            [
              -84.393461,
              39.465004
            ],
            [
              -84.442106,
              39.481767
            ],
            [
              -84.423043,
              39.497457
            ],
            [
              -84.406643,
              39.536557
            ],
            [
              -84.364633,
              39.544668
            ],
            [
              -84.365232,
              39.589493
            ],
            [
              -84.326785,
              39.589313
            ],
            [
              -84.32752,
              39.614832
            ],
            [
              -84.294775,
              39.614431
            ],
            [
              -84.28574,
              39.589158
            ],
            [
              -84.114195,
              39.577983
            ],
            [
              -84.113333,
              39.588743
            ]
          ]
        ]
      },
      "properties": {
        "name": "Warren County",
        "teachers": 1880
      },
      "rdn": "us.oh.warren-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -84.803492,
              41.252562
            ],
            [
              -84.457217,
              41.253485
            ],
            [
              -84.457059,
              41.209434
            ],
            [
              -84.399529,
              41.209281
            ],
            [
              -84.390492,
              41.194668
            ],
            [
              -84.362999,
              41.209266
            ],
            [
              -84.341607,
              41.20906
            ],
            [
              -84.331905,
              41.165441
            ],
            [
              -84.341612,
              41.158013
            ],
            [
              -84.341911,
              40.990692
            ],
            [
              -84.370105,
              40.990531
            ],
            [
              -84.380065,
              40.968919
            ],
            [
              -84.399135,
              40.965336
            ],
            [
              -84.398407,
              40.903265
            ],
            [
              -84.39801,
              40.844873
            ],
            [
              -84.417297,
              40.844794
            ],
            [
              -84.416217,
              40.79388
            ],
            [
              -84.456336,
              40.800965
            ],
            [
              -84.456172,
              40.728306
            ],
            [
              -84.689163,
              40.727705
            ],
            [
              -84.688879,
              40.786001
            ],
            [
              -84.727319,
              40.786091
            ],
            [
              -84.727327,
              40.771555
            ],
            [
              -84.802474,
              40.771652
            ],
            [
              -84.803313,
              40.989209
            ],
            [
              -84.803492,
              41.252562
            ]
          ]
        ]
      },
      "properties": {
        "name": "Western Buckeye",
        "teachers": 506
      },
      "rdn": "us.oh.western-esc"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -83.415919,
              41.618938
            ],
            [
              -83.414577,
              41.515001
            ],
            [
              -83.41606,
              41.39919
            ],
            [
              -83.426646,
              41.361763
            ],
            [
              -83.417139,
              41.348282
            ],
            [
              -83.445339,
              41.352467
            ],
            [
              -83.448192,
              41.283289
            ],
            [
              -83.466957,
              41.280076
            ],
            [
              -83.477678,
              41.253668
            ],
            [
              -83.477355,
              41.224346
            ],
            [
              -83.487934,
              41.202865
            ],
            [
              -83.478615,
              41.166054
            ],
            [
              -83.535084,
              41.166196
            ],
            [
              -83.545063,
              41.137649
            ],
            [
              -83.573331,
              41.137888
            ],
            [
              -83.581865,
              41.167174
            ],
            [
              -83.765123,
              41.168345
            ],
            [
              -83.765858,
              41.244013
            ],
            [
              -83.77523,
              41.255525
            ],
            [
              -83.862319,
              41.255203
            ],
            [
              -83.882206,
              41.247598
            ],
            [
              -83.883226,
              41.40082
            ],
            [
              -83.917211,
              41.400737
            ],
            [
              -83.911955,
              41.444181
            ],
            [
              -83.892669,
              41.444092
            ],
            [
              -83.882897,
              41.478841
            ],
            [
              -83.826116,
              41.473667
            ],
            [
              -83.814143,
              41.454469
            ],
            [
              -83.754529,
              41.462824
            ],
            [
              -83.712843,
              41.492494
            ],
            [
              -83.660822,
              41.499264
            ],
            [
              -83.646117,
              41.474905
            ],
            [
              -83.592558,
              41.499923
            ],
            [
              -83.567666,
              41.484265
            ],
            [
              -83.528894,
              41.487501
            ],
            [
              -83.527572,
              41.601856
            ],
            [
              -83.542989,
              41.617904
            ],
            [
              -83.415919,
              41.618938
            ]
          ]
        ]
      },
      "properties": {
        "name": "Wood County",
        "teachers": 661
      },
      "rdn": "us.oh.wood-esc"
    }
  ]
};