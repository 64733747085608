import {put} from "redux-saga/effects";
import {loadSoles, setSoles, solesLoading, solesLoadingFailed} from "../redux/SolesCreators";
import Parse from "parse";
import {AddSoleAction, DeleteSoleAction, SetSolesAction} from "../redux/SolesTypes";

export function* loadSolesSaga() {
    yield put(solesLoading());
    try {
        const solesJson = localStorage.getItem('soles') as string;
        if (solesJson) {
            try {
                yield put(setSoles(JSON.parse(solesJson)));
            } catch (error) {
            }
        }

        //TODO: @Steffen handle cases where users have a lot of SOLEs
        const soles = yield Parse.Cloud.run("webapp.getSolesWithQuestions", {
            offset: 0,
            limit: 100
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(setSoles(soles));
    } catch (error) {
        yield put(solesLoadingFailed(error.message));
    }
}

export function* setSolesSaga(action: SetSolesAction) {
    localStorage.setItem('soles', JSON.stringify(action.soles));
}

export function* deleteSoleSaga(action: DeleteSoleAction) {
    try {
        yield Parse.Cloud.run("webapp.deleteSole", {
            id: action.soleId
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(loadSoles());
    } catch (error) {
        yield put(solesLoadingFailed(error.message));
    }
}

export function* addSoleSaga(action: AddSoleAction) {
    yield Parse.Cloud.run('event.trigger', {rdn: 'session.plan.start', isActivity:false, objectId: action.soleId, className:'Sole' }, {
        sessionToken: Parse.User.current()?.getSessionToken()
    });
    yield Parse.Cloud.run('event.trigger', {rdn: 'session.plan.done', isActivity:false, objectId: action.soleId, className:'Sole' }, {
        sessionToken: Parse.User.current()?.getSessionToken()
    });
}