import {ISole} from "../../shared/soleTypes";
import React, {useEffect, useState} from "react";
import {Box, createStyles, Grid, Paper, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {match, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import {SessionContextItem} from "../../redux/SessionTypes";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {useSessionContext} from "../../hooks/useSessionContext";
import moment from "moment";
import ObservationCard from "./ObservationCardComponent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paddingTop: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(7),
                paddingRight: theme.spacing(7),
            },
        }
    }),
);

interface Identifiable {
    id: string
}

interface SummaryProps {
    match?: match<Identifiable>
}

const Summary: React.FC<SummaryProps> = (props: SummaryProps) => {
    const getLabel = useTranslationLabel();
    const classes = useStyles();
    const soles = useSelector((state: AppState) => state.soles.soles);
    const [sole, setSole] = useState<ISole | undefined>(soles.find(sole => sole.id === props.match?.params.id ?? ''));
    const texts = useSelector((state: AppState) => state.text.texts);
    const [sessionContext, setSessionContext] = useSessionContext([]);
    const history = useHistory();

    useEffect(() => {
        let context: SessionContextItem[] = [
            {
                callback(): void {
                    history.push('/soles');
                },
                text: getLabel('ui.my_soles')!
            }
        ];
        if (sole?.question?.text) {
            context.push({
                callback(): void { },
                text: sole?.question?.text
            });
        }
        setSessionContext(context);
    }, [sole]);

    if (sole === undefined) {
        return <div/>;
    } else {
        return <Box mt={5}>
        <Paper className={classes.paddingTop}>
                <Typography
                    variant={'h4'}
                    align={'center'}
                    gutterBottom={true}
                >
                    {sole.question?.text}
                </Typography>
            <Grid container spacing={3} justify={'center'} alignItems={'stretch'}>
            {sole.observations?.filter(obs => (obs.img)).map(obs =>
                <Grid item key={obs.id} xs={12} sm={6} md={4}>
                    <ObservationCard observation={obs}/>
                </Grid>)}
            </Grid>
            {/*TODO: when this is completed don't forget to add the appropriate text to clientTexts*/}
            <Typography variant={'body1'} gutterBottom={true}>Date: {moment(sole.plannedDate).format('LL')}</Typography>
            <Typography variant={'body1'} gutterBottom={true}>Devices: {sole.numDevices}</Typography>
            </Paper>
        </Box>
        ;
    }
};

export default Summary;
