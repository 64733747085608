import {ISole} from "../shared/soleTypes";

export enum SolesActionType {
    SolesLoad = "SolesLoad",
    SolesLoading = "SolesLoading",
    SolesLoadingFailed = "SolesLoadingFailed",
    SetSoles = "SetSoles",
    DeleteSole = "DeleteSole",
    AddSole = "AddSole"
}

interface SoleLoadAction {
    type: typeof SolesActionType.SolesLoad
}

interface SolesLoadingAction {
    type: typeof SolesActionType.SolesLoading
}

interface SolesLoadingFailedAction {
    type: typeof SolesActionType.SolesLoadingFailed,
    errorMessage: string
}

export interface SetSolesAction {
    type: typeof SolesActionType.SetSoles,
    soles: ISole[]
}

export interface DeleteSoleAction {
    type: typeof SolesActionType.DeleteSole,
    soleId: string
}

export interface AddSoleAction {
    type: typeof SolesActionType.AddSole,
    soleId: string
}

export type SolesActions =
    SoleLoadAction |
    SolesLoadingAction |
    SolesLoadingFailedAction |
    SetSolesAction |
    DeleteSoleAction |
    AddSoleAction
