import React from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import Lottie from "react-lottie";
import errorAnimation from '../components/common/error-animation.json';
import {useTranslationLabel} from "../hooks/useTranslation";

interface ErrorProps {
    text?: string,
    fullHeight?: boolean
}


const Error: React.FC<ErrorProps> = ({text, fullHeight}) => {
    const getLabel = useTranslationLabel();
    return <Grid container justify="center" alignItems="center" direction="column">
        <Grid item xs={10} sm={8} md={6} lg={4}>
            <Lottie options={{
                loop: false,
                autoplay: true,
                animationData: errorAnimation,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            }}
                    height={'100%'}
                    width={'100%'}/>
        </Grid>
            <Typography variant="h6" component="h4">
                {text ?? 'Something went wrong!'}
            </Typography>
            <Button variant="contained" color="secondary" href={'mailto:support@startsole.org'}>
                {getLabel('ui.support')}
            </Button>

    </Grid>


};

export default Error;
