import {ReportActions, ReportActionType,} from "./ReportTypes";
import {IReport} from "../shared/soleTypes";

export interface ReportState {
    isLoading: boolean,
    errorMessage?: string,
    report?: IReport
}

const initialReportState: ReportState = {
    isLoading: false,
    errorMessage: undefined,
    report: undefined
};

export const ReportReducer = (state = initialReportState, action: ReportActions): ReportState => {
    switch (action.type) {
        case ReportActionType.LoadReport:
            return {
                ...state,
                isLoading: true,
                errorMessage: undefined
            };
        case ReportActionType.SetReport:
            return {
                ...state,
                report:action.report
            };
        case ReportActionType.LoadReportFinished:
            return {
                ...state,
                isLoading: false,
                errorMessage: undefined
            };
        case ReportActionType.LoadReportFailed:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};
