import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {match} from "react-router-dom";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Box, Grid, Link, Theme} from "@material-ui/core";
import {loadReport} from "../../redux/ReportCreators";
import LineGraph from "./LineGraph";
import {AppState} from "../../redux/configureStore";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {DataGrid, GridValueFormatterParams} from "@material-ui/data-grid";
import Loading from '../LoadingComponent';
import PieGraph from "./PieGraph";
import MapChart from "./Map";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

let inputLabels = [
    { key: "2015", color: "#8884d8" },
    { key: "2016", color: "#82ca9d" },
    { key: "2017", color: "#81cc2d" },
    { key: "2018", color: "#d05742" },
    { key: "2019", color: "#73ef99" },
    { key: "2020", color: "#53bb51" },
    { key: "2021", color: "#c76e14" }
];

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        padded: {
            padding: theme.spacing(3),
            textAlign: 'center'
        }
    })
);

interface Identifiable {
    id: string
}

interface DashboardProps {
    match?: match<Identifiable>
}

const Dashboard: React.FC<DashboardProps> = props => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();

    const report = useSelector((state: AppState) => state.report.report);
    const errorMessage = useSelector((state: AppState) => state.report.errorMessage);
    const isLoading = useSelector((state: AppState) => state.report.isLoading);
    const ringRdn = props.match?.params.id ?? 'us.oh';
    const showMap = (ringRdn === 'us.oh' || ringRdn === 'us.pa');

    useEffect(() => {
//        dispatch(loadReport( "report.solesCount", "us.oh", {from:new Date("2000-1-1"), to:new Date("2100-1-1"), interval:"day"}));
        dispatch(loadReport( "report.getDashboardData",  ringRdn, {}));
    }, []);

    const handleChange = (event: React.ChangeEvent<{}> | undefined, newValue: number) => {
        setValue(newValue);
    };

    const UsersPerMonth = () => {
        const data = {
            dataKey: "name",
            oyLabel: getLabel('ui.admin.users_per_month_y_axis'),
            oxLabel: getLabel('ui.admin.users_per_month_x_axis'),
            yLimit: [0, 10],
            values:[{}]
        };

        if (report && report.data !== undefined) {
            data.values = [
                {
                    name: 'January',
                    2015: report.data.userCountByMonth['2015'][0]>0 ? report.data.userCountByMonth['2015'][0] : null,
                    2016: report.data.userCountByMonth['2016'][0]>0 ? report.data.userCountByMonth['2016'][0] : null,
                    2017: report.data.userCountByMonth['2017'][0]>0 ? report.data.userCountByMonth['2017'][0] : null,
                    2018: report.data.userCountByMonth['2018'][0]>0 ? report.data.userCountByMonth['2018'][0] : null,
                    2019: report.data.userCountByMonth['2019'][0]>0 ? report.data.userCountByMonth['2019'][0] : null,
                    2020: report.data.userCountByMonth['2020'][0]>0 ? report.data.userCountByMonth['2020'][0] : null,
                    2021: report.data.userCountByMonth['2021'][0]>0 ? report.data.userCountByMonth['2021'][0] : null
                },
                {
                    name: 'February',
                    2015: report.data.userCountByMonth['2015'][1]>0 ? report.data.userCountByMonth['2015'][1] : null,
                    2016: report.data.userCountByMonth['2016'][1]>0 ? report.data.userCountByMonth['2016'][1] : null,
                    2017: report.data.userCountByMonth['2017'][1]>0 ? report.data.userCountByMonth['2017'][1] : null,
                    2018: report.data.userCountByMonth['2018'][1]>0 ? report.data.userCountByMonth['2018'][1] : null,
                    2019: report.data.userCountByMonth['2019'][1]>0 ? report.data.userCountByMonth['2019'][1] : null,
                    2020: report.data.userCountByMonth['2020'][1]>0 ? report.data.userCountByMonth['2020'][1] : null,
                    2021: report.data.userCountByMonth['2021'][1]>0 ? report.data.userCountByMonth['2021'][1] : null
                },
                {
                    name: 'March',
                    2015: report.data.userCountByMonth['2015'][2]>0 ? report.data.userCountByMonth['2015'][2] : null,
                    2016: report.data.userCountByMonth['2016'][2]>0 ? report.data.userCountByMonth['2016'][2] : null,
                    2017: report.data.userCountByMonth['2017'][2]>0 ? report.data.userCountByMonth['2017'][2] : null,
                    2018: report.data.userCountByMonth['2018'][2]>0 ? report.data.userCountByMonth['2018'][2] : null,
                    2019: report.data.userCountByMonth['2019'][2]>0 ? report.data.userCountByMonth['2019'][2] : null,
                    2020: report.data.userCountByMonth['2020'][2]>0 ? report.data.userCountByMonth['2020'][2] : null,
                    2021: report.data.userCountByMonth['2021'][2]>0 ? report.data.userCountByMonth['2021'][2] : null
                },
                {
                    name: 'April',
                    2015: report.data.userCountByMonth['2015'][3]>0 ? report.data.userCountByMonth['2015'][3] : null,
                    2016: report.data.userCountByMonth['2016'][3]>0 ? report.data.userCountByMonth['2016'][3] : null,
                    2017: report.data.userCountByMonth['2017'][3]>0 ? report.data.userCountByMonth['2017'][3] : null,
                    2018: report.data.userCountByMonth['2018'][3]>0 ? report.data.userCountByMonth['2018'][3] : null,
                    2019: report.data.userCountByMonth['2019'][3]>0 ? report.data.userCountByMonth['2019'][3] : null,
                    2020: report.data.userCountByMonth['2020'][3]>0 ? report.data.userCountByMonth['2020'][3] : null,
                    2021: report.data.userCountByMonth['2021'][3]>0 ? report.data.userCountByMonth['2021'][3] : null
                },
                {
                    name: 'May',
                    2015: report.data.userCountByMonth['2015'][4]>0 ? report.data.userCountByMonth['2015'][4] : null,
                    2016: report.data.userCountByMonth['2016'][4]>0 ? report.data.userCountByMonth['2016'][4] : null,
                    2017: report.data.userCountByMonth['2017'][4]>0 ? report.data.userCountByMonth['2017'][4] : null,
                    2018: report.data.userCountByMonth['2018'][4]>0 ? report.data.userCountByMonth['2018'][4] : null,
                    2019: report.data.userCountByMonth['2019'][4]>0 ? report.data.userCountByMonth['2019'][4] : null,
                    2020: report.data.userCountByMonth['2020'][4]>0 ? report.data.userCountByMonth['2020'][4] : null,
                    2021: report.data.userCountByMonth['2021'][4]>0 ? report.data.userCountByMonth['2021'][4] : null
                },
                {
                    name: 'June',
                    2015: report.data.userCountByMonth['2015'][5]>0 ? report.data.userCountByMonth['2015'][5] : null,
                    2016: report.data.userCountByMonth['2016'][5]>0 ? report.data.userCountByMonth['2016'][5] : null,
                    2017: report.data.userCountByMonth['2017'][5]>0 ? report.data.userCountByMonth['2017'][5] : null,
                    2018: report.data.userCountByMonth['2018'][5]>0 ? report.data.userCountByMonth['2018'][5] : null,
                    2019: report.data.userCountByMonth['2019'][5]>0 ? report.data.userCountByMonth['2019'][5] : null,
                    2020: report.data.userCountByMonth['2020'][5]>0 ? report.data.userCountByMonth['2020'][5] : null,
                    2021: report.data.userCountByMonth['2021'][5]>0 ? report.data.userCountByMonth['2021'][5] : null
                },
                {
                    name: 'July',
                    2015: report.data.userCountByMonth['2015'][6]>0 ? report.data.userCountByMonth['2015'][6] : null,
                    2016: report.data.userCountByMonth['2016'][6]>0 ? report.data.userCountByMonth['2016'][6] : null,
                    2017: report.data.userCountByMonth['2017'][6]>0 ? report.data.userCountByMonth['2017'][6] : null,
                    2018: report.data.userCountByMonth['2018'][6]>0 ? report.data.userCountByMonth['2018'][6] : null,
                    2019: report.data.userCountByMonth['2019'][6]>0 ? report.data.userCountByMonth['2019'][6] : null,
                    2020: report.data.userCountByMonth['2020'][6]>0 ? report.data.userCountByMonth['2020'][6] : null,
                    2021: report.data.userCountByMonth['2021'][6]>0 ? report.data.userCountByMonth['2021'][6] : null
                },
                {
                    name: 'August',
                    2015: report.data.userCountByMonth['2015'][7]>0 ? report.data.userCountByMonth['2015'][7] : null,
                    2016: report.data.userCountByMonth['2016'][7]>0 ? report.data.userCountByMonth['2016'][7] : null,
                    2017: report.data.userCountByMonth['2017'][7]>0 ? report.data.userCountByMonth['2017'][7] : null,
                    2018: report.data.userCountByMonth['2018'][7]>0 ? report.data.userCountByMonth['2018'][7] : null,
                    2019: report.data.userCountByMonth['2019'][7]>0 ? report.data.userCountByMonth['2019'][7] : null,
                    2020: report.data.userCountByMonth['2020'][7]>0 ? report.data.userCountByMonth['2020'][7] : null,
                    2021: report.data.userCountByMonth['2021'][7]>0 ? report.data.userCountByMonth['2021'][7] : null
                },
                {
                    name: 'September',
                    2015: report.data.userCountByMonth['2015'][8]>0 ? report.data.userCountByMonth['2015'][8] : null,
                    2016: report.data.userCountByMonth['2016'][8]>0 ? report.data.userCountByMonth['2016'][8] : null,
                    2017: report.data.userCountByMonth['2017'][8]>0 ? report.data.userCountByMonth['2017'][8] : null,
                    2018: report.data.userCountByMonth['2018'][8]>0 ? report.data.userCountByMonth['2018'][8] : null,
                    2019: report.data.userCountByMonth['2019'][8]>0 ? report.data.userCountByMonth['2019'][8] : null,
                    2020: report.data.userCountByMonth['2020'][8]>0 ? report.data.userCountByMonth['2020'][8] : null,
                    2021: report.data.userCountByMonth['2021'][8]>0 ? report.data.userCountByMonth['2021'][8] : null
                },
                {
                    name: 'October',
                    2015: report.data.userCountByMonth['2015'][9]>0 ? report.data.userCountByMonth['2015'][9] : null,
                    2016: report.data.userCountByMonth['2016'][9]>0 ? report.data.userCountByMonth['2016'][9] : null,
                    2017: report.data.userCountByMonth['2017'][9]>0 ? report.data.userCountByMonth['2017'][9] : null,
                    2018: report.data.userCountByMonth['2018'][9]>0 ? report.data.userCountByMonth['2018'][9] : null,
                    2019: report.data.userCountByMonth['2019'][9]>0 ? report.data.userCountByMonth['2019'][9] : null,
                    2020: report.data.userCountByMonth['2020'][9]>0 ? report.data.userCountByMonth['2020'][9] : null,
                    2021: report.data.userCountByMonth['2021'][9]>0 ? report.data.userCountByMonth['2021'][9] : null
                },
                {
                    name: 'November',
                    2015: report.data.userCountByMonth['2015'][10]>0 ? report.data.userCountByMonth['2015'][10] : null,
                    2016: report.data.userCountByMonth['2016'][10]>0 ? report.data.userCountByMonth['2016'][10] : null,
                    2017: report.data.userCountByMonth['2017'][10]>0 ? report.data.userCountByMonth['2017'][10] : null,
                    2018: report.data.userCountByMonth['2018'][10]>0 ? report.data.userCountByMonth['2018'][10] : null,
                    2019: report.data.userCountByMonth['2019'][10]>0 ? report.data.userCountByMonth['2019'][10] : null,
                    2020: report.data.userCountByMonth['2020'][10]>0 ? report.data.userCountByMonth['2020'][10] : null,
                    2021: report.data.userCountByMonth['2021'][10]>0 ? report.data.userCountByMonth['2021'][10] : null
                },
                {
                    name: 'December',
                    2015: report.data.userCountByMonth['2015'][11]>0 ? report.data.userCountByMonth['2015'][11] : null,
                    2016: report.data.userCountByMonth['2016'][11]>0 ? report.data.userCountByMonth['2016'][11] : null,
                    2017: report.data.userCountByMonth['2017'][11]>0 ? report.data.userCountByMonth['2017'][11] : null,
                    2018: report.data.userCountByMonth['2018'][11]>0 ? report.data.userCountByMonth['2018'][11] : null,
                    2019: report.data.userCountByMonth['2019'][11]>0 ? report.data.userCountByMonth['2019'][11] : null,
                    2020: report.data.userCountByMonth['2020'][11]>0 ? report.data.userCountByMonth['2020'][11] : null,
                    2021: report.data.userCountByMonth['2021'][11]>0 ? report.data.userCountByMonth['2021'][11] : null
                }
            ];
        }
        else if(isLoading || errorMessage) {
            data.values = [];
        }

        return data;
    };

    const SolesPerMonth = () => {
        const data = {
            dataKey: "name",
            oyLabel: "new SOLEs",
            oxLabel: "months",
            yLimit: [0, 10],
            values:[{}]
        };

        if (report && report.data !== undefined) {
            data.values = [
                {
                    name: 'January',
                    2015: report.data.soleCountByMonth['2015'][0]>0 ? report.data.soleCountByMonth['2015'][0] : null,
                    2016: report.data.soleCountByMonth['2016'][0]>0 ? report.data.soleCountByMonth['2016'][0] : null,
                    2017: report.data.soleCountByMonth['2017'][0]>0 ? report.data.soleCountByMonth['2017'][0] : null,
                    2018: report.data.soleCountByMonth['2018'][0]>0 ? report.data.soleCountByMonth['2018'][0] : null,
                    2019: report.data.soleCountByMonth['2019'][0]>0 ? report.data.soleCountByMonth['2019'][0] : null,
                    2020: report.data.soleCountByMonth['2020'][0]>0 ? report.data.soleCountByMonth['2020'][0] : null,
                    2021: report.data.soleCountByMonth['2021'][0]>0 ? report.data.soleCountByMonth['2021'][0] : null
                },
                {
                    name: 'February',
                    2015: report.data.soleCountByMonth['2015'][1]>0 ? report.data.soleCountByMonth['2015'][1] : null,
                    2016: report.data.soleCountByMonth['2016'][1]>0 ? report.data.soleCountByMonth['2016'][1] : null,
                    2017: report.data.soleCountByMonth['2017'][1]>0 ? report.data.soleCountByMonth['2017'][1] : null,
                    2018: report.data.soleCountByMonth['2018'][1]>0 ? report.data.soleCountByMonth['2018'][1] : null,
                    2019: report.data.soleCountByMonth['2019'][1]>0 ? report.data.soleCountByMonth['2019'][1] : null,
                    2020: report.data.soleCountByMonth['2020'][1]>0 ? report.data.soleCountByMonth['2020'][1] : null,
                    2021: report.data.soleCountByMonth['2021'][1]>0 ? report.data.soleCountByMonth['2021'][1] : null
                },
                {
                    name: 'March',
                    2015: report.data.soleCountByMonth['2015'][2]>0 ? report.data.soleCountByMonth['2015'][2] : null,
                    2016: report.data.soleCountByMonth['2016'][2]>0 ? report.data.soleCountByMonth['2016'][2] : null,
                    2017: report.data.soleCountByMonth['2017'][2]>0 ? report.data.soleCountByMonth['2017'][2] : null,
                    2018: report.data.soleCountByMonth['2018'][2]>0 ? report.data.soleCountByMonth['2018'][2] : null,
                    2019: report.data.soleCountByMonth['2019'][2]>0 ? report.data.soleCountByMonth['2019'][2] : null,
                    2020: report.data.soleCountByMonth['2020'][2]>0 ? report.data.soleCountByMonth['2020'][2] : null,
                    2021: report.data.soleCountByMonth['2021'][2]>0 ? report.data.soleCountByMonth['2021'][2] : null
                },
                {
                    name: 'April',
                    2015: report.data.soleCountByMonth['2015'][3]>0 ? report.data.soleCountByMonth['2015'][3] : null,
                    2016: report.data.soleCountByMonth['2016'][3]>0 ? report.data.soleCountByMonth['2016'][3] : null,
                    2017: report.data.soleCountByMonth['2017'][3]>0 ? report.data.soleCountByMonth['2017'][3] : null,
                    2018: report.data.soleCountByMonth['2018'][3]>0 ? report.data.soleCountByMonth['2018'][3] : null,
                    2019: report.data.soleCountByMonth['2019'][3]>0 ? report.data.soleCountByMonth['2019'][3] : null,
                    2020: report.data.soleCountByMonth['2020'][3]>0 ? report.data.soleCountByMonth['2020'][3] : null,
                    2021: report.data.soleCountByMonth['2021'][3]>0 ? report.data.soleCountByMonth['2021'][3] : null
                },
                {
                    name: 'May',
                    2015: report.data.soleCountByMonth['2015'][4]>0 ? report.data.soleCountByMonth['2015'][4] : null,
                    2016: report.data.soleCountByMonth['2016'][4]>0 ? report.data.soleCountByMonth['2016'][4] : null,
                    2017: report.data.soleCountByMonth['2017'][4]>0 ? report.data.soleCountByMonth['2017'][4] : null,
                    2018: report.data.soleCountByMonth['2018'][4]>0 ? report.data.soleCountByMonth['2018'][4] : null,
                    2019: report.data.soleCountByMonth['2019'][4]>0 ? report.data.soleCountByMonth['2019'][4] : null,
                    2020: report.data.soleCountByMonth['2020'][4]>0 ? report.data.soleCountByMonth['2020'][4] : null,
                    2021: report.data.soleCountByMonth['2021'][4]>0 ? report.data.soleCountByMonth['2021'][4] : null
                },
                {
                    name: 'June',
                    2015: report.data.soleCountByMonth['2015'][5]>0 ? report.data.soleCountByMonth['2015'][5] : null,
                    2016: report.data.soleCountByMonth['2016'][5]>0 ? report.data.soleCountByMonth['2016'][5] : null,
                    2017: report.data.soleCountByMonth['2017'][5]>0 ? report.data.soleCountByMonth['2017'][5] : null,
                    2018: report.data.soleCountByMonth['2018'][5]>0 ? report.data.soleCountByMonth['2018'][5] : null,
                    2019: report.data.soleCountByMonth['2019'][5]>0 ? report.data.soleCountByMonth['2019'][5] : null,
                    2020: report.data.soleCountByMonth['2020'][5]>0 ? report.data.soleCountByMonth['2020'][5] : null,
                    2021: report.data.soleCountByMonth['2021'][5]>0 ? report.data.soleCountByMonth['2021'][5] : null
                },
                {
                    name: 'July',
                    2015: report.data.soleCountByMonth['2015'][6]>0 ? report.data.soleCountByMonth['2015'][6] : null,
                    2016: report.data.soleCountByMonth['2016'][6]>0 ? report.data.soleCountByMonth['2016'][6] : null,
                    2017: report.data.soleCountByMonth['2017'][6]>0 ? report.data.soleCountByMonth['2017'][6] : null,
                    2018: report.data.soleCountByMonth['2018'][6]>0 ? report.data.soleCountByMonth['2018'][6] : null,
                    2019: report.data.soleCountByMonth['2019'][6]>0 ? report.data.soleCountByMonth['2019'][6] : null,
                    2020: report.data.soleCountByMonth['2020'][6]>0 ? report.data.soleCountByMonth['2020'][6] : null,
                    2021: report.data.soleCountByMonth['2021'][6]>0 ? report.data.soleCountByMonth['2021'][6] : null
                },
                {
                    name: 'August',
                    2015: report.data.soleCountByMonth['2015'][7]>0 ? report.data.soleCountByMonth['2015'][7] : null,
                    2016: report.data.soleCountByMonth['2016'][7]>0 ? report.data.soleCountByMonth['2016'][7] : null,
                    2017: report.data.soleCountByMonth['2017'][7]>0 ? report.data.soleCountByMonth['2017'][7] : null,
                    2018: report.data.soleCountByMonth['2018'][7]>0 ? report.data.soleCountByMonth['2018'][7] : null,
                    2019: report.data.soleCountByMonth['2019'][7]>0 ? report.data.soleCountByMonth['2019'][7] : null,
                    2020: report.data.soleCountByMonth['2020'][7]>0 ? report.data.soleCountByMonth['2020'][7] : null,
                    2021: report.data.soleCountByMonth['2021'][7]>0 ? report.data.soleCountByMonth['2021'][7] : null
                },
                {
                    name: 'September',
                    2015: report.data.soleCountByMonth['2015'][8]>0 ? report.data.soleCountByMonth['2015'][8] : null,
                    2016: report.data.soleCountByMonth['2016'][8]>0 ? report.data.soleCountByMonth['2016'][8] : null,
                    2017: report.data.soleCountByMonth['2017'][8]>0 ? report.data.soleCountByMonth['2017'][8] : null,
                    2018: report.data.soleCountByMonth['2018'][8]>0 ? report.data.soleCountByMonth['2018'][8] : null,
                    2019: report.data.soleCountByMonth['2019'][8]>0 ? report.data.soleCountByMonth['2019'][8] : null,
                    2020: report.data.soleCountByMonth['2020'][8]>0 ? report.data.soleCountByMonth['2020'][8] : null,
                    2021: report.data.soleCountByMonth['2021'][8]>0 ? report.data.soleCountByMonth['2021'][8] : null
                },
                {
                    name: 'October',
                    2015: report.data.soleCountByMonth['2015'][9]>0 ? report.data.soleCountByMonth['2015'][9] : null,
                    2016: report.data.soleCountByMonth['2016'][9]>0 ? report.data.soleCountByMonth['2016'][9] : null,
                    2017: report.data.soleCountByMonth['2017'][9]>0 ? report.data.soleCountByMonth['2017'][9] : null,
                    2018: report.data.soleCountByMonth['2018'][9]>0 ? report.data.soleCountByMonth['2018'][9] : null,
                    2019: report.data.soleCountByMonth['2019'][9]>0 ? report.data.soleCountByMonth['2019'][9] : null,
                    2020: report.data.soleCountByMonth['2020'][9]>0 ? report.data.soleCountByMonth['2020'][9] : null,
                    2021: report.data.soleCountByMonth['2021'][9]>0 ? report.data.soleCountByMonth['2021'][9] : null
                },
                {
                    name: 'November',
                    2015: report.data.soleCountByMonth['2015'][10]>0 ? report.data.soleCountByMonth['2015'][10] : null,
                    2016: report.data.soleCountByMonth['2016'][10]>0 ? report.data.soleCountByMonth['2016'][10] : null,
                    2017: report.data.soleCountByMonth['2017'][10]>0 ? report.data.soleCountByMonth['2017'][10] : null,
                    2018: report.data.soleCountByMonth['2018'][10]>0 ? report.data.soleCountByMonth['2018'][10] : null,
                    2019: report.data.soleCountByMonth['2019'][10]>0 ? report.data.soleCountByMonth['2019'][10] : null,
                    2020: report.data.soleCountByMonth['2020'][10]>0 ? report.data.soleCountByMonth['2020'][10] : null,
                    2021: report.data.soleCountByMonth['2021'][10]>0 ? report.data.soleCountByMonth['2021'][10] : null
                },
                {
                    name: 'December',
                    2015: report.data.soleCountByMonth['2015'][11]>0 ? report.data.soleCountByMonth['2015'][11] : null,
                    2016: report.data.soleCountByMonth['2016'][11]>0 ? report.data.soleCountByMonth['2016'][11] : null,
                    2017: report.data.soleCountByMonth['2017'][11]>0 ? report.data.soleCountByMonth['2017'][11] : null,
                    2018: report.data.soleCountByMonth['2018'][11]>0 ? report.data.soleCountByMonth['2018'][11] : null,
                    2019: report.data.soleCountByMonth['2019'][11]>0 ? report.data.soleCountByMonth['2019'][11] : null,
                    2020: report.data.soleCountByMonth['2020'][11]>0 ? report.data.soleCountByMonth['2020'][11] : null,
                    2021: report.data.soleCountByMonth['2021'][11]>0 ? report.data.soleCountByMonth['2021'][11] : null
                }
            ];
        }
        else if(isLoading || errorMessage) {
            data.values = [];
        }

        return data;
    };

    const UsersBySubject = () => {
        let data = {
            values:[{}]
        };

        let otherTotal = 0;
        let allTotal = 0;

        if (report && report.data !== undefined) {
            let newValues = [];
            for (const subject in report.data.userCountBySubject) {
                //TODO: is there an easy way to get subjects in here, maybe with getLabel?
                switch(subject) {
                    case "top.careereducation" ||
                        "top.physicaleducation" ||
                        "top.engineering" ||
                        "top.englishlanguagedevelopment" ||
                        "top.financialliteracy" ||
                        "top.health" ||
                        "top.libmedia-infolit" ||
                        "top.lifeskills" ||
                        "top.technology" ||
                        "top.thearts" ||
                        "top.foreignlanguage":
                        otherTotal += report.data.userCountBySubject[subject];
                        break;
                    case "top.english":
                        newValues.push({
                            name: "English",
                            value: report.data.userCountBySubject[subject]
                        });
                        break;
                    case "top.math":
                        newValues.push({
                            name: "Math",
                            value: report.data.userCountBySubject[subject]
                        });
                        break;
                    case "top.science":
                        newValues.push({
                            name: "Science",
                            value: report.data.userCountBySubject[subject]
                        });
                        break;
                    case "top.socialstudies":
                        newValues.push({
                            name: "Social Studies",
                            value: report.data.userCountBySubject[subject]
                        });
                        break;
                    case "top.all" || "subjects.all":
                        allTotal += report.data.userCountBySubject[subject];
                        break;
                    default:
                    console.log('unknown subject');
                }
            }
            if(otherTotal>0){
                newValues.push({
                    name: "Other",
                    value: otherTotal
                });
            }
            if(allTotal>0){
                newValues.push({
                    name: "All Subjects",
                    value: allTotal
                });
            }
            data.values=newValues;
        }
        return data;
    };

    const UsersByGrade = () => {
        let data = {
            values:[{}]
        };

        if (report && report.data !== undefined) {
            let newValues = [
                {
                    name: "Pre-K & Kindergarten",
                    value: report.data.userCountByGrade["edu.pre-k"] + report.data.userCountByGrade["edu.k"]
                },
                {
                    name: "Grades 1-3",
                    value: report.data.userCountByGrade["edu.1"] + report.data.userCountByGrade["edu.2"] + report.data.userCountByGrade["edu.3"]
                },
                {
                    name: "Grades 4-6",
                    value: report.data.userCountByGrade["edu.4"] + report.data.userCountByGrade["edu.5"] + report.data.userCountByGrade["edu.6"]
                },
                {
                    name: "Grades 7-8",
                    value: report.data.userCountByGrade["edu.7"] + report.data.userCountByGrade["edu.8"]
                },
                {
                    name: "Grades 9-10",
                    value: report.data.userCountByGrade["edu.9"] + report.data.userCountByGrade["edu.10"]
                },
                {
                    name: "Grades 11-12",
                    value: report.data.userCountByGrade["edu.11"] + report.data.userCountByGrade["edu.12"]
                }
            ];
            data.values=newValues;
        }
        return data;
    };

    const SolesBySubject = () => {
        if(report && report.data !== undefined) {
            return {
                values:[
                    {
                        name: "English",
                        value: report.data.soleCountBySubjectBand[0]
                    },
                    {
                        name: "Math",
                        value: report.data.soleCountBySubjectBand[1]
                    },
                    {
                        name: "Science",
                        value: report.data.soleCountBySubjectBand[2]
                    },
                    {
                        name: "Social Studies",
                        value: report.data.soleCountBySubjectBand[3]
                    },
                    {
                        name: "Other",
                        value: report.data.soleCountBySubjectBand[4]
                    }
                ]
            };
        }
        else {
            return {
                values:[{}]
            };
        }
    };

    const SolesByGrade = () => {
        if(report && report.data !== undefined) {
            return {
                values:[
                    {
                        name: "Pre-K & Kindergarten",
                        value: report.data.soleCountByGradeBand[0]
                    },
                    {
                        name: "Grades 1-6",
                        value: report.data.soleCountByGradeBand[1]
                    },
                    {
                        name: "Grades 7-8",
                        value: report.data.soleCountByGradeBand[2]
                    },
                    {
                        name: "Grades 9-12",
                        value: report.data.soleCountByGradeBand[3]
                    },
                    {
                        name: "Other",
                        value: report.data.soleCountByGradeBand[4]
                    }
                ]
            };
        }
        else {
            return {
                values:[{}]
            };
        }
    };

    const schoolRows = report?.data?.schools.map((school:any, index:number) => {return {id: index, ...school}});

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={getLabel('ui.admin.educators')} {...a11yProps(0)} />
                <Tab label={getLabel('ui.admin.soles')} {...a11yProps(1)}/>
                <Tab label={getLabel('ui.admin.schools')} {...a11yProps(2)}/>
                {showMap ? <Tab label={getLabel('ui.admin.map')} {...a11yProps(3)}/> : <></>}
            </Tabs>
            <TabPanel value={value} index={0}>
                {report?.data ? <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Paper className={classes.padded}>
                            <LineGraph
                                title={getLabel('ui.admin.users_per_month_title')}
                                {...UsersPerMonth()}
                                labels={inputLabels}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.padded}>
                            <PieGraph
                                title={getLabel('ui.admin.users_by_subject')}
                                {...UsersBySubject()}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.padded}>
                            <PieGraph
                                title={getLabel('ui.admin.users_by_grade')}
                                {...UsersByGrade()}
                            />
                        </Paper>
                    </Grid>
                </Grid> : <Loading text={getLabel('ui.admin.loading_educators')}/>}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {report?.data ? <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Paper className={classes.padded}>
                            <LineGraph
                                title="SOLEs per Month"
                                {...SolesPerMonth()}
                                labels={inputLabels}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.padded}>
                            <PieGraph
                                title={getLabel('ui.admin.soles_by_subject')}
                                {...SolesBySubject()}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.padded}>
                            <PieGraph
                                title={getLabel('ui.admin.soles_by_grade')}
                                {...SolesByGrade()}
                            />
                        </Paper>
                    </Grid>
                </Grid> : <Loading text={getLabel('ui.admin.loading_soles')}/>}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {schoolRows ? <div style={{ height: 1400, width: '100%' }}><DataGrid
                    columns={
                        [
                            {
                                field: 'name',
                                headerName: 'Name',
                                flex:0.7,
                                renderCell: (params: GridValueFormatterParams) => (
                                    <Link href={"https://www.google.com/maps/search/?api=1&query="+ params.value +"&query_place_id=" + params.row.placeId} target={'_blank'}>
                                        {params.value}
                                    </Link>
                                )
                            },
                            { field: 'nUsers', headerName: 'User' },
                            { field: 'nSoles', headerName: 'Soles' },
                            // { field: 'placeId', headerName: 'PlaceID' }
                        ]
                    }
                    rows={ schoolRows }
                    pageSize={25}
                    autoHeight
                /></div> : <Loading text={getLabel('ui.admin.loading_schools')} fullHeight={true}></Loading>}
            </TabPanel>
            {showMap ?
                <TabPanel value={value} index={3}>
                    <MapChart ring={ringRdn} onChange={handleChange}/>
                </TabPanel> :
                <></>
            }
        </div>
    );
};

export default Dashboard;
