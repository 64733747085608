import React, {useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../redux/configureStore";
import {IResource} from "../shared/soleTypes";
import SelectionComponent from "./input/SelectionComponent";
import {SelectionConstraints, StringConstraints} from "../data/constraints/Constraints";
import StringComponent from "./input/StringComponent";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import {useTranslationLabel} from "../hooks/useTranslation";
import {useSessionContext} from "../hooks/useSessionContext";
import {getRingProps} from "../theme/RingProps";

interface ResourceProps {
    resource: IResource,
    downloadLabel?: string,
    downloadUrl?: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterField: {
            minWidth: '300px',
            maxWidth: '400px'
        },
        root: {
            display: 'flex',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                maxWidth: 400,
            },
            margin: 'auto',
            width: 400
        },
        details: {
            [theme.breakpoints.down('sm')]: {
                width: 200,
            },
            [theme.breakpoints.up('md')]: {
                width: 300,
            }
        },
        cardContent: {
          height: 212
        },
        image: {
            width: 200
        }
    }),
);

const Resource: React.FC<ResourceProps> = (props) => {
    const resource = props.resource;
    const classes = useStyles();
    const getLabel = useTranslationLabel();

    return <Grid item>
        <Card className={classes.root}>
            <div className={classes.details}>
                <CardContent className={classes.cardContent}>
                    <Typography component="h5" variant="h5">
                        {resource.title}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {resource.authors.map(author => {
                            return <div>{getLabel('ui.resources.shared_by')} <a href={"mailto:" + author.email}>{author.name}</a></div>
                        })}
                    </Typography>
                    <Typography variant="body2">{resource.short}</Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" href={"../resources/" + resource.file} download>
                        {props.downloadLabel ? props.downloadLabel : getLabel('ui.download')}
                    </Button>
                </CardActions>
            </div>
            <img className={classes.image} src={'/images/resources/' + resource.image} alt={resource.title}/>
        </Card>
    </Grid>
};

const Resources: React.FC = () => {
    const classes = useStyles();
    const pub = useSelector((state: AppState) => state.user.pub)!;
    const ringProps = getRingProps(pub);
    const resources = useSelector((state: AppState) => state.resources.resources);
    let resourceTags = useSelector((state: AppState) => state.resources.resourceTags);
    resourceTags = ringProps.resourceTags ? [...resourceTags, ...ringProps.resourceTags] : resourceTags;
    const [selectedTag, setSelectedTag] = useState<string | undefined>();
    const [searchText, setSearchText] = useState<string | undefined>();
    const getLabel = useTranslationLabel();
    useSessionContext([
        {
            callback(): void { },
            text: getLabel('ui.teacher_resources')!
        }
    ]);
    const selectionConstraint = new SelectionConstraints();
    selectionConstraint.min = 0;
    selectionConstraint.max = 1;
    selectionConstraint.items = resourceTags
        .map(tag => {
            return {rdn: tag.key, title: tag.text}
        });

    const searchConstraint = new StringConstraints();

    const list = resources
        .filter(resource => (selectedTag === undefined || selectedTag === 'show-all' || resource.tags.includes(selectedTag)) &&
            (searchText === undefined || resource.short.includes(searchText) || resource.title.includes(searchText) || resource.description.includes(searchText))
        )
        .map(r => <Resource resource={r}/>);

    list.push(<Resource resource={{
        title: 'Add Your Resource',
        image: 'your_resource_here.png',
        short: 'Do you have a great resource to share? Let us know!',
        authors: [],
        tags: [],
        description: '',
        file: ''
    }} downloadLabel={getLabel('ui.contact_us')} downloadUrl='mailto:support@startsole.org'/>);

    const onChangeFilter = (value: string | string[] | undefined) => {
        if (typeof value === 'string') {
            setSelectedTag(value as string);
        }
    };

    const onChangeSearchText = (value: string | undefined) => {
        setSearchText(value);
    };

    return <>
        <Grid container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
        >
            <Grid item sm={12} md={6} className={classes.filterField}>
                <StringComponent constraint={searchConstraint} label='Search by keyword' rdn='search'
                                 onChange={onChangeSearchText}/>
            </Grid>
            <Grid item sm={12} md={6} className={classes.filterField}>
                <SelectionComponent initialValue="show-all" rdn='tags' constraint={selectionConstraint}
                                    onChange={onChangeFilter}/>
            </Grid>
        </Grid>
        <Grid container spacing={3} justify="center"
              alignItems="stretch">
            {list}
        </Grid>
    </>;
};

export default Resources;
