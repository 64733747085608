import React, {useState} from 'react';
import {Box, Button, Card, CardActions, CardContent, CardMedia, Grid, makeStyles, Typography, Badge, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Paper, Container} from "@material-ui/core";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {Link as RouterLink, match} from "react-router-dom";
import {useSessionContext} from "../../hooks/useSessionContext";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";

const useStyles = makeStyles((theme:any) => ({
    marginAutoContainer: {
        height: `calc(80vh - ${theme.spacing(6)}px - ${theme.mixins.toolbar.minHeight}px - 8px)`
    },
    marginAutoItem: {
        margin: 'auto',
    },
    maxWidthCard: {
        maxWidth: '400px',
        margin: 'auto',
    },
    media: {
        objectFit: 'none',
        // padding: theme.spacing(2)
    }
}));

interface CommunityProps {
    match?: match<{ ringRdn: string }>
}

const CommunityHome: React.FC<CommunityProps> = (props) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const pub = useSelector((state: AppState) => state.user.pub);
    const ringRdn = props.match?.params.ringRdn;
    const ring = pub?.rings?.find(ring => ring.rdn === ringRdn);

    useSessionContext([
        {
            callback(): void {
            },
            text: getLabel('ui.my_community') ?? 'My Community'
        },
        {
            callback(): void {
            },
            text: ring?.name ?? 'My Ring'
        }
    ]);

    //TODO: make sure points are updated in AppState so the carousel is hidden appropriately
    return <Grid container className={classes.marginAutoContainer}>
        <Grid item xs={12} sm={12} md={6} className={classes.marginAutoItem}>

            <Card variant={'outlined'}>
                {/*<CardActionArea>*/}
                <CardMedia className={classes.media} component='img' image={"/images/logos/lake-erie-west-logo.png"}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" align={'center'}>
                        Welcome to your Community!
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    We can put in a bit of text here explaining the details of the partnership, something to situate users and make them feel comfortable.  This could also explain the program where users can earn points for completing asynchronous trainings, the get help button, etc.
                    </Typography>
                </CardContent>
                {/*</CardActionArea>*/}
                <CardActions>
                    <Grid container justify={'center'} alignItems={'center'} spacing={3}>
                        <Grid item>
                            <Button size={'small'} component={RouterLink} to="/community/us.oh.lakeeriewest/training">View Trainings</Button>
                        </Grid>
                        <Grid item>
                            <Button size={'small'} href="https://docs.google.com/forms/d/e/1FAIpQLSeuqAqDu4WZCy0PHI018D8TTNgx9Zz1YVx2lp9o73jWJB4E_A/viewform" target="_blank">Request Help</Button>
                        </Grid>

                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    </Grid>
};

export default CommunityHome;
