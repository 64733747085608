import {ISole, SoleState} from "../../shared/soleTypes";
import React from "react";
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {Link as RouterLink} from 'react-router-dom'
import {useTranslationLabel} from "../../hooks/useTranslation";
import Parse from "parse";
import {useDispatch} from "react-redux";
import {deleteSole} from "../../redux/SolesCreators";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: "6px"
        },
        action: {
            backgroundColor: theme.palette.primary.main,
        },
        title: {
            color: theme.palette.common.white
        },
        flipped: {
            direction: 'rtl'
        }
    })
);

const getDocumentUrl = async (id: string, type: string): Promise<string> => {
    const baseUrl = process.env.REACT_APP_FILE_BASE_URL ?? '';
    let url: string = await Parse.Cloud.run('webapp.getDownloadLink', {
        id: id,
        type: type
    }, {
        sessionToken: Parse.User.current()?.getSessionToken()
    });
    return baseUrl + url;
};

interface SoleCardProps {
    sole: ISole
}

const SoleCard: React.FC<SoleCardProps> = (props: SoleCardProps) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const dispatch = useDispatch();

    const [openDelete, setOpenDelete] = React.useState(false);
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const sole = props.sole;
    const state = sole.state;

    const getDefaultRoute = (sole: ISole): string => {
        let route = '/soles/' + sole.id;
        if (sole.state === SoleState.StatePlanned || sole.state === SoleState.StateFacilitate) {
            route += '/plan';
        } else if (sole.state === SoleState.StateReflect) {
            route += '/reflect';
        }
        return route;
    };

    const onClickDelete = () => {
        if (sole.id) {
            dispatch(deleteSole(sole.id));
        }
    };

    const onClickDownloadPlan = async () => {
        const url = await getDocumentUrl(sole?.id ?? '', 'plan');
        window.open(url);
    };

    const onClickDownloadSummary = async () => {
        const url = await getDocumentUrl(sole?.id ?? '', 'summary');
        window.open(url);
    };

    return <Card variant={"outlined"} className={classes.root}>
        <CardActionArea component={RouterLink} to={{
            pathname: getDefaultRoute(sole)
        }}>
            <CardContent>
                <Typography variant={"h5"}>
                    {sole.question?.text}
                </Typography>
                {sole.tag ? <Typography variant={"h6"} color={'textSecondary'}>
                    {sole.tag}
                </Typography> : <></>}
            </CardContent>
        </CardActionArea>
        <CardActions className={classes.flipped}>
            {/*TODO: maybe use this for sharing: https://github.com/nygardk/react-share*/}
            {/*<IconButton onClick={onClickShare}  disabled={true}>*/}
            {/*    <ShareOutlinedIcon/>*/}
            {/*</IconButton>*/}

            <IconButton onClick={handleClickOpenDelete}>
                <DeleteIcon/>
            </IconButton>
            {(state === SoleState.StateFacilitate || state === SoleState.StatePlanned) &&
            <IconButton component={RouterLink} to={{
                pathname: '/soles/' + sole.id + '/copy'
            }}>
              <FileCopyOutlinedIcon/>
            </IconButton>
            }
            {state !== SoleState.StateCompleted &&
            <Button color={'default'} variant={'contained'} component={RouterLink} to={{
                pathname: '/soles/' + sole.id + '/reflect'
            }}>{ getLabel('ui.sole-card.reflect') }</Button>
            }
            <Button color={'primary'} variant={'contained'} onClick={onClickDownloadPlan}>
                { getLabel('ui.sole-card.download_plan') }
            </Button>
            {state === SoleState.StateCompleted &&
            <Button onClick={onClickDownloadSummary}>
                { getLabel('ui.sole-card.download_summary') }
            </Button>
            }
            {(state === SoleState.StateFacilitate || state === SoleState.StatePlanned) &&
            <Button color={'secondary'} variant={'contained'} component={RouterLink} to={{
                pathname: '/soles/virtual',
                search: '?sole=' + sole.id
            }}>
                { getLabel('ui.sole-card.virtual') }
            </Button>
            }
        </CardActions>
        <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{getLabel('ui.sole-card.delete_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {getLabel('ui.sole-card.delete_body')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDelete} color="primary">
                    {getLabel('ui.cancel')}
                </Button>
                <Button onClick={onClickDelete} color="primary" autoFocus>
                    {getLabel('ui.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    </Card>
};

export default SoleCard;
