import {UserActions, UserActionType,} from "./UserTypes";
import Parse from 'parse';
import {ISchool, IUserPub} from "../shared/soleTypes";

export interface UserState {
    isLoading: boolean,
    errorMessage?: string,
    user?: Parse.User,
    pub?: IUserPub,
    school?: ISchool
}

const initialState: UserState = {
    isLoading: false,
    errorMessage: undefined,
    user: undefined,
    pub: undefined,
    school: undefined
};

export const UserReducer = (state: UserState = initialState, action: UserActions): UserState => {
    switch (action.type) {
        case UserActionType.LoginPending:
            return {
                ...state,
                isLoading: true,
                errorMessage: undefined,
                user: undefined,
                school: undefined,
                pub: undefined
            };
        case UserActionType.LoginFailed:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.errorMessage,
                user: undefined,
                school: undefined,
                pub: undefined
            };
        case UserActionType.SetCurrentUser:
            return {
                ...state,
                isLoading: false,
                errorMessage: undefined,
                user: action.user,
                pub: action.pub
            };
        case UserActionType.ResetUser:
            return {
                ...state,
                isLoading: false,
                errorMessage: undefined,
                user: undefined,
                school: undefined,
                pub: undefined
            };
        case UserActionType.SetSchool:
            return {
                ...state,
                school: action.school
            };
        default:
            return state;
    }
};
