import {SolesActions, SolesActionType} from "./SolesTypes";
import {ISole} from "../shared/soleTypes";

export interface SolesState {
    isLoading: boolean,
    errorMessage?: string,
    soles: ISole[]
}

const initialState: SolesState = {
    isLoading: false,
    errorMessage: undefined,
    soles: []
};

export const SolesReducer = (state = initialState, action: SolesActions): SolesState => {
    switch (action.type) {
        case SolesActionType.SolesLoading:
            return {
                isLoading: true,
                errorMessage: undefined,
                soles: []
            };
        case SolesActionType.SolesLoadingFailed:
            return {
                isLoading: false,
                errorMessage: action.errorMessage,
                soles: []
            };
        case SolesActionType.SetSoles:
            return {
                isLoading: false,
                errorMessage: undefined,
                soles: action.soles
            };
        default:
            return state;
    }
};
