import {SoleText} from "../data/SoleText";

export enum TextActionType {
    TextLoad = "TextLoad",
    TextLoading = "TextLoading",
    TextLoadingFailed = "TextLoadingFailed",
    TextLoadingFinished = "TextLoadingFinished",
    SetTexts = "SetTexts"
}

interface TextLoadAction {
    type: typeof TextActionType.TextLoad
}

interface TextLoadingAction {
    type: typeof TextActionType.TextLoading
}

interface TextLoadingFailedAction {
    type: typeof TextActionType.TextLoadingFailed,
    errorMessage: string
}

interface TextLoadingFinished {
    type: typeof TextActionType.TextLoadingFinished
}

export interface SetTextsAction {
    type: typeof TextActionType.SetTexts,
    texts: SoleText[]
}

export type TextActions =
    TextLoadAction |
    TextLoadingAction |
    TextLoadingFailedAction |
    SetTextsAction |
    TextLoadingFinished
