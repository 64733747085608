import {LoadReportAction} from "../redux/ReportTypes";
import Parse from "parse";
import {put} from "redux-saga/effects";
import {loadReportFailed, loadReportFinished, setReport,} from "../redux/ReportCreators";
import {IReport} from "../shared/soleTypes";

export function* loadReportSaga(action: LoadReportAction) {
    try {

        const report: IReport = {
            name: action.name,
            ring: action.ring,
            parms: action.parms,
            data: undefined
        };

        // trigger a background update for the dashboarddata
        if(report.name === "report.getDashboardData") {

            Parse.Cloud.run("ring.updateDashboardDataForRingRdn", { ringRdn:report.ring}, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
        }

        report.data = yield Parse.Cloud.run(report.name, { ring:report.ring, ...report.parms}, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });

        yield put(setReport(report));
        yield put(loadReportFinished());
    } catch (error) {
        yield put(loadReportFailed(error.message));
    }
}
