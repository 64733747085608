import React, {useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/configureStore";
import {NumberConstraints} from "../../data/constraints/Constraints";
import TextField from "@material-ui/core/TextField";
import {Rating} from "@material-ui/lab";
import {Grid, Slider, Typography} from "@material-ui/core";
import {useTranslationLabel} from "../../hooks/useTranslation";

interface NumberProps {
    initialValue?: number,
    isRating?: boolean,
    label: string,
    rdn: string,
    onChange: (value: number | undefined) => void
}

function percentageText(value: number) {
    return `${value || 0}%`;
}

const NumberComponent: React.FC<NumberProps> = (props: NumberProps) => {
    const getLabel = useTranslationLabel();
    const constraints = useSelector((state: AppState) => state.constraints.constraints);
    const constraint = props.rdn ? constraints.get(props.rdn) : undefined;
    const isPercent = constraint instanceof NumberConstraints && constraint.unit === 'percent';

    const max = isPercent ? 100 : 5;
    const [value, setValue] = useState<number | undefined>(props.initialValue ? isPercent ? props.initialValue * max / 100 : props.initialValue : undefined);
    const precision = 0.5;

    // TODO: get min/max and formatter from DataPoint
    const minValue = 0;
    const maxValue = 100;
    const suffix = "%";

    const marks = [
        {value:minValue, label:minValue + suffix},
        {value:maxValue, label:maxValue + suffix}
    ];

    function valuetext(value: number) {
        return `${value}${suffix}`;
    }

    if (constraint instanceof NumberConstraints) {
        if (value === undefined && constraint.defaultValue) {
            setValue(constraint.defaultValue);
        }
        const minError = (value && constraint.min) ? value<constraint.min : false;

        if (constraint.unit === 'percent') {
            if (value === undefined) {
                setValue(50);
            }
            return (
                <Grid container>
                    <Grid item xs={12} sm={7}>
                        <Typography component="legend">{props.label}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                            <Slider
                                min={minValue}
                                max={maxValue}
                                marks={marks}
                                getAriaValueText={percentageText}
                                value={value}
                                defaultValue={value}
                                valueLabelFormat={valuetext}
                                valueLabelDisplay="auto"
                                aria-labelledby="slider"
                                onChange={(event, newValue) => {
                                    setValue(newValue as number);
                                    props.onChange(newValue as number);
                                }}
                            />
                    </Grid>
                </Grid>
            );
        }
        else if (props.isRating) {
            return <div>
                <Typography component="legend">{props.label}</Typography>
                <Rating
                    max={max}
                    precision={precision}
                    name={props.rdn}
                    value={value}
                    onChange={(event: any) => {
                        let v: number | undefined = undefined;
                        if (event.target.value) {
                            v = Number(event.target.value);
                            v = v * 100 / max;
                        }
                        setValue(v);
                        props.onChange(v);
                    }}
                />
            </div>
        }
        else {
            return <TextField
                // inputProps={{ min: constraint.min, max: constraint.max, step: constraint.step }}
                type="number"
                margin="normal"
                required
                fullWidth
                id={props.rdn}
                //            error={!isValidDuration}
                label={props.label}
                //            helperText={isValidDuration ? '' : 'SOLEs should be at least 30 minutes'}
                name={props.rdn}
                autoFocus
                value={value}
                error={minError}
                helperText={minError ? getLabel('error.min.'+props.rdn) ?? getLabel('error.min.default') : ''}
                onChange={(event: any) => {
                    let v: number | undefined = undefined;
                    if (event.target.value) {
                        v = Number(event.target.value);
                        setValue(v);
                        props.onChange(v);
                    }
                }}
            />;
        }
    } else {
        return <div/>;
    }
};

export default NumberComponent;
