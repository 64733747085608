import {IQuestion} from "../../shared/soleTypes";
import React from "react";
import {
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer, TableHead, TableRow,
} from "@material-ui/core";
import {HidableComponent} from "../hoc/Hidable";
import {useTranslationLabel} from "../../hooks/useTranslation";

interface QuestionResourcesProps {
    question?: IQuestion
}

const QuestionResources: React.FC<QuestionResourcesProps> = ({question}) => {
    const getLabel = useTranslationLabel();

    return (
        <HidableComponent isVisible={question?.resources !== undefined && question.resources.length > 0}>
            <TableContainer component={Paper}>
                <Table aria-label="resource-table">
                    <TableHead>
                        <TableRow>
                            <TableCell align={'center'}>{getLabel('ui.question.resources')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {question?.resources?.map((row, index) => (
                            <TableRow key={`resource-row-${index}`}>
                                <TableCell component="th" scope="row">
                                    <a href={row.url} target="_blank" rel="noopener noreferrer">{row.title}</a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </HidableComponent>
    )
};


export default QuestionResources;
