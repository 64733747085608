import {TextActions, TextActionType} from "./TextTypes";
import {SoleText} from "../data/SoleText";

export const loadText = (): TextActions => ({
    type: TextActionType.TextLoad
});

export const textLoading = (): TextActions => ({
    type: TextActionType.TextLoading
});

export const textLoadingFailed = (errorMessage: string): TextActions => ({
    type: TextActionType.TextLoadingFailed,
    errorMessage: errorMessage
});

export const textLoadingFinished = (): TextActions => ({
    type: TextActionType.TextLoadingFinished
});

export const setTexts = (texts: SoleText[]): TextActions => ({
    type: TextActionType.SetTexts,
    texts: texts
});
