import React from "react";
import {SoleProps} from "./SoleProps";
import StringComponent from "../input/StringComponent";
import {useTranslationShort} from "../../hooks/useTranslation";

const SoleStringComponent: React.FC<SoleProps> = (props: SoleProps) => {
    const getShort = useTranslationShort();
    const rdn = props.rdn;
    const jsonKey = props.jsonKey;
    const sole = props.sole;
    const label = props.label ?? getShort(rdn) ?? '';
    // @ts-ignore
    const initialValue = sole && sole[jsonKey] ? sole[jsonKey] : undefined;

    return <StringComponent
         initialValue={initialValue}
         label={label}
         rdn={rdn}
         onChange={
             (value: string | undefined) => {
                 props.setSole({
                     ...props.sole,
                     [jsonKey]: value
                 });
             }
         }
     />
};

export default SoleStringComponent;
