import {IQuestion} from "../shared/soleTypes";
import {useEffect, useState} from "react";
import Parse from "parse";

const useFetchQuestion = (questionId?: string): [IQuestion | undefined, boolean, string, (questionId: string) => void] => {
    const [question, setQuestion] = useState<IQuestion | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (questionId) {
            fetchQuestion(questionId);
        }
    }, [questionId]);

    const fetchQuestion = async (questionId: string) => {
        setIsLoading(true);
        try {
            const question = await Parse.Cloud.run('webapp.getQuestionByIdNew', {
                id: questionId
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
            setIsLoading(false);
            // @ts-ignore
            setQuestion(question);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return [question, isLoading, errorMessage, fetchQuestion];
};

export default useFetchQuestion;
