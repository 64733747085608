import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import VideoModal from "./VideoModalComponent";
import {Typography} from "@material-ui/core";
import Scrollspy from "react-scrollspy-ez";
import {useSessionContext} from "../../hooks/useSessionContext";
import {useTranslationLabel} from "../../hooks/useTranslation";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            height: 140,
            width: 100,
        },
        control: {
            padding: theme.spacing(2),
        },
        marginTop: {
            scrollMarginTop: "80px",
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(6)
        },
        active: {
            fontWeight: 'bold'
        },
        scrollspy: {
            top: 90,
            // Fix IE 11 position sticky issue.
            //marginTop: 70,
            width: 175,
            flexShrink: 0,
            order: 2,
            position: 'sticky',
            height: 'calc(100vh - 70px)',
            overflowY: 'auto',
            padding: theme.spacing(2, 2, 2, 0),
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        ul: {
            padding: 0,
            marginLeft: theme.spacing(3),
            listStyleType: 'none'
        },
        item: {
            fontSize: 13,
            cursor: 'pointer',
            padding: theme.spacing(0.5, 0, 0.5, 1),
            borderLeft: '4px solid transparent',
            boxSizing: 'content-box',
            '&:hover': {
                borderLeft: `4px solid ${
                    theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
                    }`,
            },
            '&$active,&:active': {
                borderLeft: `4px solid ${
                    theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[800]
                    }`,
            },
        },
        anchor: { display: 'block', position: 'relative', visibility: 'hidden', top: '-100px' }
    }),
);

const HowToSole: React.FC = () => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    useSessionContext([
        {
            callback(): void { },
            text: getLabel('ui.how_to_sole')!
        }
    ]);

//    const topRef = useRef<HTMLDivElement>(null);
//    const rootEl = topRef.current == null ? undefined : topRef.current;

    return <Grid container className={classes.root} spacing={2}>
        <Grid item xs={10}>
            <section>
                <Typography id={"overview"} variant="h5" gutterBottom className={classes.marginTop}>
                    SOLE Overview
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/OIzMssS0nFw"}
                            title={"How to run your first SOLE"}
                            image={"/images/how/how-to-sole.jpg"}
                            description={"A quick tutorial on how to get started hosting a SOLE session. All you need are some internet-enabled devices and participants."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/kpYMdxliHH8"}
                            title={"SOLE Overview: Elementary"}
                            image={"/images/how/elementary-overview.jpg"}
                            description={"Peek inside Cleveland elementary schools and learn how teachers are using SOLE in their classrooms."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/0XpZo-_Pn6w"}
                            title={"SOLE Overview: Middle and High School"}
                            image={"/images/how/middle-high-overview.jpg"}
                            description={"See how teachers and students from Cleveland make SOLE work in middle & high school."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/e4X03-xOFN4"}
                            title={"SOLE Overview: Higher Education"}
                            image={"/images/how/adult-overview.jpg"}
                            description={"SOLE can also be used with adults. Learn more about Cleveland area university students and professors SOLE experience."}
                        />
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography id={"question"} variant="h5" gutterBottom className={classes.marginTop}>
                    Big Question
                </Typography>
                <Typography variant="body1" gutterBottom>
                    In the Question Phase, the educator introduces the Big Question and shares some background or a short story around the question. It's important to remember not to lead students to an answer or in any way reveal what they should learn. Big questions should lead to more questions, and don't have a single right answers. Our site includes thousands of questions aligned to standards to help you get started using SOLE in your classroom.
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/3sX1dShy19s"}
                            title={"Big Question: Elementary"}
                            image={"/images/how/elementary-big-question.jpg"}
                            description={"What makes a good Big Question? Elementary teachers share some useful guidelines."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/oSw5eyYkbl4"}
                            title={"Big Question: Middle and High School"}
                            image={"/images/how/middle-high-big-question.jpg"}
                            description={"What makes a good Big Question? Elementary teachers share some useful guidelines."}
                        />
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography id={"investigation"} variant="h5" gutterBottom className={classes.marginTop}>
                    Investigation
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Students self organize into their groups. In some cases, teachers have found that the process runs better when they assist in the formation of groups. From this moment, the educator simply let's the adventure begin! Students begin exploring the big question, jumping on computers and searching for answers. In some cases, open and supportive questions may help, and very important, offer encouragement. For the most part, educators should remain invisible. Yet, it's not unusual to run into a few challenges, ranging from one child being excluded to an entire group not working on the task.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    In most of these situations you should be encouraging and remind them about the ground rules, like being able to change groups. You can find more advice for dealing with challenges on this website. The more comfortable you become in letting the children run the session, the easier it should be to remain invisible, and let the learning happen. If your students have not done this before you may watch them struggle to figure out the process. It is important that you let them figure it out on their own. You should only intervene if they are way off task and creating an unsafe learning environment for themselves or others.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    As one educator described it:
                </Typography>
                <Typography variant="body2" gutterBottom>
                    "I was really nervous about doing it initially and had concerns about how the students would interact with one another. However what surprised me was that the students corrected each other and created an environment where they each showed off what they could do --especially when we had good questions the students were amazing in finding solutions and using the information to answer these questions in ways I never thought they would do."
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/ce-Ez0XftSs"}
                            title={"Investigation: Elementary"}
                            image={"/images/how/elementary-investigation.jpg"}
                            description={"Elementary students and teachers describe their experiences collaborating to investigate big questions."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/6nMMUicfF1U"}
                            title={"Investigation: Middle and High School"}
                            image={"/images/how/middle-high-investigation.jpg"}
                            description={"What makes a good Big Question? Elementary teachers share some useful guidelines."}
                        />
                    </Grid>
                </Grid>
            </section>
            <section >
                <Typography id={"review"} variant="h5" gutterBottom className={classes.marginTop}>
                    Review
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Each group now presents their discoveries. This is one of the most important elements of the session as it gives them a chance to think more deeply about what they've found out, and how they discovered it.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You should use this time to get excited about what the students have learnt, praise their discoveries and encourage debate between them. Ask the groups how they found their answers and what they think went well - as well as what they could do differently next time. Even if they haven’t answered your big question, or have drawn the wrong conclusions, they can learn a lot from talking about how they got to that answer and learn from other groups who took a different approach.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    And with that, you're now ready to try out your first SOLE. It may seem a bit daunting, but it's as easy as you've just seen. And remember our community is here to help each other, so if you have concerns or challenges, visit the community area of the site and someone will be there to help you. And pretty soon you will be a SOLE Leader yourself and you will be able to help others.
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/4xBHHMf4wYU"}
                            title={"Review: Elementary"}
                            image={"/images/how/elementary-review.jpg"}
                            description={"Elementary students present their work, and teachers offer advice on evaluation criteria and feedback."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/wP-MCvJTPtk"}
                            title={"Review: Middle and High School"}
                            image={"/images/how/middle-high-review.jpg"}
                            description={"Teachers discuss organizing the review, using SOLE to setup future lessons, and handling student disagreement."}
                        />
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography id={"curriculum"} variant="h5" gutterBottom className={classes.marginTop}>
                    Curriculum
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/kFizfwprJgo"}
                            title={"SOLE: Curriculum Integration"}
                            image={"/images/how/curriculum.jpg"}
                            description={"How can self-organized learning connect to standards? Learn more about teachers using SOLE across all subjects."}
                        />
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography id={"technology"} variant="h5" gutterBottom className={classes.marginTop}>
                    Technology
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/IvGhetjoUtQ"}
                            title={"SOLE: Technology's Role"}
                            image={"/images/how/technologys-role.jpg"}
                            description={"Don't let a lack of laptops stop you! Tips on making it work with the resources you have on hand."}
                        />
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography id="teachers" variant="h5" gutterBottom className={classes.marginTop}>
                    Teachers
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/oDWsUHjAGnM"}
                            title={"SOLE: Teacher Experience"}
                            image={"/images/how/teacher-experience.jpg"}
                            description={"Experienced teachers reflect on what they've learned using SOLE in their classroom, and offer advice about getting started."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/b4EktxveXxQ"}
                            title={"SOLE: The Teacher's Role"}
                            image={"/images/how/teacher-role.jpg"}
                            description={"How do teachers let learning happen? Teacher's share their insight on facilitating effective SOLE sessions."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/mYesF7WXV8"}
                            title={"SOLE Implementation: Tips for Early Educators"}
                            image={"/images/how/early-educator-tips.jpg"}
                            description={"How can SOLE work for kindergarten students? Cleveland area teachers offer their tips for scaffolding the SOLE process."}
                        />
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography id={"administrators"} variant="h5" gutterBottom className={classes.marginTop}>
                    Administrators
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/UuUgQoT7HYQ"}
                            title={"SOLE Implementation: Tips for Administrators"}
                            image={"/images/how/admin-tips.jpg"}
                            description={"Why try SOLE? Principals reflect on the value of SOLE for teachers and how they got started in their schools."}
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <VideoModal
                            url={"https://www.youtube.com/embed/RTyCYTIuzDE"}
                            title={"SOLE: Promoting 21st Century Skills"}
                            image={"/images/how/21-skills.jpg"}
                            description={"How does SOLE prepare students for the future? Practitioners reflect on building students' 21st century skills."}
                        />
                    </Grid>
                </Grid>
            </section>
        </Grid>
        <Grid item xs={2} className={classes.scrollspy}>
          <img src="/images/logos/sole-graphic-logo-small.png" alt="logo"/>
            <Scrollspy ids={['overview', 'question', 'investigation', 'review', 'curriculum', 'technology', 'teachers', 'administrators']}
                       activeItemClassName={classes.active}
                       itemContainerClassName={classes.ul} itemClassName={classes.item}/>
        </Grid>
    </Grid>
};

export default HowToSole;
